.turivius-preloader {
  &.backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .turivius-spinner {
    .rs-loader-spin,
    .rs-loader-spin::after,
    .rs-loader-spin::before {
      height: 100px;
      width: 100px;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    margin: -50px 0 0 -50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 25px;
  }
  .turivius-spinner-image {
    height: 60px;
    width: 60px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-right: 5px;
  }
}

.single-filter-box {

  .box_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .single-filter-box_title {
    font-size: 16px;
    font-weight: 500;
  }

  .toggle-icon {
    cursor: pointer;
  }

  .radio-group-container {
    display: flex;
    flex-direction: column;
    @media (max-width: 1279px) {
      .container-group-data{
        display: flex;
      }
      .radio-row {
        margin-right: 6px;
      }
    }

    @media (max-width: 1279px) {
      .container-group-data{
        display: flex;
        flex-wrap: wrap;
      }
      .radio-row {
        margin-right: 6px;
      }
    }

    @media (max-width: 767px) {
      .container-group-data{
        display: inline-block;
      }
      .radio-row {
        margin-right: 6px;
      }
    }

    .radio-row {
      display: flex;
      gap: 6px;
      margin-bottom: 10px;

      .icon-text {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      .rs-radio-checker {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &.rs-radio-checked {
          background-color: #0069f5;
          color: #fff;

          .icon-text {
            color: #fff;
          }
        }
      }

      .rs-radio-wrapper {
        flex: 1;
        text-align: center;
      }
    }
  }

  .date-section-header {
    display: flex;
    gap: 2.5px;
    align-items: center;
    padding: 10px 0px;
    cursor: pointer;
    color: #0069f5;
    font-size: 12px;
    font-weight: 600;
  }

 

  #initial-date,
  #final-date {
    text-align: center;
  }
}

@import '../../../../../vars.module.scss';

.r-notification {
  width: 100%;
  height: 450px;
  position: absolute;
  top: 0;
  left: 0px;
  z-index: 2;
  transform: translate(-500px, 0);
  transition: all 0.3s ease-in-out;

  &.active {
    transform: translate(0, 0);
  }

  .desc {
    padding: 20px;

    .notification-link-button {
      background-color: $turivius;
      width: 100%;

      i {
        line-height: 32px;
      }
    }

    .header-col {
      div {
        display: flex;
        flex-direction: row;
        align-items: center;

        img {
          width: 18px;
          height: 18px;
        }

        span.category {
          font-size: 14px;
          color: rgb(18, 59, 113);
          margin-left: 8px;
        }

        span.point {
          color: rgb(117, 117, 117);
          font-size: 14px;
          font-weight: 600;
          margin: 0px 4px;
        }

        span.time {
          color: rgb(117, 117, 117);
          font-size: 12px;
          margin-right: 8px;
        }
      }
    }

    .title-subtitle-col {
      padding: 8px 16px 4px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      div {
        display: flex;
        flex-direction: column;
        max-width: 392px;
      }

      .avatar-div {
        margin-right: 5px;
        text-align: center;
      }

      .rn-title {
        font-size: 15px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.87);
        font-family: Roboto, sans-serif;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }

      .rn-subtitle {
        font-size: 14px;
        color: rgb(117, 117, 117);
        margin-top: 2px;
        font-family: Roboto, sans-serif;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
      }
    }

    .action-col {
      display: flex;
      flex-direction: row;
      padding: 0px 8px;
      background-color: transparent;

      button {
        width: auto;
        border: 10px;
        box-sizing: border-box;
        display: inline-block;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        cursor: pointer;
        text-decoration: none;
        margin: 0px;
        padding: 0px;
        outline: none;
        font-size: inherit;
        font-weight: inherit;
        position: relative;
        height: 36px;
        line-height: 36px;
        color: rgba(0, 0, 0, 0.87);
        transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
        border-radius: 2px;
        user-select: none;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0);
        text-align: center;

        &:hover {
          background-color: rgba(0, 0, 0, 0.1);
        }

        div span {
          position: relative;
          padding: 0px 8px;
          vertical-align: middle;
          letter-spacing: 0px;
          text-transform: uppercase;
          font-weight: 500;
          font-size: 14px;
          color: rgb(18, 59, 113);
        }
      }
    }
  }

  button {
    display: block;
    width: 50px;
    height: 30px;
    margin: auto;
    margin-top: 4px;
    background-color: transparent;
    border: none;
    outline: none;

    &:hover {
      cursor: pointer;
    }

    .back {
      display: block;
      width: 40px;
      height: 4px;
      background-color: #ccc;
      position: relative;
      border-radius: 4px;

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-top: 7px solid transparent;
        border-right: 7px solid #ccc;
        border-bottom: 7px solid transparent;
        position: absolute;
        top: -5px;
        left: -5px;
      }
    }
  }
}

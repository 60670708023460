@import '../../../../../vars.module.scss';

.header-content {
  .header-item {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 5px;
      font-weight: bold;
      color: $turivius;
    }

    .header-text-contracted {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .header-text {
      &.bold {
        font-weight: bold;
      }
    }
  }
}

.rs-panel-header {
  cursor: unset;
}

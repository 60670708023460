@import '../../../../../vars.module.scss';

.edit-button {
  background-color: #daf2ff;
  color: $turivius-light;
  font-weight: 500;

  .rs-icon {
    background-color: #daf2ff;
  }

  &:hover,
  &:focus {
    background-color: #daf2ff;
    color: $turivius-light;

    .rs-icon {
      background-color: #daf2ff;
    }
  }
}

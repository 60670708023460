.homepage {
  &-container {
    display: flex;
    flex-direction: column !important;
    padding: 0 1rem; // Adiciona padding lateral para telas pequenas
  }

  &-title {
    text-align: center;
    padding-bottom: 64px;
    font-size: 2rem; // Tamanho padrão para telas maiores

    @media (max-width: 768px) {
      font-size: 1.5rem; // Reduz o tamanho do título em telas menores
      padding-bottom: 32px; // Ajusta o espaçamento inferior
    }

    @media (max-width: 480px) {
      font-size: 1.25rem; // Para telas muito pequenas (smartphones)
      padding-bottom: 24px; // Mais compacto
    }
  }
}

.home-cards_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 1.5rem;

  @media (max-width: 480px) {
    grid-template-columns: 1fr; // Ajusta para uma única coluna em telas muito pequenas
  }
}

.recent-articles-box {
  width: 47%;
}

.info {
  border-radius: 999px;
  padding: 3px 8px;
  font-weight: 600;
  font-size: 10px !important;
  line-height: 16px;
  margin: 5px 0;

  &.danger {
    background: #fee4e2;

    color: #f04438;
  }

  &.success {
    background: #d1fadf;

    color: #12b76a;
  }
}

@import '../../vars.module.scss';

/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* Isso é para manter o tooltip acima dos elementos */

/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */
.rs-tooltip {
  z-index: 1050 !important; /* Certifique-se de que está acima de outros elementos */
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */

.contente-sidebar.pesquisa {
  overflow: hidden auto;
  flex: 0 0 260px;
  background-color: $turivius-light;
  height: auto;
  z-index: 49;
  transition: 0.3s;

  &.close {
    overflow: hidden auto;
    flex: 0 0 76px;
    background-color: $turivius-light;
    height: auto;
    transition: 0.3s;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $secondary-dark;
    border-radius: 1px;
  }

  &::-webkit-scrollbar-track {
    background: $turivius-light;
    border-radius: 1px;
  }

  .turivius-sidebar {
    background-color: $turivius-light !important;
    // background-color: #fff !important; // TODO: Cor branca na sidebar
    font-family: 'Rubik', sans-serif !important;
    z-index: 990;
    padding-top: 24px;
    height: 100vh;
    transition: 0.3s;

    // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); // para dar contraste com branco
  }
}

.turivius-sidenav {
  display: flex;
  flex-direction: column;
  background-color: transparent !important;

  .sidenav-header_logo {
    position: fixed;
    background-color: transparent !important;
    padding: 0 !important;
    left: 20px; // Posiciona a logo do menu aberto
  }

  .sidenav-header_logo_close {
    position: fixed;
    left: 10px;
    top: 0;
    padding: 32px 10px;
    width: 60px;
    overflow: hidden;
  }

  .rs-btn,
  .rs-btn-default {
    background-color: transparent;
  }

  .sidenav-header_icon {
    color: $white;
    font-size: 24px;
    display: flex;
    padding: 0 16px 8px 0;
  }

  .hidden-menu {
    position: fixed;
    margin-left: 245px;
    background-color: #fff;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    transition: 0.3s;
    border: 1px solid #053ac8;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    &:hover {
      opacity: 1;
      background-color: #fff;
    }

    svg {
      padding: 0px;
      margin-right: -8px;
      margin-left: -8px;
    }

    @media screen and (max-width: 1024px) {
      display: flex;
    }
  }

  .hidden-menu-close {
    position: fixed;
    margin-left: 63px;
    background-color: #fff;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    transition: 0.3s;
    border: 1px solid #053ac8;
    z-index: 1000 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    &:hover {
      opacity: 1;
      background-color: #fff;
    }

    svg {
      padding: 0px;
      margin-right: -8px;
      margin-left: -8px;
    }

    @media screen and (max-width: 1024px) {
      display: flex;
    }
  }
}

.sidenav-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 86vh;
}

.rs-sidebar {
  display: flex;
  flex-direction: column;
}

.rs-sidenav-body {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 60px;
}

.sidenav-body {
  padding: 15px 15px 0px 15px;
  

  .turivius-module {
    display: flex;
    margin-bottom: 8px;

    .turivius-module_icon {
      font-size: 20px;
      color: $secondary-lighter;
      margin-right: 15px;
    }

    .turivius-module_name {
      padding: 0px !important;
      width: 100%;
      padding: 10px;
      font-weight: 700;
      font-size: 16px;
      color: $white;
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }

  .turivius-menu_option {
    svg {
      color: $secondary-lighter !important;
    }

    color: #fff !important;
    background-color: #032378 !important;
    border-radius: 4px;
  }

  .turivius-menu_option_close {
    svg,img {
      color: $secondary-lighter !important;
    }

    color: #fff;
    background-color: #032378 !important;
    width: 46px; // EXPLANATION: tamanho do bloco azul em destaque ao redor do ícone fechado
    border-radius: 4px;
    


  }

  .rs-sidenav-item {

    background-color: transparent;
    padding: 12px 14px;
    color: $white;
    font-size: 15px;
    margin-top: 12px !important; // margem entre o bloco de seleção (íCone+texto) e outro no sidebar
    align-items: center; // coloca o texto do bloco ao centro
    
    svg {
      width: 18px;
      height: 18px;
      margin-right: 14px;
      
    }
    img {
      width: 18px;
      height: 18px;
      margin-right: 14px; // EXPLANATION: margem proposital para afastar o componente do texto
      
    }

    &:hover {
      background-color: transparent;
      color: $secondary-lighter !important;
    }
  }
}



// ------------------------------------------------------------------------------
// TODO: CONTROLA as linhas que delimitam o footer do menu lateral
// ------------------------------------------------------------------------------

.line{
  margin-bottom: -10px;
  border-bottom: 0.5px solid #0323784d;
}

.line-footer {
  margin-bottom: -10px;
  width: 100%;
  border-bottom: 0.5px solid #0323784d;
}

.line-footer-closed {
  // margin-bottom: -10px;
  width: 82%;
  border-bottom: 0.5px solid #0323785d;
}


// ------------------------------------------------------------------------------

.sidenav-footer {
  padding-bottom: 20px;
  padding: 20px 26px;
  align-content: flex-end;
  
  
  @media screen and (max-width: 1024px) {
    padding: 0 24px 0 24px;
  }

  .turivius-module {
    .turivius-module_icon {
      font-size: 20px;
      color: $secondary-lighter;
      margin-right: 12px;
    }

    .turivius-module_name {
      margin-top: 4px;
      font-size: 15px;
      color: $white;
      &:hover {
        color: $secondary-lighter !important;
      }
    }

    .rs-sidenav-item {
      svg {
        margin-right: 15px;
      }

      background-color: transparent !important;
      padding: 8px 0px !important;
      display: flex;
    }
  }
}

// ------------------------------------------------------------------------------
// TODO: CONTROLA o menu lateral
// ------------------------------------------------------------------------------

.rs-sidenav-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 16px 50px 16px;
  border-bottom: 0.5px solid #0323784d;

  &.close {
    border-bottom: none;
    padding: 40px 16px 16px 16px;
    border-bottom: 0.5px solid #0323782d;
  }
}
// ------------------------------------------------------------------------------


.sidebar-collapsed {
  padding: 24px 0 8px 16px;
  background-color: transparent !important;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 99;

  .rs-button,
  .rs-btn-default {
    background-color: $turivius-light;
    color: $white;
  }
}

.menu {
  width: 87%;
  @media (max-width: 1360px) {
    width: 81%;
  }
}

.bulk-action-bar {
  left: 260px;
}

.contente-sidebar.close ~ div {
  .menu {
    width: 100%;
  }
  .bulk-action-bar {
    left: 76px;
  }
}

.contente-sidebar.resultados {
  overflow: hidden auto;
  flex: 0 0 260px;
  background-color: $turivius-light;
  z-index: 82;
  transition: width 0.3s;

  &.close {
    overflow: hidden auto;
    flex: 0 0 76px;
    background-color: $turivius-light;
    transition: 0.3s;
    width: 76px;
    transition: width 0.3s;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $secondary-dark;
    border-radius: 1px;
  }

  &::-webkit-scrollbar-track {
    background: $turivius-light;
    border-radius: 1px;
  }

  .turivius-sidebar {
    background-color: $turivius-light !important;
    font-family: 'Rubik', sans-serif !important;
    z-index: 990;
    padding-top: 24px;
    height: 100vh;
    transition: 0.3s;
  }
}

.turivius-sidebar,
.contente-sidebar {
  border-right: 1px solid #e5e7eb;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

@import '../../../../../vars.module.scss';

.rn-item {
  width: 100%;
  padding: 20px 10px;
  list-style: none;

  line-height: 24px !important;
  text-align: left;
  background-color: #ffffff;
  margin: 10px 0;
  border-radius: 8px;

  .header-col {
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;

      span.category {
        background-color: #e4e7ec;
        padding: 2px 5px;
        border-radius: 50px;
        color: #475467;
        font-size: 12px !important;
        font-weight: 600;
      }

      span.point {
        color: rgb(117, 117, 117);
        font-size: 14px;
        font-weight: 600;
        margin: 0px 4px;
      }

      span.time {
        color: #98a2b3;
        font-size: 12px !important;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
      }
    }

    &.new {
      .category {
        background-color: #fef0c7;
        padding: 2px 5px;
        border-radius: 50px;
        color: #f79009;
        font-size: 12px !important;
        font-weight: 600;
      }
    }
  }

  .title-subtitle-col {
    padding: 8px 16px 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: column;
      max-width: 392px;
    }

    .rn-title {
      color: #101828;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 8px;
    }

    .rn-subtitle {
      color: #667085;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .action-col {
    display: flex;
    flex-direction: row;
    padding: 0px 8px;
    background-color: transparent;
    margin-top: 10px;

    button {
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      color: #0069f5;
      font-size: 12px;
      font-weight: 500;
    }
  }
}

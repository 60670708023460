@import '../../../vars.module.scss';

.container-checkbox {
  margin-top: 15px;

  &_label {
    font-weight: 700;
    color: #3a3a3a;
  }

  &_box {
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 160px;
    overflow: auto;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $secondary;
    }
  }

  &_header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 11px;
  }
  &_title {
    font-weight: 700;
  }
  &_button {
    padding: 2px 4px;
    background-color: $secondary;
    color: $white;
    border-radius: 2px;

    &:focus,
    &:hover {
      background-color: $secondary;
      color: $white;
    }
  }
}

.container {
  max-width: 100vw !important;
  max-height: 100vh !important;
  width: 1280px;
}

.d-flex {
  display: flex !important;
}

.align-items_center {
  display: flex !important;
  align-items: center !important;
}
.align-items_start {
  display: flex !important;
  align-items: center !important;
}

.justify-content_center {
  display: flex !important;
  justify-content: center !important;
}

.justify-content_between {
  display: flex;
  justify-content: space-between;
}

.unsubscribe-monitoring-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  text-align: center;

  .unsubscribe-monitoring-panel {
    margin: auto;
    background-color: #fff;
    max-width: 400px;
    padding: 20px;

    .rs-panel-header {
      font-weight: 700;
    }
  }
}

.buttons-result-searchbarresult {
  font-size: 16px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  color: #475467;
  font-weight: 500;
  font-family: 'Poppins';
  margin-right: 4px;
  margin-left: 6px;
}

.buttons-result-searchbarresult.notselected {
  padding: 12px 10px;
  color: #475467;
  font-size: 14px;
}

.buttons-result-searchbarresult.selected {
  background: var(--Gray-Gray-50, #f9fafb);
  background-color: #f9fafb;
  padding: 12px 10px;
  border-radius: 8px;
  font-size: 14px;
  color: var(--Gray-Gray-900, #101828);
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
}

.switch-option-searchmode {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;

  width: 300px;
  padding: 12px 8px;

  border-radius: 8px;
  border: 1px solid var(--Gray-Gray-50, #f9fafb);
  background: var(--Gray-White, #fff);

  /* Elevation/XS */
  box-shadow:
    0px 1px 2px 0px rgba(0, 0, 0, 0.04),
    0px 1px 3px 0px rgba(0, 0, 0, 0.06);
}

.box-filter-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  .filter-title {
    font-weight: 600;
    font-size: 20px;
    margin-left: 6px;
  }

  .filter-actions {
    font-size: 12px;
    color: #0069f5;

    span {
      margin: 0 2px;
      cursor: pointer;
      position: relative;

      &::after {
        content: '-';
        color: #b2ddff;
        margin-left: 4px;
      }

      &:last-child::after {
        content: '';
      }
    }
  }

  .filter-icon {
    font-size: 18px;
  }

  .options-nav {
    display: flex;
    gap: 12px;
  }
}

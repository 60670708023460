.decision-classification {
  width: 100%;
  border: 1px solid $gray200;
  border-radius: 4px;
  padding: 8px;
  position: relative;

  &_active {
    width: 100%;
    border: 1px solid $blue300;
    border-radius: 4px;
    padding: 8px;
    position: relative;
  }

  &_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      display: flex;
      align-items: center;
      background-color: transparent;
    }
  }

  &_options {
    width: 100%;
    background-color: $white;
    border: 1px solid $gray200;
    border-radius: 8px;

    &-item {
      padding: 0px 8px;
      &:first-child {
        padding-top: 8px;
      }
      &:last-child {
        padding-bottom: 8px;
      }
      button {
        padding: 8px;
        width: 100%;
        display: flex;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        background-color: transparent;
        color: $gray900;
        &:hover {
          background-color: $blue25;
          color: $gray900;
          opacity: 1;
        }
      }
    }
  }
  &_error-message {
    color: $danger600;
  }
}

/* Estilo apenas para este SelectPicker */
.custom-select-picker {
    border: 1px solid #d0d5dd;
    border-radius: 8px;
    padding: 2px 8px; /* Reduz o padding para diminuir a altura */
    font-size: 13px; /* Reduz o tamanho da fonte */
    color: #344054;
    background-color: #ffffff;
    width: 140px; /* Largura fixa */
    outline: none; /* Remove o foco */
    height: 32px; /* Define uma altura fixa menor */
  }
  
/* Remove o efeito de hover */
/* 
.custom-select-picker .rs-picker-toggle:hover {
  background-color: transparent;
  border-color: #d0d5dd; Mantém a borda original
} */





* {
  font: 'Poppins';
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* Label da HORA DA PESQUISA*/
/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */

.label-search-time * {
  font-family: 'Poppins';
  font-style: italic;
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* Classe que controla a cor de fundo do histórico */
/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */
.rs-drawer-header,
.rs-drawer-footer,
.rs-drawer-body {
  /* background-color: #fffffe !important; */
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */




/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* Esse é o efeito da searchbar */
/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */



.search-input-container {
  display: flex;
  align-items: center;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #ffffff;
  margin-bottom: 30px;
  gap: 20px;
}

.search-icon-button {
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  
} 

.search-icon {
  color: #667085;
  font-size: 18px;
  margin-right: 20px;
}

.search-input {
  border: none;
  outline: none;
  flex-grow: 1;
  font-size: 14px;
  color: #667085;
  background-color: transparent;
}

.search-input::placeholder {
  color: #667085;
}

.arrow-icon {
  color: #667085;
  font-size: 18px;
  cursor: pointer;
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */







/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* Isso é para manter o tooltip acima dos elementos */
/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */
.rs-tooltip {
  z-index: 1050 !important; /* Certifique-se de que está acima de outros elementos */
}
/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* Esse é o texto com a data */
.pesquisa-title-major {
  font-size: 22px;
  font-weight: bold;
  color: #101828;
  margin-bottom: 35px;
}

/* EFEITO NOS ICONE */
.pesquisa-section *:hover {
  cursor: pointer;
}

.pesquisa-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
}

/* Esse é o texto com a data do grupo de pesquisa */
.pesquisa-title {
  font-size: 18px;
  font-weight: bold;
  color: #101828;
  margin-bottom: 8px;
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */

/* Estilos dos ícones e texto do histórico de busca */

/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */
.search-label-container {
  display: flex;
  justify-content: start;
  align-items: end; /* para colocar o texto da hora na base*/
}

.search-label {
  display: flex;
  align-items: center;
  background-color: #f4e9ff;
  border-radius: 25px;
  padding: 4px 16px;
  max-width: 600px;
  font-family: Arial, sans-serif;
}

.search-icon {
  font-size: 16px;
  color: #007bff;
  margin-right: 8px;
}

.search-text {
  font-size: 12px;
  font-weight: bold;
  color: #7a57d1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* -------------------------------------------------------------------------------------------------------------------------------------------------------- */

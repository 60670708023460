@import './notification.scss';
@import './notifications.scss';
@import '../../../../../vars.module.scss';

.notifications-configurations {
  .filters-displayer {
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 80px;
    max-height: 80px;

    .horizontal-menu {
      max-width: 100%;

      .arrow-prev,
      .arrow-next {
        font-size: 20px;
        font-weight: 700;
        color: $secondary_dark;
        cursor: pointer;
      }
    }

    .chip {
      height: 35px;
      line-height: 17px;
      background-color: transparentize($color: $secondary_light, $amount: 0.9);
      padding: 0;
      color: $secondary_dark;
      border: 1px solid $secondary_dark;

      .row {
        display: flex;
        align-items: center;
        margin: 0;

        .col.s11 {
          padding-right: 0px;
          padding-left: 10px;
        }

        .col.s1 {
          padding-left: 6px;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }
      }

      .chip-title {
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 400;
      }

      i {
        float: left;
        font-size: 20px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .notifications-configurations-header {
    width: 100%;
    min-height: 150px;
  }

  .notifications-configurations-header-colored {
    width: 100%;
    height: 75px;
    background-color: $secondary;
  }

  .notifications-configurations-header-white {
    width: 100%;
    min-height: 75px;
    background-color: $white;
    padding-bottom: 1px;
  }

  .notifications-configurations-channels {
    thead {
      .th-filtros {
        @media only screen and (min-width: 992px) {
          width: 60vw;
        }
      }

      .th-active {
        @media only screen and (min-width: 992px) {
          width: 250px;
        }
      }
    }
  }
}

.notification-wrapper {
  display: flex;
  align-items: center;

  .notification-message {
    line-height: 1.5rem;
  }

  .notification-action-button {
    width: 100%;

    &:hover {
      cursor: pointer;
    }
  }

  .notifications-unread {
    padding: 8px 0px 8px 16px;
    color: #053ac8;
    .badge-unread {
      position: relative;

      span {
        position: absolute;
        left: 9px;
        bottom: -7px;
        width: 22px;
        height: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FEF0C7;
        border-radius: 50px;
        padding: 4px 2px 0px 2px;
        color: #F79009;
        font-size: 8px !important;
        font-weight: 600;
      }
    }
  }

  .notification-badge {
    border-radius: 50px;
    display: flex;
    margin-right: 3px;
    padding: 6px 0 6px 10px;

    background-color: #eff8ff;
    color: #053ac8;
  }
}

.react-notification-center {
  position: relative;
  z-index: 1;
  font-family: 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .r-notifications-icon {
    width: 10px;
    height: 10px;
    border: 1px solid white;
    border-radius: 50px;
    text-align: center;
    line-height: 20px;
    color: white;
    position: absolute;
    z-index: 0;
    font-size: 10px;
    cursor: pointer;
    background-color: #ccc;
    top: -40px;
    left: 55px;
    overflow: hidden;
    transition: all 0.2s ease-in-out;

    &.active {
      background-color: #ff5c5c;
      width: 22px;
      height: 22px;
    }

    &:hover {
      transform: scale(1.1);
    }

    &.pulse {
      animation-name: pulse_animation;
      animation-duration: 300ms;
      animation-iteration-count: 2;
      animation-timing-function: linear;

      -webkit-animation-name: webkit_pulse_animation;
      -webkit-animation-duration: 300ms;
      -webkit-animation-iteration-count: 2;
      -webkit-animation-timing-function: linear;
    }
  }

  .rn-header {
    cursor: default;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    background-color: #ffffff00;
    border-bottom: 1px solid #f0f0f0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    overflow: hidden;

    h5 {
      width: 100%;
      font-size: 0.9rem;
      font-weight: 600;
      color: $turivius;
      text-transform: uppercase;
      padding: 0px 20px;
      margin: 0;
      text-align: left;
      line-height: 50px;
    }

    .rn-header-title {
      div {
        position: absolute;
        right: 12px;
        top: -8px;
        display: inline-flex;
        align-items: center;
        font-size: 9px;
        border: 1px solid $turivius;
        border-radius: 2px;
        height: 30px;
        top: 10px;
        padding: 0px 6px;

        &:hover {
          background-color: $turivius;
          color: white !important;
          cursor: pointer;
        }

        svg {
          font-size: 20px;
          margin-right: 5px;
        }
      }
    }

    .rn-header-icon {
      cursor: pointer;
      padding: 0px 5px;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .rn-content {
    width: 100%;
    height: 100%;

    background-color: #ffffff00;
    overflow: hidden;

    font-size: 14px;
    line-height: 1.5rem;

    .no-rn {
      cursor: default;
      width: 100%;
      height: 100%;
      text-align: center;
      color: #999;
      line-height: 250px;
      overflow: hidden;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: rgb(155, 155, 155);
    }
  }

  .rn-footer {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 40px;
    background-color: #fcfcfc;
    border-top: 1px solid #f0f0f0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    overflow: hidden;

    .rn-footer-message {
      width: 100%;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.8rem;
      color: $turivius;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .rr-wrapper {
    border-radius: 3px;
    max-width: 100vw;
    width: 500px;
    height: calc(100vh - 120px);
    z-index: 1;

    &.left {
      top: 50px;
      left: -395px;

      @media only screen and (max-width: 1270px) {
        top: -30px;
        left: -85px;
      }

      &:before {
        content: '';
        width: 0;
        height: 0;
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;
        border-bottom: 15px solid #fcfcfc;
        position: absolute;
        top: -15px;
        left: 320px;
      }
    }

    &.right {
      top: 50px;
      left: 100px;

      @media only screen and (max-width: 1270px) {
        top: 10px;
        left: 0px;
      }
    }

    .notification-holder {
      border-radius: 3px;
      width: 100%;
      height: 100%;
      position: absolute;
      overflow: hidden;
      border-radius: 3px;
    }
  }

  &.light-theme {
    .notification-box {
      background-color: #eff8ff;
    }

    .notification-list {
      .header {
        border-bottom: 1px solid #f0f0f0;

        h4 {
          color: #666;
        }
      }

      .contents {
        li.item {
          border-bottom: 1px solid #f9f9f9;

          .short-desc {
            color: #444;
          }
        }
      }

      .footer {
        border-top: 1px solid #f0f0f0;
      }
    }
  }
}

@-webkit-keyframes webkit_pulse_animation {
  0% {
    -webkit-transform: scale(1);
  }

  50% {
    -webkit-transform: scale(1.2);
  }

  100% {
    -webkit-transform: scale(1);
  }
}

@keyframes pulse_animation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}
#notification-popover {
  padding: 0px;
  margin-top: 20px;
}

.notification-header {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid #e4e7ec;
  margin-bottom: 10px;
  padding-bottom: 50px;
}

@import '../../../../../vars.module.scss';

.delete-button {
  background-color: #f2c5cb;
  color: #c8051c;
  font-weight: 500;

  .rs-icon {
    background-color: #f2c5cb;
  }

  &:hover,
  &:focus {
    background-color: #f2c5cb;
    color: #c8051c;

    .rs-icon {
      background-color: #f2c5cb;
    }
  }
}

.tv-badge {
  @include typography-body5_preset;
  width: fit-content;
  display: inline-block;

  &-circle {
    &_sm {
      border-radius: 50%;
      padding: 2px 6px;
      border-radius: 16px;
    }
    &_md {
      border-radius: 50%;
      padding: 4px 8px;
      border-radius: 16px;
    }
    &_lg {
      border-radius: 50%;
      padding: 30px 24px;
    }
  }
  &-square {
    &_sm {
      border-radius: 4px;
      padding: 2px 6px;
    }
    &_md {
      border-radius: 4px;
      padding: 4px 8px;
    }
    &_lg {
      border-radius: 8px;
      padding: 30px 24px;
    }
  }

  &-primary {
    background-color: $blue100 !important;
    color: $blue600 !important;
  }
  &-gray {
    background-color: $gray200 !important;
    color: $gray600 !important;
  }
  &-success {
    background-color: $success100 !important;
    color: $success500 !important;
  }
  &-warning {
    background-color: $warning100 !important;
    color: $warning500 !important;
  }
  &-danger {
    background-color: $danger100 !important;
    color: $danger500 !important;
  }

  &-violet {
    background-color: $purple100 !important;
    color: $purple600 !important;
  }
  &-azure {
    background-color: $information100;
    color: $information500;
  }
}

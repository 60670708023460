.avatar {
  @include typography-body5_preset;
  width: fit-content;
  overflow: hidden;
  & img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
  }
  &-circle {
    border-radius: 50%;
  }
  &-square {
    border-radius: 8px;
  }

  &-sm {
    padding: 8px 14px;
  }
  &-md {
    padding: 10px 16px;
  }
  &-lg {
    padding: 18px 24px;
  }

  &-primary {
    background-color: $blue100;
    color: $blue600;
  }
  &-gray {
    background-color: $gray200;
    color: $gray600;
  }
  &-success {
    background-color: $success100;
    color: $success500;
  }
  &-warning {
    background-color: $warning100;
    color: $warning500;
  }
  &-danger {
    background-color: $danger100;
    color: $danger500;
  }
  &-yellow {
    background-color: $warning100;
    color: $warning500;
  }
  &-orange {
    background-color: $warning100;
    color: $warning500;
  }
  &-violet {
    background-color: $purple100;
    color: $purple600;
  }
  &-azure {
    background-color: $information100;
    color: $information500;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Public+Sans&family=Sora:wght@400;700&display=swap');

:export {
  turivius: #00007d;
  turivius_light: #053ac8;
  turivius_dark: #02032f;
  secondary: #0d91fe;
  secondary_light: #0d91fe;
  secondary_lighter: #84d4ff;
  secondary_dark: #0069f5;
  turivius_hot: #cc66ff;
  red: #e61e25;
  pastel_red: #ff6961;
  green: #15c16b;
  black: #3a3a3a;
  black_deep: #000000;
  white: #ffffff;
  gray: #aaa;
  body_color: #fff;
  darker_body_color: #f9f9f9;
  link_color: #039be5;
  ptsans: 'PT Sans', Helvetica, sans-serif;
}

body {
  font-family: 'Public Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rubik', sans-serif;
}

.title {
  margin-bottom: 8px;
}

.title label {
  color: #3a3a3a;
  font-weight: 700;
}

.icon-btn-action {
  border: 1px solid #001645;
  background-color: #ffffff;
  color: #001645;
  letter-spacing: 0.5px;
  font-size: 13px;
  height: 35.4px;
  padding: 0px 16px;
}

.icon-btn-action .rs-icon,
.icon-btn-action .rs-icon:hover,
.icon-btn-action .rs-icon:focus,
.icon-btn-action .rs-icon:active {
  background-color: transparent !important;
}

.icon-btn-action svg {
  height: 1.5em;
}

.icon-btn-action:hover {
  background-color: #001645;
  color: #ffffff;
}

.entity-indicator .progress {
  float: left;
  background-color: transparent !important;
  width: 100px !important;
  height: 100px !important;
  line-height: 100px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}

.entity-indicator .progress:after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 7px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.entity-indicator .progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.entity-indicator .progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none !important;
  border-width: 7px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.entity-indicator .progress .progress-value {
  color: #eee;
  width: 86px;
  height: 86px !important;
  display: flex;
  border-radius: 50%;
  font-size: 15px;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container .head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 85%;
  padding: 0;
}

.container .head div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .head p {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.container .head .indicator {
  margin-right: 15px;
}

.container .actions {
  width: 15%;
}

.btnAction {
  border: 1px solid #001645;
  background-color: #ffffff;
  color: #001645;
  border-radius: 2px;
  display: inline-block;
  letter-spacing: 0.5px;
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
  height: 35.4px;
  padding: 0px 16px;
}

.btnAction svg {
  display: block;
  float: left;
  height: 1.5em;
}

.btnAction:hover {
  background-color: #001645;
  color: #ffffff;
}

.publishedContainer {
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  margin: 0px 20px;
  border-bottom: 1px solid #001645;
}

.publishedContainer p {
  color: #666666;
  width: 50%;
  height: 20px;
  margin: 0;
  padding: 0;
}

.publishedContainer p:hover {
  font-weight: bold;
}

.visualizeContainer {
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  margin: 0px 20px;
  border-bottom: 1px solid #001645;
  align-items: center;
  font-size: 11.6px;
}

.visualizeContainer p {
  cursor: pointer;
  color: #4965a0;
  text-decoration: underline;
  font-size: 11.6px;
}

.ementaContainer {
  margin: 0px 20px;
  align-items: center;
}

.ementaContainer .ementaTitle {
  color: #ffffff;
  background-color: #4965a0;
  font-size: 11.6px;
  padding: 2px 0px 0px 4px;
  font-weight: bold;
}

.ementaContainer .ementaText {
  font-size: 1rem;
  overflow-y: hidden;
  text-align: justify;
}

.decisionContainer {
  margin: 0px 20px;
}

.decisionContainer .decisionTitle {
  color: #ffffff;
  background-color: #4965a0;
  font-size: 11.6px;
  padding: 2px 0px 0px 4px;
  font-weight: bold;
}

.decisionContainer .decisionText {
  font-size: 1rem;
  overflow-y: hidden;
  text-align: justify;
}

.actionBottom {
  margin: 0px 20px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #cecece;
  padding: 5px 0px;
}

.actionBottom .btnActionSave {
  height: 36px;
  width: 48%;
  margin: 3px;
  border-radius: 2px;
  letter-spacing: 0.5px;
  background-color: #15c16b;
  color: #ffffff;
  font-weight: bold;
  font-size: 11.6px;
}

.actionBottom .btnActionStatic {
  height: 36px;
  width: 48%;
  margin: 3px;
  border-radius: 2px;
  letter-spacing: 0.5px;
  font-size: 11.6px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.actionBottom .btnActionStatic svg {
  display: block;
  float: left;
  height: 1.5em;
}

.actionBottom .btnActionSave:hover {
  background-color: #ffffff;
  border: 1px solid #15c16b;
  color: #15c16b;
}

.turivius-preloader.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.turivius-preloader .turivius-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin: -50px 0 0 -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.turivius-preloader .turivius-spinner .rs-loader-spin,
.turivius-preloader .turivius-spinner .rs-loader-spin::after,
.turivius-preloader .turivius-spinner .rs-loader-spin::before {
  height: 100px;
  width: 100px;
}

.turivius-preloader .turivius-spinner-image {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
}

.turivius-preloader.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.turivius-preloader .turivius-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin: -50px 0 0 -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.turivius-preloader .turivius-spinner .rs-loader-spin,
.turivius-preloader .turivius-spinner .rs-loader-spin::after,
.turivius-preloader .turivius-spinner .rs-loader-spin::before {
  height: 100px;
  width: 100px;
}

.turivius-preloader .turivius-spinner-image {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
}

.rs-panel-body {
  padding: 0;
}

.rs-pagination-group {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 15px;
}

:export {
  turivius: #00007d;
  turivius_light: #053ac8;
  turivius_dark: #02032f;
  secondary: #0d91fe;
  secondary_light: #0d91fe;
  secondary_lighter: #84d4ff;
  secondary_dark: #0069f5;
  turivius_hot: #cc66ff;
  red: #e61e25;
  pastel_red: #ff6961;
  green: #15c16b;
  black: #3a3a3a;
  black_deep: #000000;
  white: #ffffff;
  gray: #aaa;
  body_color: #fff;
  darker_body_color: #f9f9f9;
  link_color: #039be5;
  ptsans: 'PT Sans', Helvetica, sans-serif;
}

.rs-header .header-hero {
  width: 100%;
}

.rs-header .header-hero .rs-panel-body {
  padding: 8px 10px;
}

.rs-header .header-hero .rs-btn-ghost {
  color: #fff !important;
  border-color: #fff !important;
}

.rs-header .header-hero .rs-btn-primary:not(.btn-create) {
  background-color: #02032f !important;
  color: #fff !important;
}

.rs-header .header-hero .rs-btn-primary:not(.btn-create) i {
  background: #053ac8 !important;
}

.rs-header .header-hero .rs-btn-primary.btn-create {
  background-color: #06371f !important;
  color: #fff !important;
}

.rs-header .header-hero .rs-btn-primary.btn-create i {
  background: #15c16b !important;
}

@media only screen and (max-width: 499px) {

  .rs-header .header-hero h4,
  .rs-header .header-hero h5 {
    font-size: 15px;
  }

  .rs-header .header-hero p {
    font-size: 12px;
  }
}

.rs-header .custom-container-header {
  width: 100%;
  max-width: 100%;
  height: 100px;
  overflow: hidden;
}

.rs-header .custom-container-header .custom-container-header-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: #fff;
}

.rs-header .custom-container-header .header-shape {
  top: 95px;
  position: fixed;
  height: 10px;
  width: 100vw;
  background-color: #fff;
  border-radius: 6px;
}

.rs-content {
  max-height: 100vh;
  overflow: hidden;
}

.rs-content.page-content {
  background-color: #ffffff;
  border-left: 1px solid #ccc;
  max-height: calc(100vh - 100px);
  overflow: auto;
  top: -5px;
  position: relative;
  border-top-left-radius: 7px;
}

:export {
  turivius: #00007d;
  turivius_light: #053ac8;
  turivius_dark: #02032f;
  secondary: #0d91fe;
  secondary_light: #0d91fe;
  secondary_lighter: #84d4ff;
  secondary_dark: #0069f5;
  turivius_hot: #cc66ff;
  red: #e61e25;
  pastel_red: #ff6961;
  green: #15c16b;
  black: #3a3a3a;
  black_deep: #000000;
  white: #ffffff;
  gray: #aaa;
  body_color: #fff;
  darker_body_color: #f9f9f9;
  link_color: #039be5;
  ptsans: 'PT Sans', Helvetica, sans-serif;
}

@media only screen and (max-width: 499px) {
  .rs-content .custom-container .rs-header .custom-container-header .header-hero {
    padding-left: 40px;
  }
}

.custom-sidebar {
  flex: unset !important;
  width: unset !important;
}

.custom-sidenav {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  height: 100vh;
  background-color: #00007d !important;
}

.custom-sidenav .sidenav-header .user {
  padding: 16px 10px;
  color: white;
  font-weight: 600;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: column;
  display: flex;
}

.custom-sidenav .sidenav-header .user span {
  white-space: nowrap;
}

.custom-sidenav .sidenav-header .user .btn-header {
  color: white;
  padding: 2px;
  font-size: 12px;
}

.custom-sidenav .sidenav-header .user .btn-header:hover,
.custom-sidenav .sidenav-header .user .btn-header:focus {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
}

.custom-sidenav .option-sidenav {
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

.custom-sidenav .option-sidenav.footer {
  width: 100%;
  padding: 10px 0 10px 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.custom-sidenav .option-sidenav.dropdown {
  background-color: #00007d !important;
  padding: 5px 0px;
  color: #fff;
}

.custom-sidenav .option-sidenav .fragment-sidenav {
  margin-right: 10px;
}

.custom-sidenav .option-sidenav:focus,
.custom-sidenav .option-sidenav:focus-within {
  background-color: black !important;
}

.custom-sidenav .footer-sidebar {
  display: flex;
  align-items: center;
  width: 100% !important;
}

.custom-sidenav .footer-sidebar.rs-sidenav-item {
  margin-top: auto !important;
  margin-bottom: 10px !important;
}

.custom-sidenav .footer-sidebar .footer-sidenav {
  padding: 10px 10px;
  display: flex;
  align-items: center;
}

.custom-sidenav .footer-sidebar .fragment-sidenav {
  margin-right: 10px;
}

.custom-sidenav::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px #02032f;
  background-color: #00007d;
}

.custom-sidenav::-webkit-scrollbar {
  width: 3px;
  background-color: #00007d;
}

.custom-sidenav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #02032f;
}

@media only screen and (min-width: 500px) {
  .custom-sidenav {
    width: 150px !important;
  }

  .custom-sidenav .open-sidebar {
    display: none !important;
  }
}

@media only screen and (max-width: 499px) {
  .custom-sidenav {
    width: 140px !important;
    left: -150px;
    z-index: 10;
    position: absolute;
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }

  .custom-sidenav .open-sidebar {
    position: fixed;
    left: 20px;
    z-index: 100;
    height: 67px;
    display: flex;
    align-items: center;
    background-color: #00007d;
    height: 30px;
    padding: 5px 7px;
    border-radius: 5px;
  }

  .custom-sidenav .open-sidebar.close {
    left: 150px;
    background-color: #cc66ff;
  }

  .custom-sidenav.open {
    left: 0px;
    transition: left 0.2s linear;
  }
}

.custom-sidenav #turivius-sidenav {
  height: 100vh;
}

.custom-sidenav .rs-sidenav-default {
  background-color: transparent;
}

.custom-sidenav .rs-sidenav-nav {
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
}

.custom-sidenav .rs-dropdown {
  flex: unset;
}

.custom-sidenav .rs-sidenav-header {
  padding-top: 15px;
  text-align: center;
}

.custom-sidenav .rs-sidenav-body {
  padding-top: 15px;
  height: 100% !important;
  display: flex;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle {
  background-color: transparent;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item:hover,
.custom-sidenav .rs-sidenav-body .rs-sidenav-item:active,
.custom-sidenav .rs-sidenav-body .rs-sidenav-item:focus,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle:hover,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle:active,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle:focus {
  text-decoration: none !important;
  box-shadow: none;
}

@media only screen and (min-width: 500px) {

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle {
    text-align: center;
  }

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle {
    padding-left: 0px;
  }

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item span,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle span {
    font-size: 13px;
  }
}

@media only screen and (max-width: 499px),
screen and (max-height: 700px) {

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle {
    text-align: center;
  }

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle {
    padding: 8px 0px;
    height: auto;
  }

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item i,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle i {
    font-size: 1em;
  }

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item .rs-avatar,
  .custom-sidenav .rs-sidenav-body .rs-sidenav-item img,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle .rs-avatar,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle img {
    width: 20px;
    height: 20px;
  }

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item span,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle span {
    font-size: 12px;
  }
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active),
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item {
  position: relative;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active),
.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active) *,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle *,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item * {
  color: #fff;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active)::after,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle::after,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item::after {
  content: '';
  position: absolute;
  width: 0px;
  height: 2px;
  left: 0%;
  bottom: 0;
  transition: all ease-in 0.3s;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active):hover,
.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active):focus,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle:hover,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle:focus,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item:hover,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item:focus {
  background: transparent !important;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active):hover::after,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle:hover::after,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item:hover::after {
  width: 70%;
  left: 0;
}

.custom-sidenav .rs-sidenav-body .rs-dropdown-item span {
  font-size: 12px;
  color: #fff;
  margin-left: 25px;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item-active {
  font-weight: bold;
  background-color: transparent;
  border-bottom: 2px solid #fff;
  border-image-slice: 1;
  -o-border-image: linear-gradient(to left,
      rgba(108, 219, 141, 0) 30%,
      #fff 0%,
      #fff 100%,
      rgba(108, 255, 141, 0) 100%);
  border-image: linear-gradient(to left,
      rgba(108, 219, 141, 0) 30%,
      #fff 0%,
      #fff 100%,
      rgba(108, 255, 141, 0) 100%);
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item-active * {
  color: #fff;
}

.image-logo {
  max-height: 55px;
  max-width: 100%;
  margin: auto;
  padding: 0px;
  margin-top: 20px;
}

.turivius-preloader.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.turivius-preloader .turivius-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin: -50px 0 0 -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.turivius-preloader .turivius-spinner .rs-loader-spin,
.turivius-preloader .turivius-spinner .rs-loader-spin::after,
.turivius-preloader .turivius-spinner .rs-loader-spin::before {
  height: 100px;
  width: 100px;
}

.turivius-preloader .turivius-spinner-image {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
}

.turivius-preloader.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.2);
}

.turivius-preloader .turivius-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  margin: -50px 0 0 -50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

.turivius-preloader .turivius-spinner .rs-loader-spin,
.turivius-preloader .turivius-spinner .rs-loader-spin::after,
.turivius-preloader .turivius-spinner .rs-loader-spin::before {
  height: 100px;
  width: 100px;
}

.turivius-preloader .turivius-spinner-image {
  height: 60px;
  width: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
}

.entity-indicator .progress {
  float: left;
  background-color: transparent !important;
  width: 100px !important;
  height: 100px !important;
  line-height: 100px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}

.entity-indicator .progress:after {
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 7px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
}

.entity-indicator .progress>span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}

.entity-indicator .progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none !important;
  border-width: 7px;
  border-style: solid;
  position: absolute;
  top: 0;
}

.entity-indicator .progress .progress-value {
  color: #eee;
  width: 86px;
  height: 86px !important;
  display: flex;
  border-radius: 50%;
  font-size: 15px;
  text-align: center;
  line-height: 20px;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  margin: 0;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.container .head {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 85%;
  padding: 0;
}

.container .head div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container .head p {
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.4rem;
}

.container .head .indicator {
  margin-right: 15px;
}

.container .actions {
  width: 15%;
}

.btnAction {
  border: 1px solid #001645;
  background-color: #ffffff;
  color: #001645;
  border-radius: 2px;
  display: inline-block;
  letter-spacing: 0.5px;
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
  height: 35.4px;
  padding: 0px 16px;
}

.btnAction svg {
  display: block;
  float: left;
  height: 1.5em;
}

.btnAction:hover {
  background-color: #001645;
  color: #ffffff;
}

.publishedContainer {
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  margin: 0px 20px;
  border-bottom: 1px solid #001645;
}

.publishedContainer p {
  color: #666666;
  width: 50%;
  height: 20px;
  margin: 0;
  padding: 0;
}

.publishedContainer p:hover {
  font-weight: bold;
}

.visualizeContainer {
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  margin: 0px 20px;
  border-bottom: 1px solid #001645;
  align-items: center;
  font-size: 11.6px;
}

.visualizeContainer p {
  cursor: pointer;
  color: #4965a0;
  text-decoration: underline;
  font-size: 11.6px;
}

.ementaContainer {
  margin: 0px 20px;
  align-items: center;
}

.ementaContainer .ementaTitle {
  color: #ffffff;
  background-color: #4965a0;
  font-size: 11.6px;
  padding: 2px 0px 0px 4px;
  font-weight: bold;
}

.ementaContainer .ementaText {
  font-size: 1rem;
  overflow-y: hidden;
  text-align: justify;
}

.decisionContainer {
  margin: 0px 20px;
}

.decisionContainer .decisionTitle {
  color: #ffffff;
  background-color: #4965a0;
  font-size: 11.6px;
  padding: 2px 0px 0px 4px;
  font-weight: bold;
}

.decisionContainer .decisionText {
  font-size: 1rem;
  overflow-y: hidden;
  text-align: justify;
}

.actionBottom {
  margin: 0px 20px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #cecece;
  padding: 5px 0px;
}

.actionBottom .btnActionSave {
  height: 36px;
  width: 48%;
  margin: 3px;
  border-radius: 2px;
  letter-spacing: 0.5px;
  background-color: #15c16b;
  color: #ffffff;
  font-weight: bold;
  font-size: 11.6px;
}

.actionBottom .btnActionStatic {
  height: 36px;
  width: 48%;
  margin: 3px;
  border-radius: 2px;
  letter-spacing: 0.5px;
  font-size: 11.6px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.actionBottom .btnActionStatic svg {
  display: block;
  float: left;
  height: 1.5em;
}

.actionBottom .btnActionSave:hover {
  background-color: #ffffff;
  border: 1px solid #15c16b;
  color: #15c16b;
}

.rs-panel-body {
  padding: 0;
}

.rs-pagination-group {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 15px;
}

:export {
  turivius: #00007d;
  turivius_light: #053ac8;
  turivius_dark: #02032f;
  secondary: #0d91fe;
  secondary_light: #0d91fe;
  secondary_lighter: #84d4ff;
  secondary_dark: #0069f5;
  turivius_hot: #cc66ff;
  red: #e61e25;
  pastel_red: #ff6961;
  green: #15c16b;
  black: #3a3a3a;
  black_deep: #000000;
  white: #ffffff;
  gray: #aaa;
  body_color: #fff;
  darker_body_color: #f9f9f9;
  link_color: #039be5;
  ptsans: 'PT Sans', Helvetica, sans-serif;
}

@media only screen and (max-width: 499px) {
  .rs-content .custom-container .rs-header .custom-container-header .header-hero {
    padding-left: 40px;
  }
}

.custom-sidebar {
  flex: unset !important;
  width: unset !important;
}

.custom-sidenav {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  height: 100vh;
  background-color: #00007d !important;
}

.custom-sidenav .sidenav-header .user {
  padding: 16px 10px;
  color: white;
  font-weight: 600;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-direction: column;
  display: flex;
}

.custom-sidenav .sidenav-header .user span {
  white-space: nowrap;
}

.custom-sidenav .sidenav-header .user .btn-header {
  color: white;
  padding: 2px;
  font-size: 12px;
}

.custom-sidenav .sidenav-header .user .btn-header:hover,
.custom-sidenav .sidenav-header .user .btn-header:focus {
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
}

.custom-sidenav .option-sidenav {
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

.custom-sidenav .option-sidenav.footer {
  width: 100%;
  padding: 10px 0 10px 10px;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.custom-sidenav .option-sidenav.dropdown {
  background-color: #00007d !important;
  padding: 5px 0px;
  color: #fff;
}

.custom-sidenav .option-sidenav .fragment-sidenav {
  margin-right: 10px;
}

.custom-sidenav .option-sidenav:focus,
.custom-sidenav .option-sidenav:focus-within {
  background-color: black !important;
}

.custom-sidenav .footer-sidebar {
  display: flex;
  align-items: center;
  width: 100% !important;
}

.custom-sidenav .footer-sidebar.rs-sidenav-item {
  margin-top: auto !important;
  margin-bottom: 10px !important;
}

.custom-sidenav .footer-sidebar .footer-sidenav {
  padding: 10px 10px;
  display: flex;
  align-items: center;
}

.custom-sidenav .footer-sidebar .fragment-sidenav {
  margin-right: 10px;
}

.custom-sidenav::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px #02032f;
  background-color: #00007d;
}

.custom-sidenav::-webkit-scrollbar {
  width: 3px;
  background-color: #00007d;
}

.custom-sidenav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #02032f;
}

@media only screen and (min-width: 500px) {
  .custom-sidenav {
    width: 150px !important;
  }

  .custom-sidenav .open-sidebar {
    display: none !important;
  }
}

@media only screen and (max-width: 499px) {
  .custom-sidenav {
    width: 140px !important;
    left: -150px;
    z-index: 10;
    position: absolute;
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);
  }

  .custom-sidenav .open-sidebar {
    position: fixed;
    left: 20px;
    z-index: 100;
    height: 67px;
    display: flex;
    align-items: center;
    background-color: #00007d;
    height: 30px;
    padding: 5px 7px;
    border-radius: 5px;
  }

  .custom-sidenav .open-sidebar.close {
    left: 150px;
    background-color: #cc66ff;
  }

  .custom-sidenav.open {
    left: 0px;
    transition: left 0.2s linear;
  }
}

.custom-sidenav #turivius-sidenav {
  height: 100vh;
}

.custom-sidenav .rs-sidenav-default {
  background-color: transparent;
}

.custom-sidenav .rs-sidenav-nav {
  display: flex !important;
  flex-direction: column !important;
  width: 100%;
}

.custom-sidenav .rs-dropdown {
  flex: unset;
}

.custom-sidenav .rs-sidenav-header {
  padding-top: 15px;
  text-align: center;
}

.custom-sidenav .rs-sidenav-body {
  padding-top: 15px;
  height: 100% !important;
  display: flex;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle {
  background-color: transparent;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item:hover,
.custom-sidenav .rs-sidenav-body .rs-sidenav-item:active,
.custom-sidenav .rs-sidenav-body .rs-sidenav-item:focus,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle:hover,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle:active,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle:focus {
  text-decoration: none !important;
  box-shadow: none;
}

@media only screen and (min-width: 500px) {

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle {
    text-align: center;
  }

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle {
    padding-left: 0px;
  }

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item span,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle span {
    font-size: 13px;
  }
}

@media only screen and (max-width: 499px),
screen and (max-height: 700px) {

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle {
    text-align: center;
  }

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle {
    padding: 8px 0px;
    height: auto;
  }

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item i,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle i {
    font-size: 1em;
  }

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item .rs-avatar,
  .custom-sidenav .rs-sidenav-body .rs-sidenav-item img,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle .rs-avatar,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle img {
    width: 20px;
    height: 20px;
  }

  .custom-sidenav .rs-sidenav-body .rs-sidenav-item span,
  .custom-sidenav .rs-sidenav-body .rs-dropdown-toggle span {
    font-size: 12px;
  }
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active),
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item {
  position: relative;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active),
.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active) *,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle *,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item * {
  color: #fff;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active)::after,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle::after,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item::after {
  content: '';
  position: absolute;
  width: 0px;
  height: 2px;
  left: 0%;
  bottom: 0;
  transition: all ease-in 0.3s;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active):hover,
.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active):focus,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle:hover,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle:focus,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item:hover,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item:focus {
  background: transparent !important;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item:not(.rs-sidenav-item-active):hover::after,
.custom-sidenav .rs-sidenav-body .rs-dropdown-toggle:hover::after,
.custom-sidenav .rs-sidenav-body .rs-dropdown-item:hover::after {
  width: 70%;
  left: 0;
}

.custom-sidenav .rs-sidenav-body .rs-dropdown-item span {
  font-size: 12px;
  color: #fff;
  margin-left: 25px;
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item-active {
  font-weight: bold;
  background-color: transparent;
  border-bottom: 2px solid #fff;
  border-image-slice: 1;
  -o-border-image: linear-gradient(to left,
      rgba(108, 219, 141, 0) 30%,
      #fff 0%,
      #fff 100%,
      rgba(108, 255, 141, 0) 100%);
  border-image: linear-gradient(to left,
      rgba(108, 219, 141, 0) 30%,
      #fff 0%,
      #fff 100%,
      rgba(108, 255, 141, 0) 100%);
}

.custom-sidenav .rs-sidenav-body .rs-sidenav-item-active * {
  color: #fff;
}

.image-logo {
  max-height: 55px;
  max-width: 100%;
  margin: auto;
  padding: 0px;
  margin-top: 20px;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.container div {
  display: flex;
  padding: 20px;
  align-items: center;
  flex-direction: column;
}

img {
  max-width: 500px;
  max-height: 450px;
  width: auto;
  height: auto;
}

.containerTxtNotFound {
  align-items: flex-start !important;
}

.containerTxtNotFound .tagContainer {
  font-size: 13px;
  border-bottom: 3px solid #123b71;
  color: #123b71;
  font-weight: bold;
  text-transform: uppercase;
  padding-bottom: 10px;
  width: 45%;
}

.containerTxtNotFound .titleContainer {
  font-size: 30px;
  font-weight: bold;
  color: #123b71;
}

.containerTxtNotFound .bodyContainer {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
  margin-top: 20px;
}

.containerTxtNotFound .btnContainer {
  background-color: #123b71;
  color: #ffffff;
  font-weight: bold;
  margin-top: 10px;
}

@media screen and (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  img {
    max-width: 300px;
    max-height: 250px;
    width: auto;
    height: auto;
  }
}

:export {
  turivius: #00007d;
  turivius_light: #053ac8;
  turivius_dark: #02032f;
  secondary: #0d91fe;
  secondary_light: #0d91fe;
  secondary_lighter: #84d4ff;
  secondary_dark: #0069f5;
  turivius_hot: #cc66ff;
  red: #e61e25;
  pastel_red: #ff6961;
  green: #15c16b;
  black: #3a3a3a;
  black_deep: #000000;
  white: #ffffff;
  gray: #aaa;
  body_color: #fff;
  darker_body_color: #f9f9f9;
  link_color: #039be5;
  ptsans: 'PT Sans', Helvetica, sans-serif;
}

.login-page {
  height: 100vh;
  display: flex;
}

.login-page .rs-flex-box-grid {
  flex-direction: column;
}

.login-page .logo-image.inside {
  width: 100%;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}

.login-page .logo-image.inside img {
  max-width: 190px;
  max-height: 140px;
}

.login-page .logo-image.outside {
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
}

.login-page .logo-image.outside img {
  max-width: 200px;
  max-height: 150px;
}

.login-page .box-login-footer {
  background: #ffffff;
  border-radius: 0px;
  padding: 24px 48px 48px 48px;
}

.login-page .box-login {
  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px !important;
  padding: 24px 48px 48px 48px;
}

.login-page .box-login h4 {
  font-weight: bold;
  color: #000000;
}

.login-page .box-login .rs-form-control-label {
  font-weight: 700;
  color: #02032f;
}

.login-page .box-login .rs-input {
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #00000033;
  padding: 7px 0;
}

.login-page .box-login .rs-input:focus,
.login-page .box-login .rs-input:focus-within {
  outline: none;
}

.login-page .rs-content {
  height: 100%;
}

.login-page .rs-flex-box-grid {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-page .rs-flex-box-grid .rs-flex-box-grid-item {
  max-width: 400px;
}

.login-page .rs-panel {
  background: #fff;
}

.login-page .rs-panel .rs-panel-title h4 {
  text-align: center;
  margin-top: 15px;
  margin-bottom: 0px;
}

.login-page .rs-panel .rs-panel-title .avatar {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: -100px;
  width: 130px !important;
  height: 130px !important;
  border-radius: 50%;
  z-index: 9;
  background: #00007d;
  padding: 15px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.login-page .rs-panel .rs-panel-title .avatar img {
  width: 100px;
}

.login-page .container-buttons .btn-login {
  background-color: #053ac8;
  color: #ffffff;
  font-weight: bold;
  border-radius: 4px;
}

.login-page .container-buttons .btn-login.again {
  color: #053ac8;
  background-color: transparent;
}

.login-page .container-buttons .btn-forgot {
  border-radius: 4px;
  color: #053ac8;
  font-weight: 700;
  text-align: left;
  padding: 8px 0;
}

@media only screen and (max-width: 420px) {
  .login-page .logo-image.inside img {
    max-width: 130px;
    max-height: 80px;
  }

  .login-page .box-login {
    padding: 30px !important;
  }
}

:export {
  turivius: #00007d;
  turivius_light: #053ac8;
  turivius_dark: #02032f;
  secondary: #0d91fe;
  secondary_light: #0d91fe;
  secondary_lighter: #84d4ff;
  secondary_dark: #0069f5;
  turivius_hot: #cc66ff;
  red: #e61e25;
  pastel_red: #ff6961;
  green: #15c16b;
  black: #3a3a3a;
  black_deep: #000000;
  white: #ffffff;
  gray: #aaa;
  body_color: #fff;
  darker_body_color: #f9f9f9;
  link_color: #039be5;
  ptsans: 'PT Sans', Helvetica, sans-serif;
}

.turivius-search-operators {
  word-break: break-word;
  cursor: pointer;
  color: #2e2e2e;
}

.turivius-search-operators .operator-btn {
  background-color: #ffffff;
  padding: 4px 8px;
  border-radius: 4px;
  margin-right: 4px;
  font-weight: 600;
  border: 0.5px solid #000000;
}

.operators-popover mark {
  background-color: #e5f0fe;
}

.operators-popover .quote {
  margin-top: 8px;
  padding: 8px;
  background-color: #f9f9f9;
  border-radius: 2px;
  border-left: 4px solid #006af5;
}

:export {
  turivius: #00007d;
  turivius_light: #053ac8;
  turivius_dark: #02032f;
  secondary: #0d91fe;
  secondary_light: #0d91fe;
  secondary_lighter: #84d4ff;
  secondary_dark: #0069f5;
  turivius_hot: #cc66ff;
  red: #e61e25;
  pastel_red: #ff6961;
  green: #15c16b;
  black: #3a3a3a;
  black_deep: #000000;
  white: #ffffff;
  gray: #aaa;
  body_color: #fff;
  darker_body_color: #f9f9f9;
  link_color: #039be5;
  ptsans: 'PT Sans', Helvetica, sans-serif;
}

.select-entity {
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-entity p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.select-entity .rs-icon {
  background-color: #ffffff;
  margin-left: 1px;
}

.select-entity .rs-icon:hover,
.select-entity .rs-icon:focus {
  background-color: #ffffff;
  opacity: 1;
}

.select-entity:hover .rs-icon,
.select-entity:focus {
  background-color: #ffffff;
  opacity: 1;
}

.select-entity:focus-within {
  box-shadow: none;
}

.turivius-modal-entities-filter .rs-modal-header button {
  margin: 0px 8px;
}

.turivius-modal-entities-filter .new-badge {
  background-color: #0069f5 !important;
  color: #ffffff !important;
  border-radius: 2px !important;
  padding: 2px 2px !important;
  font-size: 9px !important;
  margin-left: 0px;
  margin-right: 2px;
  font-weight: 600;
  line-height: 10px !important;
}

.turivius-modal-entities-filter .new-badge .rs-tag-text {
  margin: auto !important;
}

.turivius-modal-entities-filter .rs-modal-header span {
  font-size: 1.2em;
  font-weight: 700;
  color: #444;
}

.turivius-modal-entities-filter .rs-modal-body {
  margin-top: 4px;
}

.turivius-modal-entities-filter .rs-modal-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.turivius-modal-entities-filter .rs-modal-body::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.turivius-modal-entities-filter .rs-modal-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.turivius-modal-entities-filter .rs-modal-footer {
  border-top: 1px solid #eee;
  padding-top: 16px;
}

.turivius-modal-checkbox-group-filter {
  margin-bottom: 5px;
}

.turivius-modal-checkbox-group-filter .checkbox-input-field-contente {
  text-align: left;
  width: 100%;
  padding: 0px 10px !important;
  width: 100%;
}

.turivius-modal-checkbox-group-filter .checkbox-input-field-contente .checkbox-group-category-title {
  text-transform: uppercase;
  color: #777777;
  display: inline-flex;
  align-items: center;
  margin-left: -16px;
}

.turivius-modal-checkbox-group-filter .checkbox-input-field-contente .checkbox-group-category-title label {
  color: #444;
  font-weight: 600;
  font-size: 1em;
}

.turivius-modal-checkbox-group-filter .checkbox-input-field-contente .checkbox-group-category-container-row {
  margin-bottom: 15px;
  max-width: 100%;
}

.turivius-modal-checkbox-group-filter .checkbox-input-field-contente .checkbox-group-category-container-row:nth-last-child(1) {
  border: none;
}

.turivius-modal-checkbox-group-filter .checkbox-input-field-contente .rs-checkbox-checker {
  padding-top: 10px;
  padding-bottom: 6px;
}

.turivius-modal-checkbox-group-filter .checkbox-input-field-contente .checkbox-group-entity-container .first-line-group-entity {
  display: flex;
  flex-direction: row;
}

.turivius-modal-checkbox-group-filter .checkbox-input-field-contente .checkbox-group-entity-container .counter-entity {
  margin: 0px;
  margin-top: 3px;
  font-size: 9px;
  line-height: 10px;
}

.turivius-modal-checkbox-group-filter .checkbox-input-field-contente .checkbox-group-entity-container i,
.turivius-modal-checkbox-group-filter .checkbox-input-field-contente .checkbox-group-entity-container svg {
  font-size: 0.8rem;
}

.turivius-modal-checkbox-group-filter .checkbox-input-field-contente .checkbox-group-entity-container i.new,
.turivius-modal-checkbox-group-filter .checkbox-input-field-contente .checkbox-group-entity-container svg.new {
  text-shadow: 0px 0px 1px #000000;
  color: #fdfd88;
}

.turivius-modal-checkbox-group-filter .checkbox-input-field-contente .checkbox-group-entity-container i.partial,
.turivius-modal-checkbox-group-filter .checkbox-input-field-contente .checkbox-group-entity-container svg.partial {
  color: #053ac8;
}

@media screen and (max-width: 1041px) {
  .search-entity {
    width: 40% !important;
  }

  .search-entity .select-entity {
    margin-left: 0px;
  }
}

@media screen and (max-width: 910px) {
  .search-entity {
    width: 100% !important;
  }

  .search-entity .select-entity {
    margin-left: 0px;
  }

  .search-entity .select-entity .rs-icon {
    border-left: none;
  }
}

.input-field-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

#initial-date-filter,
#final-date-filter {
  text-align: center;
}

:export {
  turivius: #00007d;
  turivius_light: #053ac8;
  turivius_dark: #02032f;
  secondary: #0d91fe;
  secondary_light: #0d91fe;
  secondary_lighter: #84d4ff;
  secondary_dark: #0069f5;
  turivius_hot: #cc66ff;
  red: #e61e25;
  pastel_red: #ff6961;
  green: #15c16b;
  black: #3a3a3a;
  black_deep: #000000;
  white: #ffffff;
  gray: #aaa;
  body_color: #fff;
  darker_body_color: #f9f9f9;
  link_color: #039be5;
  ptsans: 'PT Sans', Helvetica, sans-serif;
}

.inteiro-teor-filter {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;
}

.inteiro-teor-filter .title-option {
  margin-bottom: 10px;
  color: #3a3a3a;
  font-weight: 700;
}

.inteiro-teor-filter .rs-radio-group-picker {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 56px;
}

.inteiro-teor-filter .rs-radio-group-picker .rs-radio-checked {
  background-color: #1675e0;
  border-radius: 5px;
}

.inteiro-teor-filter .rs-radio-group-picker .rs-radio-checked label {
  color: #ffffff;
}

.inteiro-teor-filter .rs-radio-group-picker .rs-radio-inline {
  margin-left: 0;
  width: 100%;
  text-align: center;
}

.inteiro-teor-filter .rs-radio-group-picker .rs-radio-inline .rs-radio-checker {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inteiro-teor-filter .rs-radio-group-picker .rs-radio-inline .rs-radio-checker label {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

@media only screen and (max-width: 1400px) {

  .inteiro-teor-filter .rs-radio-group-picker .rs-radio-inline .rs-radio-checker label,
  .inteiro-teor-filter .rs-radio-group-picker .rs-radio-inline .rs-radio-checker span {
    font-size: 12px !important;
  }
}

:export {
  turivius: #00007d;
  turivius_light: #053ac8;
  turivius_dark: #02032f;
  secondary: #0d91fe;
  secondary_light: #0d91fe;
  secondary_lighter: #84d4ff;
  secondary_dark: #0069f5;
  turivius_hot: #cc66ff;
  red: #e61e25;
  pastel_red: #ff6961;
  green: #15c16b;
  black: #3a3a3a;
  black_deep: #000000;
  white: #ffffff;
  gray: #aaa;
  body_color: #fff;
  darker_body_color: #f9f9f9;
  link_color: #039be5;
  ptsans: 'PT Sans', Helvetica, sans-serif;
}

.turivius-checkbox-group-filter .checkbox-input-field {
  margin-top: 8px;
  border: #0000003b solid 1px;
  border-radius: 4px;
  padding: 10px;
  height: 150px;
  width: 100%;
  overflow-y: auto;
}

.turivius-checkbox-group-filter .checkbox-input-field.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.turivius-checkbox-group-filter .checkbox-input-field .checkbox-group {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
}

.turivius-checkbox-group-filter .checkbox-input-field .checkbox-group .checkbox-group-category-label {
  margin-right: 8px;
  font-size: 14px;
  text-transform: uppercase;
}

.turivius-checkbox-group-filter .checkbox-input-field .checkbox-group .checkbox-group-category-action {
  margin-right: 8px;
  background-color: #0d91fe;
  padding: 0px 5px;
  border-radius: 3px;
  color: #ffffff;
  font-size: 0.7rem;
  cursor: pointer;
}

.turivius-checkbox-group-filter .checkbox-input-field .checkbox-group .checkbox-group-category-action:hover {
  background-color: #0069f5;
}

.turivius-checkbox-group-filter .checkbox-input-field .checkbox-group-label-container .first-line {
  display: flex;
  align-items: center;
}

.turivius-checkbox-group-filter .checkbox-input-field .checkbox-group-label-container .first-line .new-badge {
  background-color: #0d91fe;
  color: #ffffff;
  border-radius: 2px;
  padding: 0px 2px;
  font-size: 9px;
  margin-left: 0px;
  margin-right: 2px;
  font-weight: 600;
  line-height: 10px;
}

.turivius-checkbox-group-filter .checkbox-input-field .input-field-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.turivius-checkbox-group-filter .checkbox-input-field::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.turivius-checkbox-group-filter .checkbox-input-field::-webkit-scrollbar {
  width: 3px;
  background-color: #f5f5f5;
}

.turivius-checkbox-group-filter .checkbox-input-field::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #555;
}

.turivius-checkbox-group-filter .checkbox-input-field:hover {
  border: 1px solid rgba(0, 0, 0, 0.87);
}

:export {
  turivius: #00007d;
  turivius_light: #053ac8;
  turivius_dark: #02032f;
  secondary: #0d91fe;
  secondary_light: #0d91fe;
  secondary_lighter: #84d4ff;
  secondary_dark: #0069f5;
  turivius_hot: #cc66ff;
  red: #e61e25;
  pastel_red: #ff6961;
  green: #15c16b;
  black: #3a3a3a;
  black_deep: #000000;
  white: #ffffff;
  gray: #aaa;
  body_color: #fff;
  darker_body_color: #f9f9f9;
  link_color: #039be5;
  ptsans: 'PT Sans', Helvetica, sans-serif;
}

.turivius-single-group-filter .title {
  margin-bottom: 8px;
}

.turivius-single-group-filter .title label {
  color: #3a3a3a;
  font-weight: 700;
}

$turivius: #00007d;
$turivius-light: #053ac8;
$turivius-dark: #02032f;
$secondary: #0d91fe;
$secondary-light: #0d91fe;
$secondary-lighter: #84d4ff;
$secondary-dark: #0069f5;
$turivius-hot: #cc66ff;
$red: #e61e25;
$pastelred: #ff6961;
$green: #15c16b;
$black: #3a3a3a;
$black-deep: #000000;
$white: #ffffff;
$gray: #aaa;
$body-color: #fff;
$darker-body-color: #f9f9f9;
$link-color: #039be5;
$ptsans: 'PT Sans', Helvetica, sans-serif;

:export {
  turivius: $turivius;
  turivius_light: $turivius-light;
  turivius_dark: $turivius-dark;
  secondary: $secondary;
  secondary_light: $secondary-light;
  secondary_lighter: $secondary-lighter;
  secondary_dark: $secondary-dark;
  turivius_hot: $turivius-hot;
  red: $red;
  pastel_red: $pastelred;
  green: $green;
  black: $black;
  black_deep: $black-deep;
  white: $white;
  gray: $gray;
  body_color: $body-color;
  darker_body_color: $darker-body-color;
  link_color: $link-color;
  ptsans: $ptsans;
}

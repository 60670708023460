.single-filter-box {
  border-bottom: 1px solid #e4e7ec;
  padding-bottom: 16px;
  margin-bottom: 24px;

  &_title {
    color: #010c28;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  &_option {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }

  &.without-border {
    border-bottom: none;
    padding-bottom: 0;
  }

  &_container-title {
    margin-bottom: 16px;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .rs-radio-group {
    .rs-radio {
      height: fit-content;
      border: 1px solid #e4e7ec;
      font-size: 15px;
      border-radius: 50px;
      transition: 0.3s ease-in-out;
      padding: 2px 14px;
   

      .rs-radio-checker {
        padding: 6px;
        min-height: fit-content;

        .rs-radio-control {
          display: none;
        }

        .rs-radio-wrapper {
          display: none;
        }

        label {
          display: block;
          color: #475467;
          font-weight: 600;
          font-size: 12px;
          border-radius: 50px;
        }
      }

      &:hover {
        border: 1px solid #475467;
        background-color: #f2f4f7;
      }
    }

    .rs-radio-checked {
      background-color: $turivius-light;
      border-color: $turivius-light;
      transition: 0.3s ease-in-out;

      .rs-radio-checker {
        label {
          color: white;
        }
      }

      .rs-radio-control {
        display: none;
      }

      &:hover {
        background-color: #0069f5;
        border-color: #0069f5;
      }
    }
  }

  .rs-toggle.rs-toggle-checked {
    transition: 0.5ms ease-in-out;

    .rs-toggle-presentation {
      background-color: #15c16b;

      &:hover {
        background-color: #32d583;
      }
    }
  }

  .rs-picker-input {
    border-radius: 4px;

    .rs-tag-text {
      text-transform: capitalize;
    }
  }

  .rs-input-group {
    border: 1px solid #e5e5ea !important;
    border-radius: 4px;
  }
}

@import '../../../../../vars.module.scss';

.chart-title {
  text-align: left;
  color: $turivius;
  // text-overflow: ellipsis;
  // white-space: nowrap;
  overflow: hidden;
  margin-bottom: 10px;
  word-break: break-word;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import './helpers/variables/colors';
@import './helpers/variables/font-sizes';
@import './helpers/variables/font-weights';
@import './helpers/variables/line-heights';
@import './helpers/variables/borders';

@import './helpers/utils';
@import './helpers/spacing';

@import './base/reset';
@import './base/typography';

@import './layout/containers';

@import './components/button';
@import './components/toggle';
@import './components/card';
@import './components/alert';
@import './components/searchbar';
@import './components/badge';
@import './components/tag';
@import './components/dropdown';
@import './components/avatar';
@import './components/link';
@import './components/checkbox';
@import './components/select';
@import './components/nav-tabs';
@import './components/loaders';
@import './components/skeleton';

mark {
  background-color: $information100;
  border-radius: 4px;
  padding: 1px;
}

* {
  box-sizing: border-box;
}

h1 {
  @include typography-h1_preset;
}

h2 {
  @include typography-h2_preset;
}

h3 {
  @include typography-h3_preset;
}

h4 {
  @include typography-h4_preset;
}

h5 {
  @include typography-h5_preset;
}

h6 {
  @include typography-h6_preset;
}

p {
  @include typography-body3_preset;
}

span,
div {
  @include typography-body4_preset;
}

.checkbox-group-category-container-row-block {
  > :first-child {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
  }
}

.tv-select_collapsable {
  border-radius: 10px;
  padding: 22px 20.5px 22px !important; // EXPLANATION: Controla o padding do filtro de órgãos
  font-family: 'Arial', sans-serif;
  background-color: white;
  width: 100%;

  .tv-select_collapsable-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

    span {
      font-weight: 500;
      font-size: 14px;
      color: #333;
      cursor: pointer;
    }
  }

  .tv-select_collapsable-options {
    padding: 10px 0;

    &.close {
      display: none;
    }
  }

  .tv-select_select-all-btn {
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
    margin-bottom: 10px;
    background-color: #e7f1ff;
    padding: 6px 12px;
    border: 1px solid #007bff;
    border-radius: 5px;
  }

  .checkbox-group-category-container-row-block {
    margin-bottom: 10px;
    border-radius: 6px;
    padding: 10px;
    background-color: #f9fafb;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 5px 0;
      font-size: 14px;
      color: #555;
    }

    .tv-select_select-all-link {
      font-size: 12px;
      color: #007bff;
      cursor: pointer;
      margin-top: 5px;
      display: block;
    }
  }

  .item-collapse-entities {
    display: flex;
    flex-direction: column;
    padding: 1px 0;

    input[type='checkbox'] {
      margin-right: 10px;
    }

    label {
      cursor: pointer;
      color: #333;
    }

    .item-collapse-entities_flags {
      display: flex;
      align-items: center;

      .new {
        font-size: 12px;
        color: #28a745;
        margin-right: 10px;
      }

      .upgrade {
        font-size: 12px;
        color: #ff9800;
        cursor: pointer;
      }
    }
  }

  .tv-select_select-all-radio-group {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 20px;

    label {
      cursor: pointer;
      font-weight: 400;
      color: #333;
      display: flex;
      align-items: center;
    }

    input[type='radio'] {
      margin-right: 5px;
      cursor: pointer;
    }
  }

  .tv-select_counter-entity {
    font-size: 12px;
    color: #666;
    margin-top: 3px;
  }
}

.tv-select_radio-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  label {
    margin-left: 5px;
    margin-right: 15px;
    font-size: 14px;
    color: #848fac;
  }

  input[type='radio'] {
    accent-color: #3483fa;
  }
}

.tv-select_search-container {
  position: relative;
  margin-bottom: 15px;

  .search-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    pointer-events: none;
  }

  .clear-search {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer;
    color: #696969;
  }

  .tv-select_search-input {
    width: 100%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #d1d5db;
    font-size: 14px;
    box-sizing: border-box;
  }

  .tv-select_search-input::placeholder {
    color: #aaa;
  }

  .button-clean-filters {
    position: 'absolute';
    right: '10px';
    top: '50%';
    transform: 'translateY(-50%)';
    background: 'transparent';
    border: 'none';
    cursor: 'pointer';
  }
}

.counter {
  background-color: #dbe8ff;
  color: #0069f5;
  padding: 0.1rem 0.37rem;
  border-radius: 9999px;
  font-size: 0.575rem;
  font-weight: 600;
}

.tv-select_collapsable {
  background-color: #fff;
  border-radius: 10px;
  padding: 15px;

  .checkbox-group-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input[type='checkbox'] {
      margin-right: 10px;
      cursor: pointer;
    }

    .checkbox-label {
      flex-grow: 1;
      font-size: 14px;
      color: #333;
      cursor: pointer;
    }

    .chekbox-title {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 16px;
    }

    .counter {
      background-color: #dbe8ff;
      color: #0069f5;
      padding: 0.25rem 0.75rem;
      border-radius: 9999px;
      font-size: 0.875rem;
      font-weight: 600;
    }

    .checkbox-group-counter {
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .item-collapse-entities_flags {
    display: flex;
    align-items: center;

    .new {
      font-size: 12px;
      color: #28a745;
      margin-right: 10px;
    }

    .upgrade {
      font-size: 12px;
      color: #ff9800;
      cursor: pointer;
    }
  }

  .tv-select_select-all-btn {
    font-size: 14px;
    color: #007bff;
    cursor: pointer;
    margin-bottom: 10px;
    background-color: #e7f1ff;
    padding: 6px 12px;
    border: 1px solid #007bff;
    border-radius: 5px;
  }

  .checkbox-group-category-container-row-block {
    margin-bottom: 10px;
    border-radius: 6px;
    padding: 10px;
    background-color: #f9fafb;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding: 5px 0;
      font-size: 14px;
      color: #555;
    }
  }

  .tv-select_search-container {
    margin-bottom: 15px;

    .tv-select_search-input {
      width: 100%;
      padding: 8px;
      border-radius: 5px;
      border: 1px solid #d1d5db;
      font-size: 14px;
      outline: none;
    }
  }
}
.item-collapse-entities {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.item-label {
  flex-grow: 1;
}

.label-novo {
  background-color: #cce5ff;
  color: #004085;
  padding: 2px 6px;
  border-radius: 3px;
  margin-left: 10px;
}

.label-upgrade {
  background-color: #ffeeba;
  color: #856404;
  padding: 2px 6px;
  border-radius: 3px;
  margin-left: 10px;
}

.show-more-btn {
  color: #dc6803;
  font-weight: 600;
  font-size: 12px;
  padding: 0px;
}

.title-class {
  color: #101828;
  font-weight: 500;
  font-size: 14px;
}

.additional-resources {
  display: flex;
  flex-direction: column;

  &_title {
    color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin: 20px 0;
  }
}

.btn-additional-resources {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 10px 0;

  button {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: transparent;
    gap: 15px;
    color: #fff;

    label {
      cursor: pointer;
    }
  }
}

@import '../../../vars.module.scss';

.txt-green {
  color: darken($color: $green, $amount: 10);
  background-color: transparent;
}
.txt-red {
  color: darken($color: $pastelred, $amount: 10);
  background-color: transparent;
}
.txt-yellow {
  color: darken($color: $turivius-hot, $amount: 10);
  background-color: transparent;
}

.premium-filter-identifier-animated-data-availability {
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  display: flex;
  padding: 1px 1px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 5px 0px;

  background: linear-gradient(
    60deg,
    $secondary,
    $secondary-light,
    $secondary-dark,
    $turivius,
    $turivius-light,
    $turivius-dark
  );

  background-size: 300% 300%;
  animation: bd 3s ease alternate infinite;

  @keyframes bd {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }

  .data-availability-button-premium {
    width: fit-content;
    height: auto;
    cursor: pointer;
    display: flex;
    padding: 10px;
    border-radius: 5px;
    background: $body-color;
    position: relative;

    img {
      width: 18px;
      height: 18px;
      margin-top: -10px;
      margin-left: -5px;
      margin-right: 5px;
    }
    p {
      font-size: 0.7rem;
      margin: -6px 0px -6px 0px;
    }
  }
}

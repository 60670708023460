@import '../../pages/Profiles.scss';
@import '../../../../../vars.module.scss';

.profile-item {
  .personal-data {
    @include profile-sections;

    .title {
      padding-bottom: 8px;
      font-size: 21px;
      font-weight: 500 !important;
      color: $black-deep;
    }

    .button-group {
      width: 100%;
      margin-top: 24px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      button {
        &:first-child {
          margin-right: 16px;
          background-color: transparent;
          border: 1px solid $secondary-dark;
          color: $secondary-dark;
        }

        &:last-child {
          background-color: $secondary-dark;
          border: 0.5px solid $secondary-dark;
        }
      }
    }
  }

  .rs-form-control-label {
    font-size: 16px;
  }
}

.tv-dropdown {
  white-space: nowrap;
  @include typography-placeholder_preset;
  display: flex;
  align-items: center;
  border-left: 1px solid $gray200 !important;

  button {
    background-color: transparent !important;
    border: none !important;
  }
}

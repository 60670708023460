.articles {
  display: flex;
  flex-direction: column;

  &_content {
    display: flex;
    align-items: center;
    padding-top: 16px;

    &:first-child {
      padding-top: 0;
    }

    p {
      @include typography-body5_preset;
    }
  }

  &_info {
    display: flex;
    align-items: center;
    padding: 8px 0;
    border-bottom: 1px solid $gray200;
    &:last-child {
      border-bottom: none;
    }

    span {
      margin-left: 8px;
      color: $gray400;
      @include typography-body5_preset;
    }

    em {
      color: $blue600;
    }
  }
}

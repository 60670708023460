.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-filter {
  background-color: #fff;
  border-radius: 8px;
  width: 480px;
  padding: 35px 33px 44px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 11000;

  .modal-header-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .close-button {
    background: none;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
  }

  .filter-name-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border: 1px solid #e4e7ec !important;

    input {
      flex: 1;
      border: 1px solid #ccc;
      border-radius: 8px;
      padding: 10px;
      font-size: 0.9rem;
      font-family: Poppins;
    }

    .create-filter-btn {
      margin-left: 10px;
      background-color: transparent;
      color: #0069f5;

      border-radius: 8px;
    }
  }

  .saved-filters {
    margin-bottom: 20px;

    max-height: 200px; 
    overflow-y: auto; 

    p {
      font-size: 0.875rem;
      color: #000000;
      font-weight: 500;
      margin-bottom: 10px;
      font-family: Poppins;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        align-items: center;

        h2 {
          font-size: 0.75rem;
          color: #101828;
          font-family: Poppins;
        }

        span {
          color: #d0d5dd;
          font-size: 0.625rem;
          font-family: Poppins;
        }

        .choose-button,
        .selected-button {
          background: none;
          border: none;
          cursor: pointer;
          font-size: 0.9rem;

          &.choose-button {
            color: #f56c6c;
          }

          &.selected-button {
            background-color: #3ac57f;
            color: #fff;
            border-radius: 8px;
            padding: 5px 10px;
          }
        }

        &.selected {
          background-color: #e8f6ef;
          border-radius: 8px;
        }
      }
    }
  }

  .modal-footer {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 0.625rem;
  }

  .button-save-filter,
  .button-apply-filter {
    background-color: #053ac8;
    color: #fff;

    padding: 10px 26px;
    border-radius: 4px;
  }

  .cancel-button {
    background-color: #fff;
    color: #475467;
    border: 1px solid #e4e7ec;
    width: 30%;
    padding: 10px;
    border-radius: 4px;
  }

  .filter-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .filter-details {
      display: flex;
      align-items: center;
    }
  }

  .filter-actions span {
    background-color: #fef0c7;
    border-radius: 999px;
    padding: 0px 6px;
    color: #f79009 !important;
  }

  .trash-icon {
    background-color: #fee4e2;
    border-radius: 50%;
    padding: 4px;
    z-index: -10;

    color: #f79009 !important;
  }
}

.custom-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.custom-modal-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 12px;
}

.custom-modal-footer {
  display: flex;
  justify-content: left;
  padding-left: 47px;
  gap: 5px;
}

.modal-button {
  padding: 4px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.2s ease-in-out;
}

.modal-button.primary {
  background-color: #053ac8;
  color: white;
}

.modal-button.subtle {
  color: #333;
  border: 1px solid #e4e7ec;
}

.filter-actions {
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  transition: background-color 0.3s;
}

.filter-actions.selected span {
  background-color: #12b76a;
  color: #ffffff !important;
}

.active-filter {
  color: #3498db !important;
  font-weight: 600;
}

.custom-toaster {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #e6e8ec;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 16px;
  max-width: 405px;
  width: auto;
  border-bottom: 5px solid #12b76a;
}

.toaster-icon {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.custom-toaster span {
  flex-grow: 1;
  font-size: 14px;
  color: #3e3e3e;
}

.toaster-close-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 8px;
}

.close-submodal {
  background-color: transparent !important;
}

.button-close-submodal {
  display: flex;
  justify-content: end;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .head {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 85%;
    padding: 0;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    p {
      font-weight: 700;
      font-size: 1.2rem;
      line-height: 1.4rem;
    }

    .indicator {
      margin-right: 15px;
    }
  }

  .actions {
    width: 15%;
  }
}

.btnAction {
  border: 1px solid #001645;
  background-color: #ffffff;
  color: #001645;
  border-radius: 2px;
  display: inline-block;
  letter-spacing: 0.5px;
  font-size: 13px;
  text-align: center;
  vertical-align: middle;
  height: 35.4px;
  padding: 0px 16px;

  svg {
    display: block;
    float: left;
    height: 1.5em;
  }
}

.btnAction:hover {
  background-color: #001645;
  color: #ffffff;
}

.publishedContainer {
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  margin: 0px 20px;
  border-bottom: 1px solid #001645;

  p {
    color: #666666;
    width: 50%;
    height: 20px;
    margin: 0;
    padding: 0;
  }

  p:hover {
    font-weight: bold;
  }
}

.visualizeContainer {
  display: flex;
  flex-direction: row;
  padding: 5px 0px;
  margin: 0px 20px;
  border-bottom: 1px solid #001645;
  align-items: center;
  font-size: 11.6px;

  p {
    cursor: pointer;
    color: #4965a0;
    text-decoration: underline;
    font-size: 11.6px;
  }
}

.ementaContainer {
  margin: 0px 20px;
  align-items: center;

  .ementaTitle {
    color: #ffffff;
    background-color: #4965a0;
    font-size: 11.6px;
    padding: 2px 0px 0px 4px;
    font-weight: bold;
  }

  .ementaText {
    font-size: 1rem;
    overflow-y: hidden;
    text-align: justify;
  }
}

.decisionContainer {
  margin: 0px 20px;

  .decisionTitle {
    color: #ffffff;
    background-color: #4965a0;
    font-size: 11.6px;
    padding: 2px 0px 0px 4px;
    font-weight: bold;
  }

  .decisionText {
    font-size: 1rem;
    overflow-y: hidden;
    text-align: justify;
  }
}

.actionBottom {
  margin: 0px 20px;
  display: flex;
  justify-content: center;
  border-top: 1px solid #cecece;
  padding: 5px 0px;

  .btnActionSave {
    height: 36px;
    width: 48%;
    margin: 3px;
    border-radius: 2px;
    letter-spacing: 0.5px;
    background-color: #15c16b;
    color: #ffffff;
    font-weight: bold;
    font-size: 11.6px;
  }
  .btnActionStatic {
    height: 36px;
    width: 48%;
    margin: 3px;
    border-radius: 2px;
    letter-spacing: 0.5px;
    font-size: 11.6px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    svg {
      display: block;
      float: left;
      height: 1.5em;
    }
  }

  .btnActionSave:hover {
    background-color: #ffffff;
    border: 1px solid #15c16b;
    color: #15c16b;
  }
}

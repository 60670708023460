.rs-anim-fade.rs-anim-in {
  z-index: 80;
}
.contente-sidebar.close ~ div {
  @media (max-width: 1360px) {
    .resultpage {
      overflow: auto;
      width: 100%;
      margin: 0 2%;
    }

    .filter-search {
      overflow: auto;
      width: 92%;
      margin: 0 2%;
    }

    .resultpage-container_content {
      width: 96%;
    }
  }
}

.container-search-filter {
  flex-direction: row;
  display: flex;
  @media (max-width: 1279px) {
    flex-direction: column;
  }
}

.resultpage .filter-search {
  z-index: 80;
  overflow: hidden;
  width: 435px;
  height: 0px;
  margin-left: 40px;
  
  @media (max-width: 1279px) {
    height: 100%;
    overflow: auto;
    width: 82%;
    margin: 0 2%;
  }
}

// EXPLANATION: margem entre resultado da busca e texto + ordenar
.result-quantities-ordering{ 
  margin-left: 80px;
  margin-top: 25px;
  display: flex;
  justify-content: space-between; /* Adiciona espaço máximo entre os elementos */
  align-items: center; /* Centraliza os itens verticalmente */
  gap: 15px; /* Espaço entre os itens */
  
}

.horizontal-line {
  flex-grow: 1; /* Faz a linha horizontal ocupar o espaço disponível */
  border: none; /* Remove o estilo padrão de <hr> */
  height: 0.5px; /* Define a espessura da linha */
  background-color: #d0d5dd; /* Cor da linha */
  margin: 0 15px; /* Espaço horizontal ao redor da linha */
}

.resultpage-container_content {
  
  width: 100%;
  margin-top: 105px;

  .resultpage-container_content_span {
    font-size: 16px;
    color: #475467;

    b {
      color: #053AC8;
    }
  }

}

.resultpage-content_results {
  justify-content: center;
  margin-left: 32px;
  @media (max-width: 1360px) {
    margin-left: -50px;
  }
}

.jurimetrics-card{
  width: 92%;
  margin-top: 30px;
  margin-left: 30px;
  margin-right: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent !important;
}
.tv-filters {
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  width: 100%;
  &_list {
    position: fixed;
    top: 24px;

    border-radius: 0;
    max-width: 434px;
    overflow-y: auto;
    height: calc(100vh - 50px);

    h6 {
      @include typography-h8_preset;
    }
  }

  &_content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &_title {
    position: sticky;
    top: 0px;
    width: 100%;
    height: 55px;
    margin-bottom: 0;
    background-color: #ffffff;
    border-top: 1px solid rgba(170, 170, 170, 0.3);
    z-index: 7;
    border: none;
    font-size: 20px;
    padding: 14px 24px;
  }

  &_footer {
    text-align: center;
    position: sticky;
    bottom: 0px;
    width: 100%;
    height: 55px;
    margin: 0px;
    margin-bottom: 0;
    background-color: #ffffff;
    z-index: 7;
    border-radius: 10px;
  }

  @media (max-width: 1279px) {
    &_list {
      position: relative;
      max-width: 100%;
    }
  }
}

.tv-filter {
  &_group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.filter-dash {
  max-width: 480px;
  height: fit-content !important;
  max-height: 80vh;
  padding-bottom: 16px;

  overflow-y: scroll;
}

.filter-dash-loader {
  max-width: 480px;
  height: fit-content !important;

  padding-bottom: 16px;

  overflow-y: scroll;

  display: flex;
  flex-direction: column;

  div {
    &:last-child {
      align-self: flex-end;
    }
  }
}

.filter-dash_footer {
  bottom: -16px;
}

.float-button {
  position: fixed;
  float: right;
  bottom: 25px;
  right: 85px;
  z-index: 1049;

  .success-checklist {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #053ac8;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
  }

  .badge-unread {
    position: relative;

    span {
      position: absolute;
      left: 9px;
      bottom: -7px;
      width: 22px;
      height: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FEF0C7;
      border-radius: 50px;
      padding: 4px 2px 0px 2px;
      color: #F79009;
      font-size: 8px !important;
      font-weight: 600;
      min-width: 20px;
      right: -10px;
      top: -10px;
    }
  }
}

#success-checklist-popover {
  width: 450px;
  box-shadow:
    0px 1px 3px 0px rgba(0, 0, 0, 0.06),
    0px 1px 2px 0px rgba(0, 0, 0, 0.04) !important;

  .success-checklist-container {
    padding: 15px 15px;

    &_header {
      display: flex;
      flex-direction: column;
      gap: 15px;
      border-bottom: 1px solid #e4e7ec;
      padding-bottom: 25px;
      margin-bottom: 15px;

      &_progress {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          color: #475467;
          font-size: 14px;
          font-weight: 600;
        }
      }

      h6 {
        color: #053ac8;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
      }

      p {
        color: #475467;
        font-size: 14px !important;
        font-weight: 400;
      }
    }

    &_body {
      height: 400px;
      overflow-y: scroll;

      &_panel {
        p {
          color: #667085;
          font-size: 14px !important;
          font-weight: 400;
          line-height: 16px;
          padding-left: 28px;
          max-width: 350px;
          margin-bottom: 15px;

          a {
            cursor: pointer;
          }
        }

        span {
          color: #053ac8;
          font-size: 12px !important;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          margin-left: 28px;
          background-color: transparent;
          padding: 0;
        }

        &.viewTour {
          .rs-panel-header {
            padding-bottom: 0 !important;
          }
        }

        .rs-panel-header {
          padding: 20px 0;
        }
      }
    }
  }
}

.checklistHeader {
  display: flex;
  gap: 10px;
  align-items: flex-start;

  label {
    cursor: pointer;
    color: #0069f5;
    font-size: 14px;
    font-weight: 400;
    max-width: 300px;
  }
}

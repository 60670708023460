.menu-user {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 10px 6px 0;

  &_user-image {
    padding-left: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
  

    label {
      cursor: pointer;
      color: #1D2939;
      font-size: 12px;
      font-weight: 500;
      margin:0px 4px 0px 7px;
    }
  }
}

.option-menu-default {
  cursor: pointer;
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 5px;
  border-radius: 4px;
  padding: 10px 0;

  label {
    cursor: pointer;
    color: #475467;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.menu-profile-progress {
  cursor: pointer;
  border-top: 1px solid #e4e7ec;
  border-bottom: 1px solid #e4e7ec;
  padding: 18px 0;
  margin: 18px 0;
  display: flex;
  flex-direction: column;
  justify-content: left;

  a {
    font-size: 12px;
    color: rgba(0, 105, 245, 1);
    margin-top: -10px !important;
  }

  label {
    color: #344054;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 8px;
  }

  &_info {
    display: flex;
    justify-content: space-between;

    span {
      color: #344054;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .rs-progress-line {
    padding: 8px 0;
    padding-bottom: 2px;
  }
}

.knowledge-hub-button {
  background-color: transparent;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  color: #010c28;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}

.access-validity {
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    cursor: pointer;
    background-color: #fef0c7;
    border-radius: 50px;
    padding: 3px 10px;
    color: #f79009;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

@import '../../../vars.module.scss';

.collections {
  padding: 20px;
  width: 100%;

  .clickable {
    cursor: pointer;
  }

  .container-header {
    margin-bottom: 13px;

    h4 {
      color: $turivius-dark;
    }
  }

  .header {
    margin: 1.52rem 0 0.912rem 1rem;
    color: $turivius-dark;
  }

  .collections-container {
    max-width: 1800px;
    margin: auto;
  }

  .collections-paginator {
    margin-bottom: 10px;
  }

  .collection-folder {
    height: 150px;
    background-color: #fff;
    margin: 10px auto;

    .collection-folder-initial-avatar {
      width: 40px;
      height: 40px;
      background-color: $turivius-light;
      border-radius: 50%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }

    .collection-folder-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;

      .collection-folder-header {
        display: flex;

        align-items: center;
        padding: 10px;

        .title-folder {
          width: 70%;
          height: 50px;
          display: flex;
          justify-content: left;
          align-items: center;
          padding: 0px 10px;

          span {
            font-weight: bold;
            overflow: hidden;

            text-overflow: ellipsis;

            display: -webkit-box;
            -webkit-line-clamp: 3;

            -webkit-box-orient: vertical;
          }
        }

        .menu-folder {
          width: 10%;
        }
      }

      .collection-folder-body {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 10px;
      }

      .collection-folder-footer {
        background-color: #ececec;
        padding: 10px;
      }
    }

    .card-sidebar {
      background-color: $turivius !important;
      width: 25%;
      padding: 6px;

      .collection-container {
        height: 150px;
        text-align: center;

        .owner-name {
          text-align: center;
        }

        .info-icon {
          color: #fff;
          width: 100%;
          display: inline-flex;

          .circle-img {
            border-radius: 50% !important;
            width: 40px;
            margin: auto;
          }

          svg {
            font-size: 22px;
            width: 40%;
            margin-left: 6%;
            margin-right: 10px;
          }
        }
      }
    }

    .card-folder-information {
      width: 75% !important;
      height: 150px;
      flex: none !important;

      .card-content {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 10px;
        padding-bottom: 5px;
        margin-left: 8px;
        margin-right: 8px;

        .card-title {
          max-width: 100%;
          width: 100%;
          font-size: 16px;
          font-weight: 400;
          display: inline-flex;

          .truncate {
            width: 88%;
            cursor: pointer !important;
          }
        }

        p {
          text-align: justify;
          height: 65px !important;
          color: lighten($color: #000000, $amount: 30%);

          &.centered-message {
            text-align: center;
            height: unset !important;
          }

          overflow-y: hidden;
        }
      }
    }

    .card-action {
      display: inline-flex;
      align-items: center;
      padding: 0px 18px 0px 2px;
      color: $turivius;
      position: absolute;
      bottom: 15px;

      .last-update div {
        white-space: nowrap;
        font-size: 12px;
        width: 90%;
        line-height: 24px;
      }

      svg {
        width: 10%;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

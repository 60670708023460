.font {
  &-regular {
    font-weight: 400;
  }

  &-medium {
    font-weight: 500;
  }

  &-semibold {
    font-weight: 600;
  }

  &-bold {
    font-weight: 700;
  }

  &-black {
    font-weight: 900;
  }
}

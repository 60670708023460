@import '../../../vars.module.scss';

.create-monitoring-modal {
  &_footer {
    display: flex;
    justify-content: flex-end;
  }

  &_btn-footer-back,
  &_btn-footer-next,
  &_btn-footer-finish {
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 15px;
    font-weight: 700;
    color: $turivius-dark;

    .btn-icon {
      font-size: 20px;
      color: $secondary-lighter;
    }
  }

  &_btn-footer-next {
    padding-right: 30px;
  }

  &_btn-footer-back {
    padding-left: 30px;
  }

  &_btn-footer-finish {
    background-color: $turivius-dark;
    color: $secondary-lighter;

    &:focus,
    &:hover {
      background-color: $turivius-dark;
      color: $secondary-lighter;
    }
  }
}

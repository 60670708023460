.date-filter-box {
  border-radius: 8px;
  margin-bottom: 16px;
}

.date-filter-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.date-filter-input-error {
  border-color: #ff4d4f !important;
}

.date-filter-wrapper {
  margin-bottom: 16px;
}

.date-filter-input-group {
  display: flex;
  align-items: center;
}

.date-filter-addon {
  background-color: #eaecf0!important;
  color: #667085;
  height: auto;
  padding: 10px 16px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-weight: 600;
}

.date-filter-masked-input {
  flex: 1;
  padding: 6px 12px;
  border-radius: 4px;
  border: 1px solid #eaecf0!important;
  color: #667085;
}

.date-filter-masked-input:focus {
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.date-filter-initial,
.date-filter-final {
  max-width: 110px;
  text-align: center;
}

@import '../../vars.module.scss';

.card {
  background: #ffffff;
  box-shadow:
    0px 1px 2px rgba(0, 0, 0, 0.04),
    0px 1px 3px rgba(0, 0, 0, 0.06);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  min-height: 220px;

  .os {
    color: #101828;
    font-size: 20px;
    font-weight: 600;
  }

  .date {
    color: #98a2b3;
    font-weight: 400;
    font-size: 14px;
  }
  .disconnect {
    margin-top: 20px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #475467;
    background-color: white;
    border: #475467 1px solid;
    border-radius: 4px;

    i {
      margin-left: 5px;
    }
  }

  .red {
    color: #f97066;
  }
  .green {
    color: #32d583;
  }
}
.blocked {
  padding-bottom: 70px;
}

@import '../../../../../../vars.module.scss';

.p-inf-text-result-card{
    color: #475467;
}

.wrapper {
  display: flex;
  padding-top: 16px;
  flex-direction: column;
  border-top: 1px solid #e4e7ec;

  .header {
    gap: 7px;
    display: flex;
    margin-bottom: 15px;
    align-items: center;
    justify-content: flex-start;

    .title {
      color: $turivius-light;
      font-family: 'Poppins', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal !important;
      cursor: pointer;
    }

    .badge {
      gap: 4px;
      height: 24px;
      flex-shrink: 0;
      padding: 2px 8px;
      align-items: center;
      display: inline-flex;
      border-radius: 999px;
      justify-content: center;

      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      font-style: normal;
      font-family: 'Poppins', sans-serif;

      background: #fef0c7;
      /* Warning/Yellow-100 */
      color: #f79009;
      /* Warning/Yellow-500 */
    }

    .pagination {
      gap: 8px;
      display: flex;
      align-items: center;

      div {
        gap: 4px;
        display: flex;
        align-items: center;
      }

      span {
        color: #667085;
        /* Gray/Gray-500 */
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }

      button {
        padding: 0;
        outline: none;
        background: white;

        gap: 8px;
        padding: 7px;
        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 8px;
        border: 1px solid var(--Gray-Gray-100, #f2f4f7);
        background: var(--Black-Black__0, #fff);

        &:disabled {
          background: #f6f6f6;
          /* Black/Black__10 */
        }

        svg>path {
          stroke-width: 2.5px;
        }
      }
    }
  }
}

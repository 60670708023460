@mixin profile-sections {
  margin-top: 24px;
  padding: 24px;
  background: #f5f5f5;
  border: 1px solid #dadada;
  border-radius: 5px;
}

.rs-content {
  .profile-item {
    margin: 16px 16px 32px 16px;

    position: relative;
  }
}

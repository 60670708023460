.tv-tag {
  @include typography-body5_preset;

  &-sm {
    border-radius: 999px;
    padding: 0px 6px;
  }
  &-md {
    border-radius: 16px;
    padding: 2px 10px;
  }

  &-default {
    &_primary {
      background-color: $blue100;
      color: $blue600;
    }
    &_gray {
      background-color: $gray200;
      color: $gray600;
    }
    &_success {
      background-color: $success100;
      color: $success500;
    }
    &_warning {
      background-color: $warning100;
      color: $warning500;
    }
    &_danger {
      background-color: $danger100;
      color: $danger500;
    }

    &_orange {
      background-color: $warning100;
      color: $warning600;
    }
    &_violet {
      background-color: $purple100;
      color: $purple600;
    }
    &_azure {
      background-color: $information100;
      color: $information500;
    }
  }

  &-plain {
    &_primary {
      background-color: $blue700;
      color: $white;
    }
    &_gray {
      background-color: $gray500;
      color: $white;
    }
    &_success {
      background-color: $success500;
      color: $white;
    }
    &_warning {
      background-color: $warning500;
      color: $white;
    }
    &_danger {
      background-color: $danger500;
      color: $white;
    }

    &_orange {
      background-color: $warning600;
      color: $white;
    }
    &_violet {
      background-color: $purple600;
      color: $white;
    }
    &_azure {
      background-color: $information600;
      color: $white;
    }
  }

  &_favorability {
    display: flex;
    padding: 4px 16px 4px 4px;
    gap: 12px;
    border-radius: 999px;
    width: fit-content;
    align-items: center;
    border: 1px solid $gray200;
  }
}

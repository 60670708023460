#radioSetup {
  border: none;

  .rs-radio-group-picker {
    border: none;
  }

  .rs-radio-inline {
    border: 1px solid #dedede;
    padding: 8px 16px 8px 16px;
    border-radius: 8px;
    font-size: 16px;
    color: #475467;
  }

  .rs-radio-checker>label {
    color: #475467 !important;
  }

  .rs-radio-control {
    display: none;
  }

  .rs-radio-checked {
    background-color: #eff8ff;
    color: #3069fe !important;
    border-color: #3069fe;

    .rs-radio-checker>label {
      color: #3069fe !important;
      border: none;
    }
  }

  .rs-radio-control {
    display: none;
  }

  &.rs-radio-group-picker .rs-radio-checked .rs-radio-checker {
    background-color: #eff8ff;
    color: #3069fe !important;
    border: none !important;
  }
}

#checkBoxSetup {
  &.rs-checkbox-group {
    display: block;
  }

  &.rs-checkbox-group>.rs-checkbox {
    margin-left: 0px;
    margin-right: 8px;
  }

  .rs-checkbox-inline {
    border: 1px solid #dedede;
    padding: 8px 11px 7px 11px;
    border-radius: 8px;
    font-size: 16px;
    color: #475467;
    display: flex;
    float: left;
    margin-bottom: 10px;

    label {
      font-weight: 400 !important;
      color: #475467;
    }
  }

  .rs-checkbox-checker {
    padding: 8px 16px 8px 16px;
  }

  .rs-checkbox-wrapper {
    display: none;
  }

  .rs-checkbox-checked {
    background-color: #eff8ff;
    color: #3069fe;
    border-color: #3069fe;

    .rs-checkbox-checker>label {
      color: #3069fe !important;
    }
  }
}

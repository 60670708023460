@import '../../../../../vars.module.scss';

.BottomSheetNotification {
  z-index: 999;
  position: fixed;
  bottom: 0px;
  right: 0px;
  border-top: 1px solid #e5e5e5;
  background: #fff;
  box-shadow: 0px -10px 10px rgba(200, 200, 200, 0.08);
  background-color: transparentize($color: #fff, $amount: 0.05);
  cursor: pointer;

  @media only screen and (min-width: 1270px) {
    left: calc(50% - 500px);
    max-width: 1000px;
    min-width: 300px;
  }

  @media only screen and (max-width: 1270px) {
    left: 0px;
    max-width: 100%;
    min-width: 300px;
  }

  &.animated {
    animation-duration: 2s;
    animation-name: BottomSheetNotification-slidein;
  }

  .BottomSheetNotification-Inner {
    width: 98%;
    max-width: 1000px;
    margin: 20px auto;
    text-align: center;
  }

  .BottomSheetNotification-Close {
    position: absolute;
    top: 0px;
    right: 5px;
    background: transparent;
    outline: none;
    display: inline-block;
    zoom: 1;
    line-height: normal;
    white-space: nowrap;
    vertical-align: baseline;
    text-align: center;
    cursor: pointer;
    user-select: none;
    font-family: inherit;
    font-size: 100%;
    padding: 0.5em 1em;
    text-decoration: none;
    border: 0;
    opacity: 0.7;
    font-size: 16px;
    color: $black-deep;

    &:hover {
      opacity: 1;
    }
  }

  .BottomSheetNotification-Inner {
    width: 98%;
    max-width: 1000px;
    margin: 20px auto;
    text-align: center;
  }
}

@keyframes BottomSheetNotification-slidein {
  from {
    bottom: -100%;
  }

  to {
    bottom: 0px;
  }
}

.tv-drawer-default {
  &_header {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e4e7ec;
    margin-bottom: 10px;
    padding-bottom: 50px;
    background-color: #053ac8;

    &_title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      h6 {
        color: #010c28;
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
      }

      &_button-clear {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        background-color: transparent;
        border-radius: 8px;
        padding: 5px 10px;
        cursor: pointer;

        label {
          cursor: pointer;
          color: #0069f5;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }

  .rs-drawer-content {
    background-color: #eff8ff !important;
    padding: 30px;

    .rs-drawer-header {
      padding: 0 0 30px 0;
    }

    .rs-drawer-body {
      padding: 0 10px;
      height: calc(100% - 120px);
    }
  }
}

.tv-drawer-primary {
  &_header {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid #e4e7ec;
    padding-bottom: 20px !important;

    &_title {
      display: flex;
      gap: 5px;
      color: #ffffff;
      font-size: 16px;
      font-weight: 600;
      line-height: 28px;
      margin-bottom: 30px;
      &.with-route {
        p {
          color: #84caff;
        }
      }
    }

    &_subtitle {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }

  &_body {
    padding-top: 20px;

    .rs-panel-header {
      padding: 0;

      .rs-icon {
        color: #fff !important;
        right: 22px !important;
        top: 18px !important;
      }
    }

    .knowledge-panel {
      margin-bottom: 15px;

      &_body {
        padding: 12px 17px 12px 51px;
        color: #fff;

        &.main {
          background-color: #0431a8;
        }

        &.item {
          padding: 12px 17px;
          background-color: #0443d1;
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        ul {
          list-style: unset;
        }
      }
    }
  }

  .rs-drawer-content {
    background-color: #053ac8!important;
    padding: 30px;

    .rs-drawer-header {
      padding: 0;
      border-bottom: none;
    }

    .rs-drawer-body {
      padding: 0 10px 0 0;
      height: calc(100% - 120px);
    }
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #0431a8;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #0069f5;
    border-radius: 10px;
  }
}

.panel-header {
  padding: 12px 17px;
  border-radius: 4px 4px 0 0;
  display: flex;
  gap: 5px;
  align-items: center;

  &.item {
    background-color: #0443d1;
  }

  &.main {
    background-color: #0431a8;
    gap: 10px;
    display: flex;
    align-items: flex-start;
  }

  &.button {
    background-color: #0431a8;
    gap: 10px;
    display: flex;
    align-items: flex-start;
    border-radius: 4px;
    margin-bottom: 15px;
    width: 100%;

    button,
    label {
      cursor: pointer;
    }
  }

  label {
    font-size: 14px;
    font-weight: 400;
    color: #fff;

    font-weight: 500;
  }
}

.timeline {
  display: grid;
  grid-template-columns: repeat(5, 1fr);

  width: 100%;

  &-item {
    display: flex;
    flex-direction: column;

    svg {
      margin: auto;
    }
    &_divisor {
      position: relative;
    }

    &_line {
      height: 1px;
      width: 100%;
      background-color: $gray200;
      position: absolute;
      top: 47%;
    }
    &_circle {
      width: 12px;
      height: 12px;
      background-color: $blue300;
      border-radius: 50%;
      margin: 12px auto;
      z-index: 100;
      position: inherit;

      &-active {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin: 12px auto;
        z-index: 100;
        position: inherit;
        background-color: $white;
        border: 3px solid $blue700;
      }
    }
    &_description {
      display: flex;
      flex-direction: column;
      text-align: center;
      a {
        font-size: $body-3;
        font-weight: 600;
        text-decoration: underline;
        color: $gray900;

        &:hover {
          color: $blue900;
        }
      }

      &-active {
        color: $blue700 !important;
      }
      span {
        font-size: $body-4;
        color: $gray400;
      }
    }
  }
}

.timeline-decision {
  width: 100%;

  &_content {
    padding: 24px 80px;
    background-color: $gray25;
    border-radius: 16px;
  }
}

.justify-content_between {
  justify-content: space-between !important;
}

.w {
  &-25 {
    width: 25%;
    min-width: fit-content;
  }
  &-50 {
    width: 50%;
  }
  &-75 {
    width: 75%;
  }
  &-100 {
    width: 100%;
  }
}

.use-case {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 520px;

  padding: 48px !important;

  &_header {
    color: $blue700;
    p {
      font-size: $h6-size !important;
    }
  }

  button {
    width: 100% !important;
  }
}

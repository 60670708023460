.resultcard-loader {
  width: 100%;
  &_header {
    display: grid;
    gap: 16px;
    grid-template-columns: auto 1fr 0.5fr;
  }

  &_content {
    .title-loader {
      max-width: 200px;
      width: 100% !important;
    }
  }
}

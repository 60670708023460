.container-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #eff8ff;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
}

.rs-drawer-content {
  background-color: #eff8ff;
}

.container-modal > span {
  color: #053ac8;
  font-weight: 700;
  font-size: 32px;
}

.container-modal > p {
  color: #475467;
  font-weight: 400;
  font-size: 16px;
}

.title-modal {
  display: flex;
  justify-content: center;
  margin-top: 45px;
  margin-bottom: 63px;
  gap: 6px;
  height: 28px;
  align-items: center;
}

.title-modal h1 {
  color: #053ac8;
  font-weight: 600;
  font-size: 26px;
}

.title-modal > span {
  background-color: #fdb022;
  color: #fff !important;
  font-weight: 600;
  font-size: 10px !important;
  padding: 0px 6px;
  border-radius: 10px;
  text-transform: capitalize;
}

.close-button p {
  font-size: 12px;
  color: #475467 !important;
  font-weight: 500;
}

.close-icon-modal {
  font-size: 12px;
  color: #475467 !important;
  font-weight: 500;
}

.close-button {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #d0d5dd;
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
  gap: 4px;
  padding: 8px 16px;
  border-radius: 4px;

  &:hover {
    opacity: 0.7;
  }
}

.button-reload {
  width: 70%;
  padding-left: 40px;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
  margin-top: 93px;
}

.button-reload img {
  width: 10.6px;
  height: 10.6px;
}

.search-modal {
  margin-top: 100px;
  margin-bottom: 100px;
  width: 90%;
}

.search-modal .input-container {
  background: #eff8ff;
  padding-bottom: 100px;
  position: fixed;
  width: 90%;
  bottom: 0;
  margin-bottom: -30px;
}

.search-modal input {
  width: 100%;
  padding: 26px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 4px;
  padding-bottom: 21px;
  padding-right: 100px;
  border: 1px solid #d0d5dd;
  transition: border-color 0.3s;
  box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.search-modal input:focus {
  border-color: #0d91fe;
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.search-modal .send-button {
  position: absolute;
  right: 5px;
  top: 15%;
  transform: translateY(-50%);
  height: 36px;
  padding: 0 10px;
  border: none;
  background-color: transparent;
  color: #98a2b3;
  cursor: pointer;
  border-radius: 4px;
}

.search-modal .send-button.active {
  color: #0d91fe;
}

.search-modal .send-button img {
  fill: #98a2b3;
}

.search-modal .send-button img.active {
  fill: #0d91fe;
}

.search-modal > span {
  color: #98a2b3;
  font-weight: 400;
  font-size: 12px;
}

.send-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.reload-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.container-history {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #eff8ff;
  height: 100%;
  max-height: 100vh;
  overflow-y: auto;
}

.modal-search-history {
  display: flex;
  width: 80%;
  justify-content: flex-start;
  text-align: center;
  align-items: center;
}

.title-modal-search-history > span {
  background-color: #fdb022;
  color: #fff !important;
  font-weight: 600;
  font-size: 10px !important;
  padding: 0px 6px;
  border-radius: 10px;
  text-transform: capitalize;
}

.title-modal-search-history {
  margin-top: 40px;
  display: flex;
  text-align: center;
  align-items: center;
  gap: 6px;
  width: 100%;
}

.close-button-search-history {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid hsl(217, 16%, 84%);
  right: 20px;
  top: 20px;
  gap: 4px;
  padding: 8px 16px;
  border-radius: 4px;
}

.close-button-search-history > p {
  font-size: 12px;
  color: #475467 !important;
  font-weight: 500;
}

.card-search-history {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  margin-bottom: 29px;
}

.card-user {
  background-color: #ffffff;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: auto;
  border-radius: 4px;
  padding: 25px 30px 30px 32px;
  margin-top: 28px;
  border: 1px solid #f2f4f7;
}

.card-result {
  background-color: #ffffff;
  width: 100%;
  height: auto;
  border-radius: 4px;
  padding: 25px 30px 30px 32px;
  margin-top: 28px;
  border: 1px solid #f2f4f7;
}

.content-card {
  display: flex;
  justify-content: space-between;
}

.title-modal-search-result {
  display: flex;
  text-align: center;
  align-items: center;
  gap: 6px;
}

.title-modal-search-result > span {
  background-color: #fdb022;
  color: #fff !important;
  font-weight: 600;
  font-size: 10px !important;
  padding: 0px 6px;
  border-radius: 10px;
  text-transform: capitalize;
}

.data-search {
  display: flex;
  align-items: center;
  gap: 3px;
}

.data-search span {
  color: #98a2b3;
  font-size: 10px;
}
.box-user {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.card-result > span {
  color: #98a2b3;
  font-size: 10px;
}

.data-search-results {
  display: flex;
  align-items: center;
  gap: 3px;
}

.data-search-results > span {
  color: #98a2b3;
  font-size: 10px;
}

.text-info > h3 {
  font-size: 16px;
  font-weight: 700;
  color: #475467;
  margin-top: 13px;
  margin-bottom: 15px;
}

.text-info > p {
  font-size: 16px;
  color: #475467;
}

.buttons {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e4e7ec;
  margin-top: 20px;
}

@media (max-width: 1080px) {
  .buttons {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
}

.copy-reload-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.info-text {
  padding-top: 15px;
}

.copy-reload-buttons > span {
  color: #98a2b3;
  margin-top: 17px;
}

.button-copy {
  display: flex;
  margin-top: 17px;
  align-items: center;
  gap: 3px;
  border: 1px solid #e4e7ec;
  font-size: 16px;
  padding: 5px;
  border-radius: 4px;
  color: #0069f5;

  &.copied {
    color: #12b76a;
  }
}

.reload-button-history {
  margin-top: 17px;
  border: 1px solid #e4e7ec;
  border-radius: 4px;
  padding: 8px;
}

.button-history {
  position: sticky;
  bottom: 15%;
  left: 50%;
  z-index: 10;

  cursor: pointer;

  &:hover {
    transform: scale(1.1);
    transition: transform 0.5s ease;
  }
}

.arrow-down-button {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
}

.button-like {
  border: 1px solid #e4e7ec;
  border-radius: 4px;
  padding: 8px;
  margin-top: 17px;
}

.button-dislike {
  border: 1px solid #e4e7ec;
  border-radius: 4px;
  padding: 8px;
  margin-top: 17px;
}

.button-reload-history {
  width: 57%;
  margin-top: 30px;
  margin-bottom: 13px;
}

.box-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}

.clear-chat-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #ffffff;
  padding: 12px 24px;
  border-radius: 4px;
  border: 1px solid #e4e7ec;
  font-size: 14px;
  color: #475467;
  transition: transform 0.5s ease;
}

.clear-chat-button:hover {
  transform: scale(1.05);
}

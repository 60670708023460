@import '../../../../vars.module.scss';

.admin-principal {
  padding: 16px 25px;
  background-color: $white;

  .admin-body {
    background-color: $white;
  }

  .admin-menu {
    margin: 140px 0 20px 0;
  }
}

@media screen and (max-width: 836px) {
  .admin-principal {
    .admin-menu {
      margin-top: 180px;
    }
  }
}

@import '../../../../vars.module.scss';

.container-page {
  width: 100%;
  height: 100%;
  min-height: 100vh;

  overflow: auto;
  background-color: #eff8ff;
}

.containers {
  margin: 0;
  margin-top: 20px;
  text-align: center;
  padding-bottom: 20px;

  .item {
    background-color: #fff;
    padding-top: 10px;
    border-radius: 4px;
    border: 10px solid #eff8ff;

    h5 {
      color: #000;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

.title-page {
  padding-top: 28px;

  .title-page-content {
    width: 100%;
    max-width: 1920px;

    h3 {
      color: #053ac8;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }

    p {
      color: #475467;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 28px;
      margin: 10px 0px 0px 0;
    }
  }
}

.home-cards {
  max-width: 1000px;
  width: 75%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  gap: 1rem;

  &_container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.home-box-content {
  display: flex;
  justify-content: center;
  gap: 2rem;
  height: 100%;
}

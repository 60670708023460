.box-content {
  border-left: 1px solid #eaecf0;
  margin-left: 19px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 14px;
  padding-bottom: 18px;
}

.title-filter {
  font-weight: 500;
  margin-bottom: 12px;
  color: #101828;
  font-size: 16px;
}
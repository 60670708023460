@import '../../../../../../../vars.module.scss';

.turivius-checkbox-group-filter {
  margin-bottom: 5px;
  padding-top: 10px !important;

  .MuiFormLabel-root {
    background-color: #fff;
    padding: 0px 4px;
    padding-top: 10px;
  }

  .checkbox-input-field {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    text-align: left;
    width: 100%;
    padding: 10px !important;
    height: 150px;
    width: 100%;
    overflow-y: auto;

    .checkbox-input-field-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .checkbox-group {
      display: flex;
      align-items: baseline;
      justify-content: flex-start;

      .checkbox-group-category-label {
        margin-right: 8px;
        font-size: 14px;
        text-transform: uppercase;
      }

      .checkbox-group-category-action {
        margin-right: 8px;
        background-color: #0d91fe;
        padding: 0px 5px;
        border-radius: 3px;
        color: #ffffff;
        font-size: 0.7rem;
        cursor: pointer;

        &:hover {
          background-color: #0069f5;
        }
      }
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #555;
    }

    position: inherit;

    &.error {
      border-color: #f44336 !important;
    }

    &:hover {
      border: 1px solid rgba(0, 0, 0, 0.87);
    }

    .MuiCircularProgress-root {
      margin: auto;
    }

    .row {
      margin: auto;
    }

    .col.s12 {
      margin-bottom: 0px !important;
    }

    .col.s12:nth-child(1) {
      margin-top: 0px !important;
    }

    .checkbox-group-category-label {
      text-transform: uppercase;
      color: darken($color: $gray, $amount: 20);
      display: inline-flex;
      align-items: center;
      margin-left: 5px;

      p {
        font-size: 0.8rem;
      }

      .checkbox-group-category-action {
        text-transform: lowercase;
        margin-left: 10px;
        margin-top: 0px;
        cursor: pointer;
        font-size: 0.7rem;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .checkbox-group-category-container {
      margin-bottom: 15px;
      max-width: 100%;

      &:nth-last-child(1) {
        border: none;
      }
    }

    .rs-checkbox-checker {
      padding-top: 10px;
      padding-bottom: 6px;
    }

    .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner:before,
    .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
      background-color: $secondary-dark;
    }

    .rs-checkbox:not(.rs-checkbox-disabled) .rs-checkbox-wrapper .rs-checkbox-inner:before,
    .rs-checkbox-indeterminate .rs-checkbox-wrapper .rs-checkbox-inner:before {
      border-color: $secondary-dark !important;
    }

    .rs-checkbox-disabled {
      label {
        color: var(--rs-text-disabled) !important;
      }
    }

    .checkbox-group-label-container {
      .first-line {
        display: flex;
        flex-direction: row;
      }

      .counter {
        margin: 0px;
        margin-top: 3px;
        font-size: 9px;
        line-height: 10px;
      }

      i,
      svg {
        font-size: 0.8rem;

        &.new {
          text-shadow: 0px 0px 1px #000;
          color: #fdfd88;
        }

        &.partial {
          color: $turivius-light;
        }
      }
    }
  }

  .MuiFormHelperText-root {
    &.error {
      color: #f44336 !important;
    }
  }

  .data-availability-container {
    display: flex;
    flex-direction: row;
  }

  .container-dropdown {
    .rs-dropdown {
      width: 100%;
    }

    button {
      width: 100%;
      background-color: $white;
      border: 1px solid #dddddd;
      text-align: left;
    }
  }
}

.default-operator-filter,
.synonyms-filter,
.inteiro-teor-filter,
.date-filter {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  width: 100%;

  .title-option {
    margin-bottom: 10px;
    color: $black;
    font-weight: 700;
  }

  .rs-radio-group-picker {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;

    .rs-radio-checked {
      background-color: $secondary-dark !important;
      border-radius: 3px !important;

      label {
        color: #ffffff !important;
      }
    }

    .rs-radio-inline {
      margin-left: 0;
      width: 100%;
      text-align: center;

      .rs-radio,
      .rs-radio-checker {
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        .rs-radio-control {
          display: none;
        }

        @media only screen and (max-width: 1400px) {

          label,
          span {
            font-size: 12px !important;
          }
        }

        label {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.rs-content {
  overflow: auto !important;
}

.box-filter_list,
.box-filter_list_reduce {
  // overflow: hidden; /* Evita conteúdo visível fora do contêiner */
  transition: all 1s ease-in-out; /* Controla a transição suave entre os estilos */
}

.box-filter_list {
  @media (max-width: 1360px) {
    height: auto;
    display: flex;
    justify-content: center;
  }
}
.box-filter_list .tv-filters_list {
  
  height: 81vh; // EXPLANATION: altura do filtro
  scroll-behavior: auto;
  overflow: auto;

  @media (min-width: 1360px) {
    width: 398px;
  }

  @media (max-width: 1279px) {
    height: auto;
    top: 0;
  }
}


// ----------------------------------------------------------------------------------------------------
// EXPLANATION: Essa aqui é a lógica que permite que o filtro vá para cima quando o bulk action é selecionado
// ----------------------------------------------------------------------------------------------------
.box-filter_list_reduce {
  @media (max-width: 1360px) {
    height: auto;
    display: flex;
    justify-content: center;
  }
}

// EXPLANATION: Bulk action
.box-filter_list_reduce .tv-filters_list {
  // max-height: calc(100vh - 160px); /* Ajuste dinâmico baseado no viewport, descontando 100px, por exemplo */
  height: 75vh; // EXPLANATION: altura do filtro
  // height: auto; /* Permitir crescimento até o limite */
  scroll-behavior: auto;
  overflow: auto;

  @media (min-width: 1360px) {
    width: 398px;
  }

  @media (max-width: 1279px) {
    height: auto;
    top: 0;
  }
}
// ----------------------------------------------------------------------------------------------------

.container-search {
  display: flex;
}
.icones {
  background: #fff;
  width: 13%;
  justify-content: center;
}

.resultpage {
  display: flex;
  flex-direction: column;
  .resultpage-header-search {
    position: relative;
    z-index: 7;
  }
  .resultpage-header-search {
    z-index: 81;
  }

  &-header {
    background-color: $white;
    width: 100%;

    &_sub {
      display: flex;
      align-items: center;
      position: relative;
      margin: 100px 40px 0px 40px;

      .monitoring-term {
        width: 50%;
        justify-self: flex-start !important;
        display: flex;
        gap: 8px;
        margin-top: 20px;
      }
    }
  }

  &-container {
    width: 100%;
    transition: width 0.3s ease;

    display: flex;
    padding: 32px 0 16px 32px;

    &_content {
      padding: 24px;
      margin-left: 26px;

      @media (max-width: 1350px) {
        padding: 24px 0;
      }

      &-sub {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 32px;
      }

      &-span {
        @include typography-body1_preset;
        color: $gray600;

        b {
          color: $blue700;
        }
      }
    }

    @media (max-width: 1200px) {
      flex-direction: column;
      padding-right: 26px;
    }

    @media (min-width: 1900px) {
      &_content {
        width: 100%;
      }
    }
  }
}


//----------------------------------------------------------
//TODO: Essa parte controla o escurecimento na home
//----------------------------------------------------------

.focused {
  position: relative;
  z-index: 1;
}

.focused-container {
  position: relative;
  z-index: 50;
  transition: all 0.5s ease-in-out
}

.focused-container::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.65);
  z-index: 1;
}
//----------------------------------------------------------
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 70%;
  gap: 18px;
}

.card-suggestion {
  background-color: #ffffff;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 25px;
  width: 385px;
  height: 70px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  border: 1px solid transparent; 
  transition: border 0.3s ease; 
}

.card-suggestion:hover {
  border: 1px solid #0D91FE; 
}

.skeleton-card {
  background-color: #ffffff;
  display: flex;
  justify-content: left;
  align-items: center;
  padding: 25px;
  width: 385px;
  height: 70px;
  border-radius: 4px;
  font-size: 14px;
}

@import '../../../../../vars.module.scss';

.status-button {
  display: flex;
  align-items: center;

  &.ativo {
    background-color: $green;
  }

  &.inativo,
  &.bloqueado {
    background-color: $red;
  }

  .status {
    font-weight: bold;
    text-transform: capitalize;
    color: $white;
  }
}

@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.2.0/css/all.css';

.input-with-icon {
  display: flex;
  flex-direction: column;

  &_input-group {
    display: flex;
    padding: 8px 16px;
    border-radius: 8px;
    align-items: center;
    border: 1px solid $gray200;

    &_input {
      flex: 1;
      gap: 12px;
      display: flex;
      align-items: center;

      svg {
        path {
          stroke-width: 3px;
        }
      }
    }

    &_plus-pw {
      gap: 13px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        display: flex;
        align-items: center;
        background-color: transparent;
      }

      svg {
        path {
          stroke-width: 2px;
        }
      }

      &.forgot-pw {
        color: #0069f5;
      }
    }
  }

  &_message {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    height: 0;
    color: transparent;
    transition: 0.1s ease-in-out;

    &.error {
      height: auto;
      color: #f04438;
    }
  }

  input {
    flex: 1;
    border: none;

    &:focus-visible {
      outline: none;
    }
  }
}

.input-token {
  display: flex;
  justify-content: space-between;

  input {
    width: 14%;
    height: 70px;
    border: none;
    border-radius: 8px;
    text-align: center;
    background-color: $gray50;
    color: #000;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;

    &:focus-visible {
      background-color: #e7e5e5;
      outline: none;
    }
  }
}

.tv-select {
  &_collapsable {
    box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
    padding-bottom: 16px;
    margin-bottom: 16px;
    overflow: hidden;

    &-container {
      span {
        color: $gray800;
      }

      button > span {
        display: flex;
        align-items: center;
      }
    }

    &-options {
      &.close {
        display: none;
      }

      div {
        padding: 6px 0;
        display: flex;
        align-items: center;
        gap: 10px;
      }

      div > input[type='checkbox'] {
        // appearance: none;
        border-radius: 4px;
        border: 1px solid #d0d5dd;
        padding: 8px;
        background-color: #fff;
        position: relative;
        width: 16px;
        height: 16px;
        transition: 0.2s ease-in-out;
        cursor: pointer;
      }

      div > input[type='checkbox']:checked {
        background-color: $blue600;
        border: 1px solid transparent;

        // &:hover {
        //   background-color: #053ac8;
        //   border: 1px solid #053ac8;
        // }
      }

      div > input[type='checkbox']:hover {
        background-color: $blue100;
        border: 1px solid $blue600;
      }

      div > input[type='checkbox']:checked::before {
        font-family: 'Font Awesome 5 Free';
        content: '\f00c';
        font-weight: 900;
        font-size: 10px;
        color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      label {
        padding-left: 10px;
      }
    }
  }

  &_select-all-btn {
    border-radius: 4px;
    padding: 4px 16px;
    font-size: 12px;
    background-color: $white;
    border: 1px solid $gray200;
    color: $gray600;
    font-weight: 500;
    margin: 16px 0;
  }

  &_select-all-link {
    padding: 0px 20px;
    color: #0069f5;
    font-weight: 400;
    font-size: 12px;
    cursor: pointer;
  }

  &_counter-entity {
    color: #0069f5;
    font-size: 12px !important;
  }

  &-multi {
    display: flex;
    flex-direction: column;
    z-index: 1;

    &_label {
      @include typography-body4_preset;
    }

    &_select {
      &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid $gray200;
        padding: 6px;
        border-radius: 4px;

        p {
          color: red;
        }
      }

      &-menu {
        margin-top: 2px;
        z-index: 2;
        border-radius: 8px;
        padding: 8px;
        box-shadow: 0px 8px 10px rgba(71, 84, 103, 0.1);
      }
    }
  }
}

.order-results {
  
  // com flex no grupo de cima não preicsa dos atributos abaixo
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  // margin-top: -27px; // distancia entre cabeçalho e a ordenação
  // margin-bottom: 10px;

  gap: 4px;
  margin-right: 39px; // Espaço do lado da margem
  
  .rs-picker-toggle-value {
    color: $gray800 !important;
    font-weight: 500 !important;
    
  }

  .rs-picker-toggle {
    background-color: #fff;
  
  }

  
}

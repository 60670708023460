.tv {
  &-card {
    background-color: $white;
    padding: 24px;
    border-radius: 8px;
    &-header {
      padding-bottom: 24px;
      h6 {
        font-family: $poppins !important;
        color: $black;
      }
    }
  }
}

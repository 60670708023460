.decision {
  &-header {
    @include typography-body2_preset;
    color: $blue700 !important;
  }

  &-alert {
    background: $white;
    box-shadow:
      0px 1px 2px rgba(0, 0, 0, 0.04),
      0px 1px 3px rgba(0, 0, 0, 0.06);
    border-radius: 8px;
    display: flex;
    gap: 16px;
    padding: 16px;

    &_message {
      display: flex;
      flex-direction: column;

      span {
        &:last-child {
          color: $gray500;
        }
      }
    }
  }

  &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &-content {
    margin: 16px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    &_select {
      &-container {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-input {
        width: 100%;
        border: 1px solid $gray200;
        border-radius: 4px;
      }
    }
  }

  &-message {
    display: flex;
    justify-content: center;
  }

  &-modal {
    display: flex;
    flex-direction: column;

    gap: 24px;

    button {
      width: 100% !important;
    }
  }

  &-footer {
    display: flex;

    button {
      &:first-child {
        margin-right: 8px;
      }
    }
  }
}

.create-collection {
  display: flex;
  gap: 16px;

  input {
    display: flex;
    align-items: center;
    padding: 8px;
  }

  button {
    width: 260px;
  }
}

.save-collection {
  &_header {
    @include typography-body2_preset;
    color: $blue700 !important;
  }

  &_content {
    &-tab {
      input {
        display: flex;
        align-items: center;
        padding: 8px;
      }

      padding: 0 16px;
    }

    &-table {
      margin-top: 24px;
      min-height: 100px;

      &-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px 0;
        border-top: 1px solid $gray300;
      }
    }
  }

  &_table {
    .rs-table-cell {
      background-color: rgb(248, 244, 226);

      &-first {
        max-width: 250px !important;
      }

      &-last {
        width: 146px !important;

        background-color: rgb(255, 243, 190) !important;
      }
    }
  }

  &_footer {
    display: flex;
    padding: 8px 16px 0 16px;

    button {
      &:first-child {
        margin-right: 8px;
      }
    }
  }
}

.tv-modal-default {
  &_header {
    padding: 0 23px;

    &_title {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #053ac8;
    }

    &_subtitle {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      margin-top: 20px;
      color: #475467;

      b {
        font-weight: 600;
      }
    }
  }

  &_body {
    padding: 0 23px;
    display: flex;
    flex-direction: column;

    &_tab-menu {
      text-align: center;
      padding: 0 128px;
    }

    &_content {
      margin-top: 20px;
    }

    &_label {
      font-size: 16px;
      margin-bottom: 8px;
    }

    &_input {
      margin-bottom: 15px;
    }

    .rs-input {
      border-radius: 4px;

      &:focus,
      &:focus-visible {
        outline: none;
        box-shadow: none;
      }
    }
  }

  &_footer {
    padding: 0 13px 21px 13px;
    display: flex;
    gap: 10px;
  }
}

.new-feature-modal {
  &_body {
    h6 {
      color: $gray900;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 8px;
    }

    p {
      color: $gray500;
      font-size: 14px !important;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &_footer {
    display: flex;
    gap: 12px;

    button {
      border-radius: 4px;
      border: 1px solid $gray200;
      background: #ffffff;
      padding: 4px 16px;

      color: $gray600;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    .btn-action {
      background-color: #053ac8;
      color: #ffffff;
      border: 1px solid #053ac8;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;
    }
  }
}

.operators {
  display: flex;
  flex-direction: column;

  h6 {
    font-size: 14px;
    font-weight: 600;
    font-family: $poppins !important;
  }
  span {
    font-size: 14px;
  }
  &-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(216px, 1fr));
    grid-gap: 8px;
  }
  &-card {
    width: 100%;
    background-color: $white;
    border-radius: 8px;
    border: 1px solid $gray50;
    box-shadow:
      0px 1px 3px 0px rgba(0, 0, 0, 0.06),
      0px 1px 2px 0px rgba(0, 0, 0, 0.04);
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &_header {
      border-bottom: 1.5px solid $gray100;
      padding-bottom: 8px;
      display: flex;
      align-items: center;
      gap: 8px;

      span {
        color: $gray900;
      }
    }

    &_content {
      padding: 8px 0;
      &:nth-child(2) {
        height: 100%;
      }
      &-description {
        font-weight: 400 !important;
        line-height: 20px !important;
        color: $gray600 !important;
        small {
          color: $gray600 !important;
        }
      }
    }

    &_footer {
      display: flex;
      gap: 8px;
      align-items: center;
      color: $blue700;
      padding: 4px;
      font-weight: 600;
    }
  }

  &-btn {
    &-item {
      font-weight: 500;
      border-radius: 4px;
      color: $gray900;
      padding: 2px 10px;
    }
    &-color {
      &-blue {
        background-color: $blue100;
      }

      &-green {
        background-color: $success100;
      }
      &-red {
        background-color: $danger100;
      }
      &-orange {
        background-color: $warning100;
      }
      &-light-green {
        background-color: #daf6f3;
      }
    }
  }

  .operators-buttons{
    display: flex;
    gap: 2px;
    margin-top: 20px;
  }

  .operator-button {
    position: relative;
    background-color: #fff;
    border: 1px solid #e4e7ec;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px !important;
    font-weight: 500;
    margin-right: 4px;
    padding: 4px 8px;
  }

  .operator-wrapper {
    display: inline-block;
  }

  .operator-tooltip{
    position: absolute;
    z-index: 1000 !important; 
    height: 260px;
    width:200px;
    margin-top: -280px;
  }

  .in-results{
    margin-top: 0px;
  }

  .operator-button {
    cursor: pointer;
  }
}

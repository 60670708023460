@import '../../../../../vars.module.scss';

.grid-container-user-list {
  .rs-panel-body {
    padding: 0;
  }
  label {
    color: $turivius;
    font-weight: bold;
  }
}

.container-no-data {
  text-align: center;
  background-color: white;
  padding: 20px;

  span {
    color: $gray;
    margin-bottom: 20px;
  }
}

@import '../../../../../vars.module.scss';

.profile-item {
  .header {
    height: 192px !important;

    .header-cover {
      position: absolute;
      z-index: 1;
      width: 100%;
      border-radius: 50px 5px 5px 5px;
      background: linear-gradient(
        90deg,
        $turivius-light 0%,
        $secondary-dark 31.34%,
        #0068f4 65.2%,
        $secondary-light 100%
      );

      .avatar-profile_content {
        display: flex;
        flex-direction: column;
        position: relative;
        top: 64px;
        left: 24px;
        width: fit-content;

        .avatar-profile {
          height: 120px;
          width: 120px;
          background-color: $white;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 15px;
          box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);

          img {
            max-width: 110px !important;
            max-height: 110px !important;
            border-radius: 12px !important;
          }

          .change-button_content {
            position: absolute;
            top: 95px;
            left: 95px;

            .change-button,
            button {
              background-color: $turivius-light !important;
              color: $white;
              border-radius: 20px;
              box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
            }
          }

          .profile-content {
            display: flex;
            flex-direction: column;
            position: absolute;
            top: 70px;
            left: 150px;

            .profile-line {
              background-color: $secondary-dark;
              height: 8px;
              width: 50px;
            }

            .profile-name {
              display: flex;
              color: $black-deep;
              width: max-content;
            }

            .user-occupation {
              width: max-content;

              b {
                color: #053ac8;
              }
            }
          }
        }
      }
    }
  }
}

.backgroundIAOpen {
  background-color: rgb(0 0 0 / 0.2);
  position: fixed;
  inset: 0px;
  width: 100%;
  height: 100%;
  z-index: 200;
}

.DialogContentClass {
  z-index: 201 !important; 
  width: 768px;
  max-width: 768px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 32px;
  padding-bottom: 32px;
  left: auto;
  right: 56px;
  top: 20%;
  --tw-translate-x: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-translate-y: 0px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.information-collection-search {
  padding: 0 5px;

  b {
    font-weight: 700;
  }
}

.collection-without-results {
  padding: 0 5px;
  margin-top: 10px;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  p {
    margin-bottom: 10px;
  }

  b {
    font-weight: 700;
  }
}
.empty-simgle-collection {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  img {
    max-width: 90%;
  }
}

.empty-collection {
  &_image {
    margin-top: 10px;
    width: 100%;
    text-align: center;

    img {
      max-width: 90%;
    }
  }

  &_informations {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }
}

.collection-search-container {
  padding: 40px 0 16px 0;

  &_header {
    border-bottom: 1px solid #d6d7dc;
    margin-bottom: 24px;
    padding-bottom: 10px;

    &_title {
      color: $blue700;
    }
  }

  &_search {
    &_input {
      height: 40px;
      border-radius: 4px !important;
    }

    &_button {
      background-color: $blue900 !important;
    }
  }
}

.collection {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &_description {
      padding: 0 8px;
      display: flex;
      flex-direction: column;

      h6 {
        @include typography-body1_preset;
      }
    }

    &_actions {
      display: flex;
      gap: 16px;
      position: relative;

      &-menu {
        position: absolute;
        bottom: -96px;
        right: 0;
        background-color: white;
        border: 1px solid $gray200;
        border-radius: 12px;
        padding: 20px 16px;

        z-index: 100;

        box-shadow:
          0px 4px 6px -2px rgba(0, 0, 0, 0.03),
          0px 12px 16px -4px rgba(0, 0, 0, 0.08);

        &-item {
          display: flex;
          align-items: center;
          gap: 8px;
        }
        &-span {
          color: $gray900;
          font-weight: 500;
        }

        button {
          margin-left: 16px;
        }
      }
    }
  }
}

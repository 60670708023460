.tooltip-wrapper {
  position: relative;
  display: inline-block;
}

.tooltip {
  position: absolute;
  background-color: #101828;
  color: #fff;
  padding: 10px 12px;
  border-radius: 8px;
  white-space: nowrap;
  opacity: 0;
  transition:
    opacity 0.3s,
    transform 0.2s;
  pointer-events: none;
  z-index: 100;
  border: 3px solid #344054;
}

.tooltip-wrapper:hover .tooltip {
  opacity: 1;
  transform: translateY(-1px);
}

.tooltip-top {
  bottom: 70%;
  margin-bottom: 10px;
}

.tooltip-top::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: #1c1f26 transparent transparent transparent;
}

.tooltip-right {
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  margin-left: 10px;
}

.tooltip-right::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent #1c1f26 transparent transparent;
}

.tooltip-bottom {
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}

.tooltip-bottom::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent #1c1f26 transparent;
}

.tooltip-left {
  // top: 100%;
  bottom: -20%;
  right: 100%;
  transform: translateY(-50%);
  margin-right: 10px;
}

.tooltip-left::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent transparent #1c1f26;
}

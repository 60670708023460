.notification-push {
  &_header {
    display: flex;
    align-items: center;
    gap: 8px;

    &_category {
      color: #0069f8;
    }

    &_timestamp {
      color: #aaa;
    }
  }

  &_body {
    margin-top: 10px;

    &_title {
      font-weight: 600;
    }

    &_subtitle {
      color: #7e7b7b;
    }
  }
}

.notifications-session {
  margin-top: 30px;

  &_header {
    display: flex;
    gap: 8px;
    align-items: center;

    label {
      color: #475467;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

@import './../../vars.scss';

@media only screen and (max-width: 499px) {
  .rs-content
    .custom-container
    .rs-header
    .custom-container-header
    .header-hero {
    padding-left: 40px;
  }
}

.custom-sidebar {
  flex: unset !important;
  width: unset !important;
}

.custom-sidenav {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;

  .sidenav-header {
    .user {
      padding: 16px 10px;
      color: white;
      font-weight: 600;
      border-bottom: 1px solid rgba($color: #ffffff, $alpha: 0.5);
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-direction: column;
      display: flex;

      span {
        white-space: nowrap;
      }

      .btn-header {
        color: white;
        padding: 2px;
        font-size: 12px;

        &:hover,
        &:focus {
          text-decoration: none;
          color: rgba(255, 255, 255, 0.6);
        }
      }
    }
  }

  .option-sidenav {
    padding: 0px 10px;
    display: flex;
    align-items: center;

    &.footer {
      width: 100%;
      padding: 10px 0 10px 10px;
      border-top: 1px solid rgba($color: #ffffff, $alpha: 0.5);
    }

    &.dropdown {
      background-color: $turivius !important;
      padding: 5px 0px;
      color: #fff;
    }
    .fragment-sidenav {
      margin-right: 10px;
    }

    &:focus,
    &:focus-within {
      background-color: black !important;
    }
  }

  .footer-sidebar {
    display: flex;
    align-items: center;
    width: 100% !important;

    &.rs-sidenav-item {
      margin-top: auto !important;
      margin-bottom: 10px !important;
    }

    .footer-sidenav {
      padding: 10px 10px;
      display: flex;
      align-items: center;
    }

    .fragment-sidenav {
      margin-right: 10px;
    }
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px $turivius-dark;
    background-color: $turivius;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background-color: $turivius;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $turivius-dark;
  }

  @media only screen and (min-width: 500px) {
    width: 150px !important;

    .open-sidebar {
      display: none !important;
    }
  }

  @media only screen and (max-width: 499px) {
    width: 140px !important;
    left: -150px;
    z-index: 10;
    position: absolute;
    -webkit-box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);

    .open-sidebar {
      position: fixed;
      left: 20px;
      z-index: 100;
      height: 67px;
      display: flex;
      align-items: center;
      background-color: $turivius;
      height: 30px;
      padding: 5px 7px;
      border-radius: 5px;

      &.close {
        left: 150px;
        background-color: $turivius-hot;
      }
    }

    &.open {
      left: 0px;
      transition: left 0.2s linear;
    }
  }

  height: 100vh;
  background-color: $turivius !important;

  #turivius-sidenav {
    height: 100vh;
  }

  .rs-sidenav-default {
    background-color: transparent;
  }

  .rs-sidenav-nav {
    display: flex !important;
    flex-direction: column !important;
    width: 100%;
  }

  .rs-dropdown {
    flex: unset;
  }

  .rs-sidenav-header {
    padding-top: 15px;
    text-align: center;
  }

  .rs-sidenav-body {
    padding-top: 15px;
    height: 100% !important;
    display: flex;

    .rs-sidenav-item,
    .rs-dropdown-toggle {
      background-color: transparent;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none !important;
        box-shadow: none;
      }

      @media only screen and (min-width: 500px) {
        text-align: center;

        & {
          padding-left: 0px;

          span {
            font-size: 13px;
          }
        }
      }

      @media only screen and (max-width: 499px),
        screen and (max-height: 700px) {
        text-align: center;

        & {
          padding: 8px 0px;
          height: auto;

          i {
            font-size: 1em;
          }

          .rs-avatar,
          img {
            width: 20px;
            height: 20px;
          }

          span {
            font-size: 12px;
          }
        }
      }
    }

    .rs-sidenav-item:not(.rs-sidenav-item-active),
    .rs-dropdown-toggle,
    .rs-dropdown-item {
      position: relative;

      &,
      * {
        color: #fff;
      }

      &::after {
        content: '';
        position: absolute;
        width: 0px;
        height: 2px;
        left: 0%;
        bottom: 0;

        transition: all ease-in 0.3s;
      }

      &:hover,
      &:focus {
        background: transparent !important;
      }

      &:hover::after {
        width: 70%;
        left: 0;
      }
    }

    .rs-dropdown-item {
      span {
        font-size: 12px;
        color: #fff;
        margin-left: 25px;
      }
    }

    .rs-sidenav-item-active {
      font-weight: bold;
      background-color: transparent;
      border-bottom: 2px solid #fff;
      border-image-slice: 1;
      border-image: linear-gradient(
        to left,
        rgba(108, 219, 141, 0) 30%,
        #fff 0%,
        #fff 100%,
        rgba(108, 219185, 141, 0) 100%
      );

      * {
        color: #fff;
      }
    }
  }
}

.image-logo {
  max-height: 55px;
  max-width: 100%;
  margin: auto;
  padding: 0px;
  margin-top: 20px;
}

.tv-multiselect {
  &_container {
    position: relative;
  }

  &_label {
    color: $blue900;
    font-weight: 500;
    line-height: 24px;
  }

  &_input--group {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 4px;
    border: 1px solid $gray200;
    cursor: pointer;

    span {
      text-align: left;
      color: $gray400;
    }
  }

  &_options--group {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 4px;
    border: 1px solid $gray200;
    position: sticky;
    top: 80px;
    left: 0;
    height: 320px;
    overflow-y: scroll;
  }

  &_options--item {
    padding: 8px 16px;
    background-color: $white;

    box-shadow: 0px 0px 0px -4px rgba(0, 0, 0, 0.08);
    cursor: pointer;

    &:hover {
      background-color: $blue50;
    }
  }

  &_selectedgroup {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    &-item {
      padding: 4px;
      border-radius: 4px;
      border: 1px solid $gray300;
      background-color: $white;
      display: flex;
      align-items: center;
      gap: 4px;

      span {
        font-size: 10px;
        font-weight: 500;
        color: $blue900;
      }

      svg {
        cursor: pointer;
      }
    }
  }
}

.tv-checkbox {
  &_label {
    color: $blue900;
    font-weight: 500;
    line-height: 24px;
  }

  &_checkgroup {
    padding: 0 4px;
  }

  &_checkgroup--item {
    display: flex;
    gap: 4px;
  }
}

.container-content-history-search {
  position: absolute;
  right: 0;
  top: 0;
  width: 551px;
  height: 100%;
  background-color: white;
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
    0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
    0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  z-index: 10;
  display: flex;
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.title-box-drawer {
  line-height: 24px;
  font-size: 20px;
  font-weight: 700;
  padding-bottom: 10px;
}

.text-desc-history {
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: #667085;
  padding-top: 10px;
  padding-bottom: 10px;
}

.title-box {
  font-size: 16px;
  font-weight: 600;
  font-family: 'Poppins';
  color: #000000;
}

.tooltip-text {
  visibility: hidden;
  width: 108px;
  background-color: #101828;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  margin-left: -60px;
  opacity: 0;
  right: 1%;
  transition: opacity 0.3s;
  font-size: 10px;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.tv-card-header {
  display: none;
}

.history {
  height: 100%;
  width: 461px;
}


.expasive-box-content-history {
  border-bottom: 1px solid #F2F4F7;
  padding-bottom: 14px;
}

.list-history{
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4{
    font-size: 12px;
    font-weight: 600;
    width: 270px;
    height: 20px;
    line-height: 20px;
  }
  height: 42px;
}

.searched-in {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: #98A2B3;
  margin-left: 8px;
}

.button-previous-and-next {
  width: 88px;
  height: 24px;
  padding: 4px;
  align-items: center;
  padding: 4px;
  border: 1px solid #F2F4F7;
  border-radius: 8px;
  font-size: 12px;
  margin-left: 8px;
  color: #667085;
}

.button-previous-and-next:hover {
  background-color: #c9c9c9a9;
}

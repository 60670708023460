$rubik: 'Rubik', sans-serif;
$poppins: 'Poppins', sans-serif;

@mixin typography-h1_preset {
  font-family: $rubik;
  font-size: $h1-size;
  line-height: $base;
}

@mixin typography-h2_preset {
  font-family: $rubik;
  font-size: $h2-size;
  line-height: $base;
}

@mixin typography-h3_preset {
  font-family: $rubik;
  font-size: $h3-size;
  line-height: $base;
}

@mixin typography-h4_preset {
  font-family: $rubik;
  font-size: $h4-size;
  line-height: $base;
}

@mixin typography-h5_preset {
  font-family: $rubik;
  font-size: $h5-size;
  line-height: $base;
}

@mixin typography-h6_preset {
  font-family: $rubik;
  font-size: $h6-size;
  line-height: $base;
}

@mixin typography-h7_preset {
  font-family: $rubik;
  font-size: $h7-size;
  line-height: $base;
}

@mixin typography-h8_preset {
  font-family: $rubik;
  font-size: $h8-size;
  line-height: $base;
}

@mixin typography-h9_preset {
  font-family: $rubik;
  font-size: $h9-size;
  line-height: $base;
}

@mixin typography-body1_preset {
  font-family: $poppins;
  font-size: $body-1;
  line-height: $tall;
}

@mixin typography-body2_preset {
  font-family: $poppins;
  font-size: $body-2;
  line-height: $tall;
}

@mixin typography-body3_preset {
  font-family: $poppins;
  font-size: $body-3;
  line-height: $base;
}

@mixin typography-body4_preset {
  font-family: $poppins;
  font-size: $body-4;
  line-height: $base;
}

@mixin typography-body5_preset {
  font-family: $poppins;
  font-size: $body-5;
  line-height: $base;
}

@mixin typography-button_preset {
  font-family: $poppins;
  line-height: $base;
}

@mixin typography-placeholder_preset {
  font-family: $poppins;
  font-size: $body-4;
  line-height: $base;
}

.text {
  &-align {
    &_center {
      text-align: center;
    }

    &_left {
      text-align: left;
    }

    &_right {
      text-align: right;
    }
  }
}

.text {
  &-color {
    &_white {
      color: $white;
    }

    &_black {
      color: $black;
    }

    &_gray {
      color: $gray500;
    }

    &_primary {
      color: $blue900;
    }

    &_success {
      color: $success700;
    }

    &_warning {
      color: $warning600;
    }

    &_danger {
      color: $danger700;
    }
  }
}

.hot-topics {
  display: flex;
  flex-direction: column;

  &_content {
    display: flex;
    align-items: center;
    margin-top: 8px;
    padding: 8px;
    cursor: pointer;

    &:hover {
      border-radius: 8px;
      background-color: $gray100;
    }

    .badge-hot {
      width: 32px;
      height: 32px;
      border-radius: 50% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
    }

    &:first-child {
      margin-top: 0;
    }

    p {
      @include typography-body5_preset;
      color: $gray800;
    }
  }

  &_footer {
    padding-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

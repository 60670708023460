@import '../../pages/Profiles.scss';
@import '../../../../../vars.module.scss';

.profile-item {
  .change-password {
    @include profile-sections;

    .title {
      padding-bottom: 8px;
      font-size: 21px;
      font-weight: 500 !important;
      color: $black-deep;
    }

    .button {
      width: 100%;
      margin-top: 24px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;

      button {
        background-color: $secondary-dark;
        border: 0.5px solid $secondary-dark;
      }
    }
  }

  .rs-form-control-label {
    font-size: 16px;
  }
}

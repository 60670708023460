.container {
  position: relative;
  display: inline-block;
  margin-left: 6px;
}

.button {
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #000;
  font-family: 'Poppins';

  .selectedOption {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0d91fe;
    font-family: 'Poppins';
    background-color: #ffffff;
    border: 1px solid #e5e5ea;
    padding: 8px 10px;
    border-radius: 4px;
    font-weight: 400;
    gap: 4px;
    transition: border-color 0.3s;
  }

  .selectedOption:hover {
    border-color: #0d91fe;
  }
}

.noOptions {
  margin-left: 16px;
}

.filterInput {
  border: none;
  outline: none;
  padding: 16px;
}

.inputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}


.dropdown {
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 8px 0;
  list-style: none;
  width: 350px;
  z-index: 1;
  margin-left: 95px;
}

.option {
  padding: 8px 16px;
  cursor: pointer;
  color: #5b6a81;
  font-weight: 400;
  background-color: white;
  transition: background-color 0.3s ease;

  &.active {
    color: #0d91fe;
    font-weight: 700;
    background-color: #f0f4f8;
  }

  &:hover {
    background-color: #e6f7ff;
  }
}

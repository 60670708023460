.turivius-preloader {
  // position: fixed; /* Garante a centralização em toda a tela */
  top: 0;
  left: 0;
  width: 100vw; /* Ocupa a largura total da viewport */
  height: 100vh; /* Ocupa a altura total da viewport */
  display: flex; /* Centraliza os elementos internos */
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Mantém a prioridade visual */
  background-color: transparent; /* Fundo transparente */
  opacity: 0; /* Inicialmente invisível */
  visibility: hidden; /* Evita interações quando invisível */
  transition: opacity 0.3s ease, visibility 0.3s ease; /* Animação */

  &.backdrop {
    background-color: rgba(0, 0, 0, 0.2); /* Fundo escurecido */
  }

  &.visible {
    opacity: 1;
    visibility: visible; /* Torna visível */
  }
}

.turivius-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Empilha elementos verticalmente */
}

.rs-loader-spin {
  width: 5rem; /* Responsivo */
  height: 5rem;
}

.turivius-spinner-image {
  margin-top: -1.5rem;
  margin-left: -1.5rem;
  max-width: 60%; /* Responsivo */
  max-height: 60%;
}












$white: #fff;
$black: #000;

$black20: #dedede;
$black60: #666;

$gray25: #fcfcfd;
$gray50: #f9fafb;
$gray100: #f2f4f7;
$gray200: #e4e7ec;
$gray300: #d0d5dd;
$gray400: #98a2b3;
$gray500: #667085;
$gray600: #475467;
$gray700: #344054;
$gray800: #1d2939;
$gray900: #101828;

$blue25: #f5faff;
$blue50: #eff8ff;
$blue100: #d1e9ff;
$blue200: #b2ddff;
$blue300: #84caff;
$blue400: #53b1fd;
$blue500: #0d91fe;
$blue600: #0069f5;
$blue700: #053ac8;
$blue800: #032378;
$blue900: #010c28;

$purple25: #fcfaff;
$purple50: #f9f5ff;
$purple100: #f4ebff;
$purple200: #e9d7fe;
$purple300: #e9d7fe;
$purple400: #b692f6;
$purple500: #9e77ed;
$purple600: #7f56d9;
$purple700: #6941c6;
$purple800: #53389e;
$purple900: #42307d;

$danger25: #fffbfa;
$danger50: #fef3f2;
$danger100: #fee4e2;
$danger200: #fecdca;
$danger300: #fda29b;
$danger400: #f97066;
$danger500: #f04438;
$danger600: #d92d20;
$danger700: #b42318;
$danger800: #912018;
$danger900: #7a271a;

$warning25: #fffcf5;
$warning50: #fffaeb;
$warning100: #fef0c7;
$warning200: #fedf89;
$warning300: #fec84b;
$warning400: #fdb022;
$warning500: #f79009;
$warning600: #dc6803;
$warning700: #b54708;
$warning800: #93370d;
$warning900: #7a2e0e;

$success25: #f6fef9;
$success50: #ecfdf3;
$success100: #d1fadf;
$success200: #a6f4c5;
$success300: #6ce9a6;
$success400: #32d583;
$success500: #12b76a;
$success600: #039855;
$success700: #027a48;
$success800: #05603a;
$success900: #054f31;

$information25: #f5fbff;
$information50: #f0f9ff;
$information100: #e0f2fe;
$information200: #b9e6fe;
$information300: #7cd4fd;
$information400: #36bffa;
$information500: #0ba5ec;
$information600: #0086c9;
$information700: #026aa2;
$information800: #065986;
$information900: #0b4a6f;

.turivius-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin: 11px 0px;

  &.default {
    background-color: #053ac8;
    color: #fff;
  }

  &.outline-none {
    border: 1px solid $gray300;
    background-color: #ffffff;
    color: $gray600;
    outline-style: none;
  }

  &.outline {
    border: 1px solid $gray300;
    background-color: #ffffff;
    color: $gray600;
  }

  &.with-icon {
    background-color: #fff;
    color: $gray500;
    border: 1px solid $gray300;
    gap: 12px;
  }
}

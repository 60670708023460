.tv-resultcard {
  max-width: 100vw;
  width: 100%;
  background-color: $white;
  padding: 20px;
  border-radius: 8px;

  svg {
    path {
      stroke-width: 2px;
    }
  }

  &.monitoring {
    box-shadow: 0px 0px 5px #e7e7e7;
  }

  &_content {
    margin-top: 24px;
    border-top: 1px solid $gray200;
    border-bottom: 1px solid $gray200;

    h6 {
      @include typography-h7_preset;
      padding-bottom: 4px;
    }
  }

  &_footer {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
}

.limited-text {
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.sub-buttons {
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  gap: 16px;
}

.tv-resultcard .tv-resultcard_content {
  font-family: 'Poppins', sans-serif !important;
  font-size: 16px !important;
  margin-top: 24px;
  border-top: 1px solid #d0d5dd;
  border-bottom: 1px solid #d0d5dd;
  transition: max-height 0.3s ease;
  overflow: hidden;
  max-height: 320px; // EXPLANATION: Tamanho máximo do card
}

.tv-resultcard_content.expand {
  overflow-x: hidden;
  overflow-y: auto;
}

/* CTA.module.css */
.cta-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: center;
  width: 300px; /* Aqui segue o tamanho dos CTAs */
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cta-card:hover {
  
  border: 1px solid;
  border-color: #2970ff; /* Contorno azul claro no hover*/
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adiciona uma leve elevação */
}

.cta-card:hover .cta-button {
  border: 1px solid;
  border-color: #2970ff; /* Borda laranja no foco */  
  color: #2970ff;
  
}

.cta-card:hover .cta-icon {
  border: 1px solid;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adiciona uma leve elevação */
  
}


.cta-button {
  background-color: transparent; /* Fundo transparente */
  color: #6b7280; /* Texto em cinza escuro */
  border: 2px solid #e5e7eb; /* Borda cinza claro */
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 20px;
  
}

.cta-button:hover {
  cursor: pointer;
  color: #2970ff;
  cursor: pointer;
  border: 1px solid;
  border-color: #2970ff; /* Contorno azul claro no hover*/
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adiciona uma leve elevação */
  transform: scale(1.02); /* Leve aumento no tamanho */
}

.cta-icon {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 30px;
}

.cta-title {
  margin: 30px 0 6px;
  font-size: 18px;
  font-weight: 500;
  color: #333;
  font-family: 'Inter', sans-serif;
}

.cta-description {
  font-size: 14px;
  color: #666;
  flex-grow: 1;
  font-family: 'Inter', sans-serif;
}

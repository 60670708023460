@import '../../../../../vars.module.scss';

.turivius-panel {
  background-color: $white;
  border: 1px solid #e5e5ea;
  margin: 10px 0px;
  border-radius: 8px;

  .turivius-panel-header {
    padding: 20px 5px;

    &.load {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .turivius-panel-body {
    padding: 20px;
    padding-top: 0px;
  }

  .turivius-panel-btn-toggle-expand {
    float: right;
    padding: 9.25px;
    cursor: pointer;
  }

  .turivius-group-actions {
    display: inline-grid;
    position: absolute;
    right: -9%;

    .turivius-btn-actions {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $secondary_light;
      padding: 8px;
      border-radius: 40px;
      margin-bottom: 3px;

      &.save {
        background-color: $green;
      }

      &.eye-slash {
        background-color: $secondary_dark;
      }

      svg {
        color: $white;
      }
    }
  }
}

.rs-content {
  overflow: auto !important;
}

.business-home {
  display: flex;
  max-width: 100vw;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90vh;
  padding: 64px 48px 48px 48px;
  overflow: auto;
  position: relative;

  &_back {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  &_title {
    font-family: $poppins !important;
    text-align: left;
    b {
      font-weight: 600;
    }
  }

  &_content {
    display: flex;
    align-items: center;
    gap: 24px;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  &_form {
    width: 90% !important;
    display: flex;
    gap: 8px;
    position: relative;
    @media (max-width: 680px) {
      width: 100% !important;
      flex-direction: column;
      button {
        margin-top: 8px;
        width: 100% !important;
      }
    }
  }

  &_input {
    width: 100% !important;
    background: $white;
    border: none;
    box-shadow: 0px 4px 25px rgba(209, 233, 255, 0.3);
    border-radius: 8px;
    font-size: 14px;

    padding-left: 16px;
    height: 48px;
    box-sizing: border-box !important;

    outline-color: $blue200;
  }
  &_suggestion {
    width: inherit;
    max-height: 320px;
    border-radius: 8px;
    overflow-x: scroll;
    background-color: $white !important;
    padding: 16px;
    position: absolute;
    right: 100;
    top: 50px;

    &-item {
      cursor: pointer;
      padding: 4px 2px;
      margin: 2px 0;
      &:hover {
        background-color: $blue100;
      }
    }
  }
}

.dashboard {
  display: flex;
  flex-direction: column;

  &-header {
    width: 100%;
    background-color: $white;
    padding: 24px 24px 0px 48px;
    &_sub {
      display: flex;
      align-items: center;
      gap: 24px;
      position: relative;

      .monitoring-term {
        width: 50%;
        justify-self: flex-start !important;
        display: flex;
        gap: 8px;
      }
    }
  }

  &-container {
    width: 100%;
    display: flex;
    padding: 30px 24px 16px 48px;

    &_filters {
      display: flex;
      flex-direction: column;
    }

    &_content {
      width: 100%;
      padding-left: 24px;

      @media (max-width: 1200px) {
        padding: 24px 0;
      }

      &-sub {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 32px;
      }

      &-span {
        @include typography-body1_preset;
        color: $gray600;
        b {
          color: $blue700;
        }
      }
    }

    @media (max-width: 1200px) {
      flex-direction: column;

      &_content {
        width: 100%;
      }
    }

    @media (min-width: 1900px) {
      &_content {
        width: 100%;
      }
    }
  }
  &-overview {
    width: 100%;

    h3 {
      font-family: $poppins !important;
      font-size: $h5-size;
      color: $gray900;
    }
    h5 {
      font-family: $poppins !important;
      font-size: $body-1;
    }
    .assuntos-relacionados {
      color: $gray900;
    }

    &_navigate-subject {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p {
        color: $blue900;
      }

      h5 {
        @include typography-body1_preset;
      }

      &-footer {
        display: flex;
        gap: 16px;
      }
    }

    &_filters {
      width: 320px;
    }

    &_panorama {
      &-card {
        background-color: $blue700 !important;
        color: $white;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 8px;

        &-item {
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          border-left: 3px solid rgba(1, 12, 40, 0.3);

          &:first-child {
            border: none !important;
          }
        }
      }
    }
  }

  &-highlights {
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 896px) {
      flex-direction: column;
    }

    &_card {
      background-color: $blue100;
      border-radius: 8px;
      padding: 24px;
      max-width: 320px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: relative;

      &-icon {
        position: absolute;
        bottom: -16px;
        right: -12px;
        background-color: $blue100;
        border: 5px solid $white;
        border-radius: 50%;
        width: 56px;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        margin-top: 8px;
      }

      &-vara {
        background-color: $blue700;
        border-radius: 8px;
        padding: 24px;
        max-width: 320px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        position: relative;

        &-item {
          color: $white;
          font-weight: 500;
          width: 100% !important;
          padding: 16px 0;
          border-top: 2px solid $blue600;

          &:first-child {
            border-top: none;
          }

          &_group {
            display: flex;
            gap: 16px;

            div {
              width: 180px;
            }
          }
        }

        &-icon {
          position: absolute;
          bottom: -16px;
          right: -12px;
          background-color: $blue700;
          border: 5px solid $white;
          border-radius: 50%;
          width: 56px;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 700;
          margin-top: 8px;
        }
      }
      @media screen and (max-width: 886px) {
        flex-direction: column;
      }
    }
  }

  &-process-list {
    &_table {
      width: 100%;
      &-header-overview {
        background-color: $blue200;
        border-bottom: 10px solid $white;
        font-weight: 500;
        color: $blue900;

        th {
          text-align: left;
          &:nth-child(5) {
            padding-left: 8px;
          }
          &:last-child {
            padding-left: 8px;
          }
        }
      }
      &-header-subject {
        background-color: $blue200;
        border-bottom: 10px solid $white;
        color: $blue900;
        font-weight: 500;
        th {
          text-align: left;
          &:nth-child(5) {
            padding-left: 8px !important;
          }

          &:last-child {
            padding-left: 8px;
          }
        }
      }

      &-content {
        font-size: 1rem;
        line-height: 1.5rem;

        td {
          padding: 6px 0;
          max-width: 160px;
          width: fit-content;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-bottom: 0.5px solid $gray300;
          &:nth-child(3) {
            width: 156px !important;
          }
          &:nth-child(4) {
            padding-right: 8px !important;
          }
          &:nth-child(5) {
            padding-left: 8px !important;
          }
          &:last-child {
            padding-left: 24px;
          }
        }

        tr {
          &:last-child {
            td {
              border-bottom: none !important;
            }
          }
        }

        &-btn {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}

.card-divisor {
  height: 80px;
  width: 1px !important;
  background-color: $white;
}

.tv-link {
  @include typography-button_preset;
  border: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

  &-sm {
    font-size: $body-4 !important;
    line-height: $shorter;
  }
  &-md {
    font-size: $body-3 !important;
    line-height: $shorter;
  }

  &-primary {
    background-color: transparent;
    color: $blue700;

    &:hover {
      color: $blue600;
      text-decoration: underline;
      transition: ease 0.8s;
    }
    &:focus {
      color: $blue500;
      text-decoration: underline;
      transition: ease 0.8s;
    }
    &:active {
      color: $blue800;
    }
  }
  &-secondary {
    background-color: transparent;
    color: $gray600;

    &:hover {
      text-decoration: underline;
      color: $gray700;
      transition: ease 0.8s;
    }
    &:focus {
      text-decoration: underline;
      color: $gray500;
      transition: ease 0.8s;
    }
    &:active {
      text-decoration: none;
      color: $gray700;
      transition: ease 0.8s;
    }
  }
  &-danger {
    background-color: transparent;
    color: $danger600;

    &:hover {
      text-decoration: underline;
      color: $danger700;
      transition: ease 0.8s;
    }
    &:focus {
      text-decoration: underline;
      color: $danger500;
      transition: ease 0.8s;
    }
    &:active {
      text-decoration: none;
      color: $danger800;
      transition: ease 0.8s;
    }
  }

  &-disabled {
    background-color: $gray200 !important;
    color: $gray400 !important;
    cursor: not-allowed;
  }
}

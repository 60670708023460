@import '../../../../../vars.module.scss';

.avatar-content {
  position: relative;
  min-height: 120px;
  width: 100%;
  border-radius: 50px 5px 5px 5px;
  background: linear-gradient(
    90deg,
    $turivius-light 0%,
    $secondary-dark 31.34%,
    #0068f4 65.2%,
    $secondary-light 100%
  );

  .btn-new-user {
    text-align: center;
    position: absolute;
    top: 140px;
    right: 0px;
  }

  .avatar {
    height: 120px;
    width: 120px;
    background-color: $white;
    position: absolute;
    top: 70px;
    left: 20px;
    border-radius: 15px 15px 3px 15px;
    box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.25);
    align-items: center;
    justify-content: center;
    display: flex;

    img {
      max-width: 110px !important;
      max-height: 110px !important;
      border-radius: 15px 15px 3px 15px !important;
    }

    .change-button-content {
      position: absolute;
      top: 95px;
      left: 95px;

      .change-button,
      button {
        background-color: $turivius-light !important;
        color: $white;
        border-radius: 20px;
        box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.25);
      }
    }

    .company-content {
      position: absolute;
      top: 70px;
      left: 150px;

      .company-line {
        background-color: $secondary-dark;
        height: 8px;
        width: 50px;
      }

      .company-name {
        color: $black-deep;
        width: 50vh;
      }

      .company-subname {
        margin-top: -5px;
        color: black;
      }
    }
  }
}

@media screen and (max-width: 836px) {
  .avatar-content {
    min-height: 80px;
    width: 100%;

    .btn-new-user {
      top: 210px;
      left: 50%;
      transform: translateX(-50%);
    }

    .avatar {
      height: 100px;
      width: 100px;
      top: 20px;
      left: 50%;
      transform: translateX(-50%);

      img {
        max-width: 90px !important;
        max-height: 90px !important;
        border-radius: 15px 15px 3px 15px !important;
      }

      .change-button-content {
        position: absolute;
        top: 80px;
        left: 80px;
      }

      .company-content {
        top: 120px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;

        .company-line {
          margin-bottom: 8px;
        }

        .company-name {
          line-height: 27px;
          color: $black-deep;
          width: 45vh;
          font-size: 19px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}

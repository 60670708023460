.tv-select {
  &_collapsable {
    &-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-options {
    }
  }
}

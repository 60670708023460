@import './variables/colors';
@import './variables/typography';
@import './variables/borders';
@import './variables/font-sizes';
@import './variables/font-weights';
@import './variables/line-heights';

@import './helpers/utils';
@import './helpers/divisors';

@import './components/result-container';
@import './components/buttons';
@import './components/resultcard';
@import './components/timeline';
@import './components/inputs';
@import './components/single-filter';
@import './components/search-jurimetrics';
@import './components/articles';
@import './components/hot-topics';
@import './components/recent-research';
@import './components/resultcard-loader';
@import './components/modals';
@import './components/filters';
@import './components/selects';
@import './components/tag-info';
@import './components/notification';
@import './components/menu-options';
@import './components/drawer';
@import './components/success-checklist';
@import './components/knowledge-hub';
@import './components/additional-resources';
@import './components/tour';
@import './components/operators';
@import './components/recent-box.scss';

@import './components/monitoring/informative-icons';
@import './components/monitoring/toggle-monitoring';
@import './components/monitoring/monitoring-configs';
@import './components/monitoring/monitoring-filters';

@import './components/business-dashboard/use-cases';

@import './core/inputs';

@import './pages/business-dashboard';
@import './pages/homepage';
@import './pages/resultpage';
@import './pages/login';
@import './pages/collections';
@import './pages/monitoring';
@import './pages/setup';

#root {
  overflow-x: hidden;
  background-color: #eff8ff;
}

body {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background-color: $white;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: $gray300;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: $gray400;
  }
}

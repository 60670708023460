.tv-searchbar {
  background-color: $white;
  border-radius: 8px;
  padding: 4px 4px 4px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  input {
    padding: 10px 8px;
    @include typography-placeholder_preset;
  }

  &_input {
    border: none;
    width: 100% !important;
  }
}
.principal_searchbar {
  display: flexbox;
}

.btn-menu-option {
  background-color: transparent;
  color: rgb(180, 180, 191);

  &:hover,
  &:focus {
    color: rgb(180, 180, 191);
    background-color: transparent;
  }
}

.btn-menu-option svg {
  fill: rgb(180, 180, 191);
}

.btn-menu-option:hover svg {
  fill: rgb(137, 137, 153);
  transform: scale(1.2);
  transition: transform 0.3s ease-in-out;
}

.box-button-card-modal {
  display: flex;
  justify-content: center;
  
}

.collection-folder_content {
  cursor: pointer;
  max-height: 195px;
  transition: border 0.2s ease; /* Animação suave */
  border: 0.5px solid rgb(208, 213, 221, 0.6); /* Define uma borda inicial transparente */
}

.collection-folder_content:hover {
  border: 0.5px solid #0d91fe; /* Borda azul claro ao passar o mouse */
  background-color: rgba(253, 253, 253, 1);
}

.button-card-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #0d91fe;
  width: 90%;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  margin: 0px, 20px;
  padding: 8px 0px;
  transition: all 0.4s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.button-card-modal:hover {
  border: 1.5px solid #0d91fe;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.03);
}

.box-card {
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
}

.decisions {
  display: flex;
  justify-content: center;
  font-size: 12px;
  width: 100%;
}

.decisions > span {
  display: flex;
  justify-content: left;
  font-size: 12px;
  width: 100%;
  margin-left: 48px;

  margin-bottom: 20px;
}

.more-title {
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 8px;
  text-align: center;
  height: 20px;
  width: 100%;
  padding-top: 20px;
}

.icon-stars-button-modal {
  width: 12px;
}

.box-footer-card {
  width: 100%;
  display: flex;
  justify-content: center;
}

.box-footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 5px;
  padding: 20px;
  position: relative;
}

.content-calendar {
  display: flex;
  align-items: center;
  gap: 7px;
}

.info-icon-footer {
  display: flex;
  align-items: center;
}

.container-cards {
  display: flex;
  width: 300px;
}

.collection-folder {
  height: 150px;

  &_content {
    margin: 10px auto;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 8px;

    &_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;

      .title-folder {
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: center;
        padding: 0px 10px;
        cursor: pointer;
      }

      span {
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &_body {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 10px;
    }

    &_footer {
      background-color: #ececec;
      padding: 10px;
      border-radius: 0 0 8px 8px;
    }
  }
}

.title-contents {
  width: 100%;
  margin: 4px 12px;
  color: #101828;
  font-weight: 600;
  font-size: 14px;
}

.title-folder,
.btn-menu-option,
.button-card-modal,
.content-calendar,
.info-icon-footer {
  cursor: pointer;
}

@media (max-width: 1630px) {
  .box-footer-content {
    flex-direction: column;
    gap: 6px;
    font-size: 12px;
  }
  

}
@media (max-width: 1242px) {
  .button-card-modal {
    gap: 0px;
  }
}

@media (max-width: 1199px) {
  .button-card-modal {
    gap: 8px;
  }
}

@media (max-width: 1002px) {
  .button-card-modal {
    gap: 0px;
  }
}

@media (max-width: 992px) {
  .button-card-modal {
    border: 1px solid #d0d5dd;
    gap: 8px;
  }
}

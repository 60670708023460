.m {
  &-0 {
    margin: 0px;
  }

  &-1 {
    margin: 0.25rem;
  }

  &-2 {
    margin: 0.5rem;
  }

  &-3 {
    margin: 1rem;
  }

  &-4 {
    margin: 1.5rem;
  }

  &-5 {
    margin: 2rem;
  }

  &t {
    &-0 {
      margin-top: 0px;
    }

    &-1 {
      margin-top: 0.25rem;
    }

    &-2 {
      margin-top: 0.5rem;
    }

    &-3 {
      margin-top: 1rem;
    }

    &-4 {
      margin-top: 1.5rem;
    }

    &-5 {
      margin-top: 2rem;
    }
  }

  &b {
    &-0 {
      margin-bottom: 0px;
    }

    &-1 {
      margin-bottom: 0.25rem;
    }

    &-2 {
      margin-bottom: 0.5rem;
    }

    &-3 {
      margin-bottom: 1rem;
    }

    &-4 {
      margin-bottom: 1.5rem;
    }

    &-5 {
      margin-bottom: 2rem;
    }
  }

  &l {
    &-0 {
      margin-left: 0px;
    }

    &-1 {
      margin-left: 0.25rem;
    }

    &-2 {
      margin-left: 0.5rem;
    }

    &-3 {
      margin-left: 1rem;
    }

    &-4 {
      margin-left: 1.5rem;
    }

    &-5 {
      margin-left: 2rem;
    }
  }

  &r {
    &-0 {
      margin-right: 0px;
    }

    &-1 {
      margin-right: 0.25rem;
    }

    &-2 {
      margin-right: 0.5rem;
    }

    &-3 {
      margin-right: 1rem;
    }

    &-4 {
      margin-right: 1.5rem;
    }

    &-5 {
      margin-right: 2rem;
    }
  }

  &x {
    &-0 {
      margin: 0px;
    }

    &-1 {
      margin: 0 0.25rem;
    }

    &-2 {
      margin: 0 0.5rem;
    }

    &-3 {
      margin: 0 1rem;
    }

    &-4 {
      margin: 0 1.5rem;
    }

    &-5 {
      margin: 0 2rem;
    }
  }

  &y {
    &-0 {
      margin: 0px;
    }

    &-1 {
      margin: 0.25rem 0;
    }

    &-2 {
      margin: 0.5rem 0;
    }

    &-3 {
      margin: 1rem 0;
    }

    &-4 {
      margin: 1.5rem 0;
    }

    &-5 {
      margin: 2rem 0;
    }
  }
}

.p {
  &-0 {
    padding: 0px;
  }

  &-1 {
    padding: 0.25rem;
  }

  &-2 {
    padding: 0.5rem;
  }

  &-3 {
    padding: 1rem;
  }

  &-4 {
    padding: 1.5rem;
  }

  &-5 {
    padding: 2rem;
  }

  &t {
    &-0 {
      padding-top: 0px;
    }

    &-1 {
      padding-top: 0.25rem;
    }

    &-2 {
      padding-top: 0.5rem;
    }

    &-3 {
      padding-top: 1rem;
    }

    &-4 {
      padding-top: 1.5rem;
    }

    &-5 {
      padding-top: 2rem;
    }
  }

  &b {
    &-0 {
      padding-bottom: 0px;
    }

    &-1 {
      padding-bottom: 0.25rem;
    }

    &-2 {
      padding-bottom: 0.5rem;
    }

    &-3 {
      padding-bottom: 1rem;
    }

    &-4 {
      padding-bottom: 1.5rem;
    }

    &-5 {
      padding-bottom: 2rem;
    }
  }

  &l {
    &-0 {
      padding-left: 0px;
    }

    &-1 {
      padding-left: 0.25rem;
    }

    &-2 {
      padding-left: 0.5rem;
    }

    &-3 {
      padding-left: 1rem;
    }

    &-4 {
      padding-left: 1.5rem;
    }

    &-5 {
      padding-left: 2rem;
    }
  }

  &r {
    &-0 {
      padding-right: 0px;
    }

    &-1 {
      padding-right: 0.25rem;
    }

    &-2 {
      padding-right: 0.5rem;
    }

    &-3 {
      padding-right: 1rem;
    }

    &-4 {
      padding-right: 1.5rem;
    }

    &-5 {
      padding-right: 2rem;
    }
  }

  &x {
    &-0 {
      padding: 0px;
    }

    &-1 {
      padding: 0 0.25rem;
    }

    &-2 {
      padding: 0 0.5rem;
    }

    &-3 {
      padding: 0 1rem;
    }

    &-4 {
      padding: 0 1.5rem;
    }

    &-5 {
      padding: 0 2rem;
    }
  }

  &y {
    &-0 {
      padding: 0px;
    }

    &-1 {
      padding: 0.25rem 0;
    }

    &-2 {
      padding: 0.5rem 0;
    }

    &-3 {
      padding: 1rem 0;
    }

    &-4 {
      padding: 1.5rem 0;
    }

    &-5 {
      padding: 2rem 0;
    }
  }
}

@import '../../../vars.module.scss';

.collection-section {
  .header {
    margin: 1.52rem 0 0.912rem 1rem;
    color: #001942;
    font-weight: 700;
  }
  .collection-container {
    .collection-header {
      left: 0;
      margin-top: 8px;
      z-index: 7;
      background-color: $white;
      overflow: hidden;
      padding: 0 2rem;

      box-shadow:
        0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 3px 1px -2px rgba(0, 0, 0, 0.12),
        0 1px 5px 0 rgba(0, 0, 0, 0.2);
      .collection-header-container {
        max-width: 1800px;
        margin: auto;
        .title {
          font-weight: 700;
        }

        .no-description {
          color: $gray;
        }
      }

      .single-collection-actions {
        display: flex;
        align-items: center;
        .MuiButtonBase-root.MuiIconButton-root {
          &:focus {
            background-color: transparent;
          }
        }
      }
    }
    .collection-body {
      position: relative;
      .card {
        max-width: 1800px;
        margin: 0.5rem auto 1rem auto;
      }
    }
  }
}

.banner-collection-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #e4e7ec;
  margin-bottom: 40px;
}

.title-banner {
  display: flex;

  gap: 6px;
  height: 14px;
  align-items: center;
}

.title-banner h1 {
  color: #053ac8;
  font-weight: 600;
  font-size: 16px;
}

.title-banner span {
  background-color: #fdb022;
  color: #fff !important;
  font-weight: 600;
  font-size: 10px !important;
  padding: 0px 6px;
  border-radius: 10px;
  text-transform: capitalize;
}

.banner-collection-header span {
  color: #1d2939;
  font-size: 14px;
  font-weight: normal;
}

.banner-collection-button {
  background-color: #053ac8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 215px;
  color: #fff;
  padding: 8px;
  height: 36px;
  border-radius: 4px;
}

.banner-collection-button span {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
}

.empty-collection {
  margin-top: 10px;
  width: 100%;
  text-align: center;
  img {
    max-width: 90%;
  }
}

#modal-edit-collection,
#modal-create-collection {
  .modal-edit-form,
  .modal-create-form {
    padding-top: 15px;
  }
}

.avatar-folder {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: 50px;

  .avatar-folder-due {
    position: relative;

    .avatar-status {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px;
      border-radius: 20px;
      width: 25px;
      height: 25px;
      position: absolute;
      top: 40%;
      left: 50%;
      border: 2px solid #fff;
    }
  }
}

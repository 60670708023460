@import '../../../../../vars.module.scss';

.btn-advanced-search {
  width: 60%;
  height: 36px;
  border-radius: 5px;
  background-color: $turivius;
  color: $white;

  &:hover {
    background-color: $white;
    color: $turivius;
    border: 1px solid $turivius;
  }

  &:focus-within {
    box-shadow: none;
  }

  &:focus {
    background-color: $turivius;
    color: $white;
  }
}

@media screen and (max-width: 1041px) {
  .btn-advanced-search {
    width: 70%;
    font-size: 12px;
  }
}

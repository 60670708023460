@import '../../../vars.module.scss';

.collections-result-card {
  margin-bottom: 15px;

  .row {
    max-width: 100%;
  }
  .MuiListItemSecondaryAction-root {
    button:focus {
      background-color: unset;
    }
  }
  .comments-message {
    margin: 0px !important;

    color: #aaa;
    line-height: 15px !important;
    text-align: center;
    font-size: 12px;
  }
  .comments-card {
    &:before {
      content: '';
      position: absolute;
      margin-top: 30px;
      right: 25%;
      height: 20px;
      width: 20px;
      background: $white;
      transform: rotate(45deg);
      border-bottom: inherit;
      border-left: inherit;
      box-shadow:
        -1px 1px 1px 0px rgba(0, 0, 0, 0.14),
        -2px 5px 6px -1px rgba(0, 0, 0, 0.12),
        0px -1px 5px 0px rgba(0, 0, 0, 0);
    }
    padding: 4px;
    margin-top: 10px;
    background-color: $white;
    border-radius: 2px;
    max-height: 96%;
    min-height: 434px;
    -webkit-box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);

    &.embedded {
      background-color: transparent;
      box-shadow: none;
      -webkit-box-shadow: none;
      ul {
        background-color: transparent;
      }
      &:before {
        display: none;
      }
    }

    .comments-card-open-modal-icon {
      float: right;
      cursor: pointer;
    }

    div[class^='RichTextEditor__root'] {
      margin-top: 10px;
    }
    div[class^='RichTextEditor__editor'] {
      height: 100px;
    }
  }
}

.comments-card-action-buttons {
  text-align: right;
  margin-top: 4px;
  .submit-btn {
    background-color: $turivius;
  }
}

@mixin modal-button {
  background-color: $turivius-light !important;

  border-radius: 4px;
  font-weight: 700;
  font-size: 20px;
  padding: 14px 48px;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  i {
    color: $secondary-lighter;
    font-size: 28px;
    margin-left: 8px;
    font-weight: normal !important;
  }

  &:hover,
  &:focus,
  &:active {
    color: $white;
  }
}

.turivius-setup-modal {
  overflow-y: hidden;
  background-color: #053ac8;

  .rs-row {
    background-color: yellow !important;

    @media (min-width: 800px) {
      overflow: hidden !important;
    }
  }

  .rs-form {
    width: 100%;

    .rs-form-control-label {
      font-weight: 500;
      font-size: 16px;
      color: #101828;
      display: inline-block !important;
      width: 100%;
      margin-bottom: 15px;
      margin-top: 15px;
    }

    .rs-input,
    .rs-picker-toggle {
      border: 1px solid #dedede !important;
      border-radius: 8px !important;
      color: #475467;
    }

    .rs-input:disabled {
      background-color: $white;
      color: #475467;
      font-weight: 500;
      cursor: default;
    }

    .rs-picker-toggle-placeholder {
      color: #475467 !important;
      font-size: 14px !important;
    }
  }

  .call-to-action {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #053ac8 !important;
    height: 100vh !important;
    overflow: hidden !important;
    color: $white;

    .call-text {
      width: 80%;
      padding: 48px 0;
      font-size: 20px;

      h2 {
        font-size: 2rem !important;
        padding-bottom: 24px;

        &:first-child {
          color: $secondary-lighter !important;
        }
      }

      span {
        font-weight: 700;
      }
    }

    .trial-image {
      max-width: 95% !important;
    }
  }

  .call-to-action-last {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    text-align: left;
    background-color: #053ac8 !important;
    height: 100vh !important;
    overflow: hidden !important;
    color: $white;

    .call-text {
      width: 95%;
      padding: 48px;
      font-size: 20px;

      h2 {
        font-size: 2rem !important;
        padding-bottom: 24px;

        &:first-child {
          color: $secondary-lighter !important;
        }
      }

      span {
        font-weight: 700;
      }
    }

    .trial-image {
      max-width: 95% !important;
      align-self: self-end;
    }
  }

  .form-trial {
    background-color: $white !important;
    display: flex;
    min-height: 100vh !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 24px;

    .form-container {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 32px;
        font-weight: 700;

        line-height: 40px;
        color: #053ac8;
      }

      .sub-title {
        font-weight: 600;
        color: #000;
        font-size: 20px;
        margin-bottom: 10px;
      }

      .info-text {
        width: 340px;
        font-size: 12px !important;
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .line {
        display: flex;

        .rs-progress-line {
          padding: 0px;
          margin-bottom: 40px;
        }

        button {
          width: 30px;
          height: 30px;
          border-radius: 30px;
          line-height: 39px;
          background: transparent;
          margin-top: -10px;
          margin-left: -10px;
          margin-right: 5px;
        }
      }

      .loading {
        text-align: center;
      }

      .modal-logo {
        width: 25%;
        padding-bottom: 72px;
      }

      .description {
        margin-top: 25px;
        margin-bottom: 50px;
        font-weight: 400;
        font-size: 20px !important;

        color: #000;
      }

      .rs-input,
      .rs-picker-toggle {
        border: 1px solid $secondary-lighter;
      }

      .rs-picker-toggle-placeholder {
        font-size: 14px !important;
      }

      .rs-form {
        font-family: 'Poppins', sans-serif !important;
        max-width: 500px;

        color: #2e2e2e;

        .upload {
          display: flex;
          font-weight: 600;
          font-size: 16px !important;
          color: #2e2e2e;
          margin-bottom: 80px;

          button {
            margin-left: 20px;
            background-color: transparent;
            color: #0069f5;
            font-size: 12px;
            line-height: 20px;
            padding: 0px;
          }
        }

        .rs-input,
        .rs-picker-toggle {
          border: 1px solid $secondary-lighter;
        }

        .rs-picker-toggle-placeholder {
          color: #929493 !important;
          font-size: 16px !important;
        }
      }
    }
  }
}

.card-setup {
  width: 502px;
  height: 254px;
  border-radius: 24px;
  background-color: #fff;

  .card-header {
    width: 502px;
    height: 122px;
    background-color: #eff8ff;
    border-radius: 24px 24px 0px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .name {
      color: #000;
      font-size: 32px;
      font-weight: 500;
      font-family: 'Poppins', sans-serif !important;
      margin-top: 40px;
      margin-left: 20px;
    }

    .image {
      background-color: #b2ddff;
      width: 71px;
      height: 71px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      margin-left: 37px;
      border-radius: 50%;
      overflow: hidden;

      .rs-avatar {
        width: 71px;
        height: 71px;
      }

      .rs-avatar > .rs-avatar-image {
        width: 71px;
        height: 71px;
      }
    }
  }

  .card-body {
    .work-label {
      height: 44px;
      width: 281px;
      background-color: #eff8ff;
      margin-top: 30px;
      margin-left: 20px;
      border-radius: 24px;
      display: flex;
      justify-content: flex-start;
      align-content: center;
      align-items: center;
      color: #010c28;
      font-size: 20px;
      font-weight: 500;
      padding-left: 20px;
    }
  }
}

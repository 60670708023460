@import '../../../../../vars.module.scss';

.company-information {
  padding: 20px;
  background-color: #ffffff;

  .btn-save {
    background-color: $secondary-dark;
    border: 0.5px solid $secondary-dark;

    &:hover,
    &:focus {
      color: white;
    }
  }

  .btn-cancel {
    background-color: transparent;
    border: 1px solid $secondary-dark;
    color: $secondary-dark;
  }
}

.bt-box-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 48%;
  margin: 60px auto 0px;
}

.bt-box-card:hover {
  border: 1px solid;
  border-color: #2970ff; /* Contorno azul claro no hover*/
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Adiciona uma leve elevação */
}

.bt-box-card:hover .bt-box-button {
  border: 1px solid;
  border-color: #2970ff; /* Borda laranja no foco */
  color: #2970ff;
}

.bt-box-button {
    background-color: transparent; /* Fundo transparente */
    color: #6b7280; /* Texto em cinza escuro */
    border: 2px solid #e5e7eb; /* Borda cinza claro */
    border-radius: 4px;
    padding: 8px 16px;
    font-weight: 500;
    cursor: pointer;
    width: 100%;
    transition: all 0.3s ease;
  }

.bt-box-icon {
  background-color: #f4f6fa; /* Fundo claro para o ícone */
  border-radius: 8px;
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6b7280; /* Cor do ícone */
  font-size: 24px;
}

.bt-box-text {
  margin-left: 15px;
  flex: 1;
}

.bt-box-title {
  font-size: 16px;
  font-weight: 600;
  color: #333;
  margin-bottom: 5px;
}

.bt-box-description {
  font-size: 14px;
  font-weight: 400;
  color: #666;
  margin-bottom: 10px;
}



/* Versão Azul */
/* .bt-box-button {
    background-color: white;
    color: #2970ff;
    border: 1px solid #2970ff;
  } */


 
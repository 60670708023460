

.content-filters {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.box-checkbox {
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding-left: 3px;
  color: #101828;
  line-height: 20px;
  margin-bottom: 10px;
}

.box-allcheckbox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content-checkbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  background-color: #f9fafb;
  color: #101828;
  height: 32px;
  border-radius: 4px;
  padding: 0px 4px;
}

.content-checkbox-two {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;
  height: 32px;
  border-radius: 4px;
  padding: 0px 4px;
  margin-top: 2px;
}

.without-children {
  background-color: transparent;
  color: #667085;
  font-weight: 400;
  margin: -1px 0px;
}

.btn-expand-collapse {
  color: #dc6803;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  margin-bottom: 12px;
}

.content-checkbox > svg {
  margin-left: 8px;
}

.box-content-two {
  display: flex;
  align-items: center;
  gap: 8px;
}

.content-checkbox-two > svg {
  margin-left: 1px;
}

.content-checkbox-children {
  margin-left: 9px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #eaecf0;
  padding-left: 4px;
}

.content-checkbox-children-two {
  margin-left: 9px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #eaecf0;
  padding-left: 10px;
}

.content-children {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-weight: 400;
  color: #667085;
  margin: 8px 0px;
}

input[type='checkbox'] {
  appearance: none;
  -webkit-appearance: none;
  width: 17px;
  height: 17px;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
  background-color: #fff;
  padding: 6px;
  cursor: pointer;
  outline: none;
  position: relative;
  transition:
    background-color 0.2s,
    border-color 0.2s;
}

input[type='checkbox']:checked {
  background-color: #053ac8;
  border-color: #053ac8;
}

input[type='checkbox']:checked:hover {
  border-color: #5179e8;
}

input[type='checkbox']:checked::before {
  content: '';
  position: absolute;
  top: 1px;
  left: 5px;
  width: 6px;
  height: 9px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

input[type='checkbox']:indeterminate {
  background-color: #fff;
  border-color: #053ac8;
}

input[type='checkbox']:indeterminate::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 2px;
  background-color: #053ac8 !important;
  transform: translate(-50%, -50%);
}

.entity-count-two {
  background-color: #dbe8ff;
  color: #0069f5;
  border-radius: 15px;
  margin-left: auto;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.entity-count {
  background: linear-gradient(60.23deg, #2970ff 0.2%, #0040c1 99.99%);
  color: #ffffff;
  border-radius: 15px;
  margin-left: auto;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.close .rs-check-tree > .rs-check-tree-view {
  display: none;
}
/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* Bolinhas de contagem */
/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* Essa é a bolinha de contagem parta os nó folha */
.single-entity-count {
  background-color: #dbe8ff;
  color: #0069f5;
  border-radius: 15px;
  margin-left: auto;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Estilo compartilhado para .close e .open */
.close > .rs-check-tree > .rs-search-box > .rs-input-group,
.open > .rs-check-tree > .rs-search-box > .rs-input-group {
  border: 1px solid #e4e7ec !important;
  flex-direction: row-reverse;
  padding: 0;
}

/* Estilo compartilhado para .rs-input dentro de .close e .open */
.close > .rs-check-tree > .rs-search-box > .rs-input-group-inside > .rs-input,
.open > .rs-check-tree > .rs-search-box > .rs-input-group-inside > .rs-input {
  padding: 0px;
}

.rs-tree-node-toggle-placeholder {
  height: 0px;
  width: 0px;
  margin-left: -5px;
}

.rs-check-tree .rs-search-box {
  padding: 6px 0px;
}

.rs-checkbox-checked .rs-checkbox-control .rs-checkbox-inner:before,
.rs-checkbox-indeterminate .rs-checkbox-control .rs-checkbox-inner:before {
  background-color: #053ac8;
  border-color: #053ac8;
}

.rs-tree-indent-line {
  border-left: 1px solid #eaecf0;
  bottom: -4px;
  left: 12px;
  position: absolute;
  top: -10px;
  width: 1px;
}

.rs-check-tree-node-children > .rs-check-tree-node {
  background: #f9fafb;
  height: 32px;
  border-radius: 4px;
  margin-bottom: 14px;
}

.rs-check-tree
  .rs-check-tree-node-content
  .rs-check-item
  .rs-checkbox-checker
  > label {
  margin-left: -7px;
}

.rs-check-tree > .rs-check-tree-view {
  height: auto !important;
}

.rs-check-tree-node .rs-check-item-focus .rs-checkbox-label,
.rs-check-tree-node .rs-check-item:focus .rs-checkbox-label,
.rs-check-tree-node .rs-check-item:hover .rs-checkbox-label {
  background-color: transparent;
}

.rs-check-tree
  > .rs-check-tree-view
  > .rs-check-tree-root
  > .rs-check-tree-node-expanded
  > .rs-check-tree-node {
  background-color: transparent;
}

.rs-check-tree
  > .rs-check-tree-view
  > .rs-check-tree-root
  > .rs-check-tree-node-expanded
  > .rs-check-tree-node
  > .rs-tree-node-toggle {
  display: none;
}
.rs-check-tree
  > .rs-check-tree-view
  > .rs-check-tree-root
  > .rs-check-tree-node-expanded
  > .rs-check-tree-group
  > .rs-tree-indent-line {
  display: none;
}

.rs-check-tree
  > .rs-check-tree-view
  > .rs-check-tree-root
  > .rs-check-tree-node-expanded
  > .rs-check-tree-group {
  padding-left: 0;
}

/* Essa é a bolinha de contagem para os grupos */
.group-entity-count {
  background: linear-gradient(60.23deg, #2970ff 0.2%, #0040c1 99.99%);
  color: #ffffff;
  border-radius: 15px;
  margin-left: auto;
  padding: 4px 8px;
  font-size: 10px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------- */
/* O PRINCIPAL PROBLEMA QUE ESSAS CLASSES AQUI RESOLVEM É QUE OS ELEMENTOS DA ÁRVORE DE COMPONENTES SÃO EM SUA MAIORIA INLINE-BLOCK */
/* Isso faz com que não consigamos colocar elementos no canto, no caso as contagens */
/* Esse é o componente container onde os nomes do nó (com checkbox, text e etc estão contido) */
.rs-check-tree-node-content {
  display: block;
  width: 100%;
}
/* Aqui estou dizendo que todos os elementos filhos da div que estão dispostos dentro do container do nó
deverão ser block (isso garante que eles tenham altura e lagura.
Depois eu aplico width 100% para expandir eles
*/
.rs-check-tree-node-content > * {
  display: block;
  width: 100%;
}
/* Esse é um container mais interno que aparentemente deve ter uma regra important
Logo o que eu faço aqui é colocar ele como block também.
*/
.rs-checkbox-label {
  display: block;
  width: 100%;
}
/* Agora eu faço questão de que todos os componentes filho desse elemento sejam block também */
.rs-checkbox-label > * {
  display: block;
  width: 100%;
}
/* Essa é a regra final que garante que a última div que estava inlin-block antes do conteúdo seja colocada como block */
.rs-check-tree-node > .rs-check-tree-node-content .rs-checkbox-label {
  display: block;
}
/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------- */

.title-filter{
    font-weight: 500;
    color: #101828;
    font-size: 16px;
    margin-bottom: 12px;
}
@import '../../vars.module.scss';

.flag-segment {
  padding: 13px 33px 15px 16px;
  height: 68px;
  background-color: #fff;
  color: $turivius-dark;
  user-select: none;
  z-index: 6;
  width: 100%;
  border-bottom: 1px solid #e4e7ec;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.hidden {
    top: -80px;
    transition: top 500ms ease-in-out;
  }

  &.mobile {
    padding-left: 26px;
  }

  .breadcrumb {
    display: flex;
    align-items: flex-start;
    justify-content: left;
    flex-direction: column;
    color: #667085;

    &_routes {
      display: flex;
      align-items: center;

      &_item {
        background-color: transparent;
      }
    }

    &_item {
      cursor: pointer;
    }

    &_title {
      margin-top: 10px;
      color: #010c28;
      font-size: 16px;
      font-family: Poppins;
      font-style: normal;
      line-height: 28px;

      b {
        font-weight: 600;
      }
    }
  }

  .nav-options {
    display: flex;

    .line-image {
      margin-right: 16px;
    }
  }


}

#nav-menu-options {
  .btn-action {
    border-radius: 50px;
    background-color: transparent;
    color: $turivius-light;

    #avatar {
      margin-right: 10px;
    }

    &.menu {
      display: flex;
      align-items: center;
      background-color: transparent;

      svg {
        margin-right: 10px;
      }
    }
  }
}

.menu-header {
  padding: 0px 12px;

  p {
    color: #344054;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

#menu-user-personal {
  .rs-popover-content {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    padding: 30px;

    .rs-dropdown-item {
      padding: 0;

      &:focus-visible,
      &:hover {
        color: unset !important;
        background-color: unset !important;
        border: none;
      }
    }
  }
}

@import '../../../../../vars.module.scss';

.options-button {
  background-color: #daf2ff;
  color: $turivius-light;
  font-weight: 700;

  .rs-icon {
    background-color: #daf2ff;
  }

  &:hover,
  &:focus {
    background-color: #daf2ff;
    color: $turivius-light;
    font-weight: 700;

    .rs-icon {
      background-color: #daf2ff;
    }
  }
}

.no-description {
  width: 30%;
}

.header_actions {
  display: flex;
  justify-content: start;
  gap: 16px;
  height: 48px;
}

.collection-header-content {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.header_description {
  width: 50%;
}

.no-description {
  width: 80%;
}

.button-shared-style {
  background-color: #fff;
  padding: 12px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  gap: 4px;
  border: 1px solid #d0d5dd;
  border-radius: 4px;
}

@media (max-width: 1500px) {
  .button-shared-style span {
    display: none;
    padding: 4px 8px;
  }
}

@media (max-width: 1100px) {
  .collection-header-content {
    flex-direction: column-reverse;
    gap: 20px;
  }
}

.menu-responsive {
  @media (max-width: 1360px) {
    margin-left: -20px;
  }
}

@media (max-width: 1680px) {
  .jurimetria-text {
    display: none;
  }
}

@media (max-width: 1480px) {
  .monitorar-tema-text {
    display: none;
  }

}

$h1-size: 3.5rem;
$h2-size: 3rem;
$h3-size: 2.5rem;
$h4-size: 2rem;
$h5-size: 1.5rem;
$h6-size: 1.25rem;
$h7-size: 1rem;
$h8-size: 0.875rem;
$h9-size: 0.75rem;

$body-1: 1.25rem;
$body-2: 1.125rem;
$body-3: 1rem;
$body-4: 0.875rem;
$body-5: 0.75rem;

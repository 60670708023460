@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rubik:wght@100;200;300;400;500;600;700;800&display=swap');

@import './vars.module.scss';
@import './styles/main.scss';

body {
  padding: 0 !important;
  margin: 0;
  font-family: 'Public Sans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $body-color !important;
  max-width: 100%;
  overflow: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Rubik', sans-serif;
}

mark {
  background-color: #fdff75 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p,
span,
blockquote {
  font-size: 14px;
}

.turivius-spinner-image {
  padding: 0px !important;
  top: calc(50% - 5px) !important;
  left: calc(50% - 5px) !important;
}

.react-joyride__overlay {
  height: 100vh !important;
}
#hubspot-conversations-iframe,
#hubspot-messages-iframe-container {
  display: none !important;
}

.loader-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: $blue500;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader-ellipsis div:nth-child(1) {
  left: 8px;
  animation: loader-ellipsis1 0.6s infinite;
}
.loader-ellipsis div:nth-child(2) {
  left: 8px;
  animation: loader-ellipsis2 0.6s infinite;
}
.loader-ellipsis div:nth-child(3) {
  left: 32px;
  animation: loader-ellipsis2 0.6s infinite;
}
.loader-ellipsis div:nth-child(4) {
  left: 56px;
  animation: loader-ellipsis3 0.6s infinite;
}
@keyframes loader-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loader-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes loader-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.spinner {
  animation: loading-bar-spinner 800ms linear infinite;
  width: fit-content;

  &-icon {
    &_primary {
      border: solid 3.5px transparent;
      border-top-color: $blue600;
      border-left-color: $blue600;
      border-radius: 50%;
    }
    &_white {
      border: solid 3.5px transparent;
      border-top-color: $white;
      border-left-color: $white;
      border-radius: 50%;
    }
    &_gray,
    &_secondary,
    &_minimal {
      border: solid 3.5px transparent;
      border-top-color: $gray400;
      border-left-color: $gray400;
      border-radius: 50%;
    }
    &_danger {
      border: solid 3.5px transparent;
      border-top-color: $danger500;
      border-left-color: $danger500;
      border-radius: 50%;
    }

    &_success {
      border: solid 3.5px transparent;
      border-top-color: $success500;
      border-left-color: $success500;
      border-radius: 50%;
    }
    &_sm {
      width: 18px;
      height: 18px;
    }
    &_md {
      width: 26px;
      height: 26px;
    }
    &_lg {
      width: 40px;
      height: 40px;
    }
  }

  @keyframes loading-bar-spinner {
    0% {
      transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

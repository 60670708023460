.card-option {
  display: flex;
  box-shadow: 0px 1px 3px 0px #f2f4f7;
  box-shadow: 0px 1px 2px 0px #f2f4f7;
  height: 92px;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  padding: 20px;

  max-width: 526px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  cursor: pointer;

  &:hover {
    border: 1px solid blue;
  }

  .tag {
    position: absolute;
    margin-top: -95px;
    right: 15%;
  }

  .icon {
    padding: 10px;
    margin-right: 20px;
  }

  .text {
    line-height: 24px;
    margin-top: 15px;
    margin-bottom: 15px;

    b {
      font-weight: 600;
    }
  }
}

.recomended {
  border: 1px solid blue;
}

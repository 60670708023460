@import './../../../vars.module.scss';

.alert-new-version {
  position: absolute;
  width: 100%;
  background-color: #fef0c7;
  z-index: 50;

  top: 0;
  height: 59px;
  left: 0;

  color: #000;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;
  padding-left: 320px;

  &_message {
    display: flex;
    align-items: center;
    gap: 32px;
    position: relative;
    left: 0;
  }

  &_close {
    display: flex;
    align-items: center;

    background-color: transparent;
  }
}

.rs-header .header-hero {
  h5 {
    @media only screen and (max-width: 600px) {
      font-size: 15px;
      line-height: 16px;
    }
  }

  span {
    font-size: 14px;

    @media only screen and (max-width: 600px) {
      font-size: 11px;
    }
  }
}

.tv-toggle {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 16px;
  margin-top: 16px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;

    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $gray200;
    transition: 0.4s;
    input:checked {
      background-color: $blue500;
    }

    &::before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: $white;
      transition: 0.4s;

      input:checked {
        transform: translateX(26px);
      }
    }
  }
}

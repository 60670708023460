
label .rs-radio-checker{
  // background-color: red;
  padding: 5px !important;
  margin: 0px!important;

}

.single-filter-box {
  background: #fff;
  border-radius: 10px;
  padding: 22px 20.5px 22px 22.5px; // EXPLANATION: Controla o padding do filtro
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  .filter-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    label {
      font-size: 16px;
      font-weight: 500;
      color: #333;
    }
  }
  &.collapsed {
    .rs-radio-group {
      display: none;
    }
    .single-filter-box_option {
      display: none;
    }
  }
  .rs-radio-group {
    margin-top: 12px;
    .rs-radio-wrapper {
      margin-right: 8px;
      &:first-child {
        .rs-radio {
          background-color: #0052cc;
          color: #fff;
          padding: 8px 16px;
          border-color: #0052cc;
        }
      }
      .rs-radio-checked {
        background-color: #0052cc;
        border-color: #0052cc;
        color: #fff;
      }
      .rs-radio-inner {
        &:after {
          background-color: #0052cc;
        }
      }
      .rs-radio-label {
        padding: 4px 12px;
        font-size: 12px;
        font-weight: bold;
        border-radius: 20px;
        border: 1px solid #ddd;
        color: #333;
        &:hover {
          background-color: rgba(0, 82, 204, 0.1);
        }
      }
    }
  }
  .single-filter-box_title {
    font-size: 14px;
    color: #333;
  }
  .single-filter-box_option {
    display: flex;
    align-items: center;
    margin-top: 8px;
    span {
      margin-right: 8px;
      font-size: 14px;
    }
    .rs-toggle {
      margin-left: auto;
      .rs-toggle-handle {
        background-color: #00c48c;
      }
      &.rs-toggle-checked .rs-toggle-handle {
        background-color: #00c48c;
      }
    }
  }
  .synonymsFilter-styles {
    padding-bottom: 16px;
  }
}

.login-container {
  height: 100vh;
  overflow: hidden;
  background-color: #fff;

  &_brand-container {
    background-color: #053ac8;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &_brand {
      width: 290px;
      height: 80px;
    }
  }

  &_login {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
  }
}

.login-component {
  width: 468px;
  max-width: 468px;

  .logo {
    width: 150px;
    display: block;
    margin-bottom: 66px;
  }

  &_header {
    &_back-email {
      &_email {
        gap: 8px;
        display: flex;
        align-items: center;
        font-weight: 600 !important;

        button {
          font-weight: normal;
          background-color: transparent;
          color: #0069f5;
          font-size: 14px;
        }
      }
    }

    small {
      color: $gray400;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      font-family: $rubik;
      text-transform: uppercase;
    }

    h2 {
      color: $blue900;
      font-size: 32px;
      font-style: normal;
      font-weight: 700;
      line-height: 40px;
    }

    p {
      color: $gray700;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      width: 80%;
      margin-top: 12px;
    }
  }

  &_body {
    margin-top: 22px;
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 8px;
      color: $gray900;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 160%;
    }
  }

  &_footer {
    display: flex;
    flex-direction: column;

    small {
      text-align: center;
      color: #666;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      padding: 0 50px;
    }
  }
}

.single-login-message {
  text-align: center;
  padding: 0 10%;
  margin-top: 22px;

  a {
    cursor: pointer;
  }
}

.login {
  background-color: $blue700;

  .login-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .txt-login-header {
      color: $white;
      font-weight: 400;
      font-size: 16px;
    }
  }

  .login-footer {
    span {
      color: #fff;
    }

    a {
      text-decoration: none !important;
    }

    .clk-login-footer {
      color: $white;
      font-weight: 600;
      text-align: left;
      padding: 8px 0;
      font-size: 15px;
    }
  }

  .row {
    height: calc(100vh);
    margin-bottom: 0px;

    &.big-h {
      height: calc(100vh + 40px);
    }

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .login-modal {
    margin: auto;
    height: 360px;
    max-width: 420px;
    overflow: visible !important;

    &.big-h {
      height: 400px !important;
    }

    .card-content,
    .card-reveal {
      text-align: center;

      .card-title {
        margin-top: 15px;
      }

      .input-field {
        width: 100%;

        label.active {
          color: $turivius;
        }

        input:focus,
        input.valid {
          border-bottom: 1px solid $turivius;
          box-shadow: 0 1px 0 0 $turivius;
        }
      }

      button {
        width: 95%;
        background-color: $turivius;

        &:hover {
          background-color: $turivius-light;
        }
      }
    }

    .card-action {
      text-align: right;
      padding: 0px;

      a {
        color: $turivius;

        &:hover {
          color: $turivius-light;
          cursor: pointer;
        }
      }

      a.disabled {
        color: #666 !important;
        cursor: not-allowed;
      }
    }
  }
}

.first-login-content {
  height: 100vh;
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
}

.recover-password {
  img {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 1600px) {
  .login {
    background-size: 2600px 2000px;
  }
}

@media only screen and (max-width: 1330px) {
  .login-container {
    &_infos {
      &_image {
        background-size: 200%;
      }
    }
  }

  .login-component {
    width: 80%;
    padding: 30px 0 30px 0;
  }
}

@media only screen and (min-width: 768px) {
  .login-component {
    .logo {
      display: none;
    }
  }

  @media only screen and (max-width: 420px) {
    .login-page {
      .logo-image {
        &.inside {
          img {
            max-width: 130px;
            max-height: 80px;
          }
        }
      }

      .box-login {
        padding: 30px !important;
      }
    }
  }
}

@import '../vars.module.scss';

// ----------------------------------------------------------------------------------------
/* 
  EXPLANATION: Essa parte controla os efeitos de loading do carregamento quano acontece algum
  refresh na página.
*/
// ----------------------------------------------------------------------------------------

@keyframes pulse {
  0% { transform: scale(1); opacity: 0.5; }
  25% { transform: scale(1.05); opacity: 0.8; }
  50% { transform: scale(1.05); opacity: 1; }
  75% { transform: scale(1.05); opacity: 0.8; }
  100% { transform: scale(1); opacity: 0.5; }
}

@keyframes fadeOut {
  0% { opacity: 1; }
  100% { opacity: 0; visibility: hidden; }
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.9); }
  to { opacity: 1; transform: scale(1); }
}


.animated-image {
  width: 300px;
  animation: pulse 0.6s infinite ease-in-out, fadeIn 0.5s ease-in-out;
}


.loading-refresh-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  transition: opacity 0.4s ease-in-out;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

/* Quando o loading for removido, aplica um fade-out */
.loading-refresh-screen.hidden {
  animation: fadeOut 2s forwards;
}
// ----------------------------------------------------------------------------------------


.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

// em,
.text-hl {
  background-color: #fff8a8;
}

.align-center {
  text-align: center;
}

.no-collections-monitoring {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  p {
    font-weight: 700;
  }
}

.fixed-action-btn {
  bottom: 10px;

  .btn-large {
    height: 44px;
    line-height: 44px;
    padding: 0 16px;
  }
}

.title {
  font-weight: 700 !important;
  margin: 0px !important;
  color: #484848;
  line-height: 36px !important;
}

.subtitle {
  margin-bottom: 15px !important;
  color: #484848;
  line-height: 15px !important;
  font-size: 14px !important;
}

.subsubtitle {
  font-size: 10px;
  margin: 0px !important;
  color: #484848;
  line-height: 15px !important;
}

h4 {
  font-size: 2rem;
}

.TuriviusMultipleAutocompleteFilter-listbox-117 {
  max-height: 420px !important;
}

.MuiAutocomplete-input,
.MuiInputBase-input {
  border-bottom: none !important;
  box-shadow: none !important;
}

.MuiOutlinedInput-input {
  padding: 0px 10px !important;
  margin: 8px 0px !important;
}

input[id^='autocomplete-share-collection-'] {
  border-bottom: none !important;
  box-shadow: none !important;
}

.MuiFormControl-root {
  width: 100%;
  max-width: 100%;
  z-index: 0;
}

.MuiFormLabel-root {
  color: #333 !important;
  margin-bottom: 5px;
}

.inteiro-teor-filter {
  .MuiToggleButton-root:focus {
    background-color: $white !important;
    color: rgb(52, 52, 52) !important;

    &.Mui-selected {
      background-color: $secondary_dark !important;
      color: $white !important;
    }
  }

  .Mui-selected {
    background-color: $secondary_dark !important;
    color: $white !important;
  }
}

.input-field label {
  color: #333 !important;
}

.action-button-disabled {
  cursor: not-allowed;
}

.rs-icon {
  width: 16px !important;
  height: 16px !important;
}

.action-button {
  width: 100%;
  background-color: $white;
  color: $turivius_dark;
  border: 1px solid $turivius;
  height: 38px;

  .rs-icon {
    background-color: $white;
  }

  &.floating i {
    color: $turivius_dark;
  }

  &:hover,
  &:focus {
    color: $white;
    background-color: $turivius;
    opacity: 1;
  }

  &.reverse {
    background-color: $turivius;
    color: $white;
    border: 1px solid $turivius;

    &:hover,
    &:focus {
      background-color: $white;
      color: $turivius;
      border: 1px solid $turivius;
    }
  }

  &.expand,
  &.expand svg {
    background-color: $turivius !important;
    color: $white !important;

    &:hover {
      opacity: 0.8;
    }
  }

  &.expand.reverse,
  &.expand.reverse svg {
    background-color: $white !important;
    color: $turivius !important;

    svg {
      background-color: $white !important;
      color: $turivius !important;
    }

    &:hover,
    &:focus {
      background-color: $turivius !important;
      color: $white !important;
      opacity: 1;

      svg {
        background-color: $turivius !important;
        color: $white !important;
      }
    }
  }

  &.remove {
    color: $red;
    border: 1px solid $red;

    &:hover,
    &:focus {
      color: $white;
      background-color: $red;
      opacity: 1;
    }
  }

  &.remove.reverse {
    color: $white;
    background-color: $red;
    border: 1px solid $red;

    &:hover,
    &:focus {
      color: $red;
      background-color: $white;
      opacity: 1;
    }
  }

  &.save {
    color: $green;
    background-color: $white;
    border: 1px solid $green;

    svg {
      color: $green;
      background-color: $white;
    }

    &:hover,
    &:focus {
      color: $white;
      background-color: $green;
      opacity: 1;

      svg {
        color: $white;
        background-color: $green;
      }
    }
  }

  &.save.reverse {
    color: $white;
    background-color: $green;
    border: 1px solid $green;

    svg {
      color: $white;
      background-color: $green;
    }

    &:hover,
    &:focus {
      color: $green;
      background-color: $white;
      opacity: 1;

      svg {
        color: $green;
        background-color: $white;
      }
    }
  }

  &.secondary-color {
    color: $secondary;
    border: 1px solid $secondary;

    &:hover,
    &:focus {
      color: $white;
      background-color: $secondary;
      opacity: 1;
    }
  }

  &.secondary-color.reverse {
    color: $white;
    background-color: $secondary;
    border: 1px solid $secondary;

    &:hover,
    &:focus {
      color: $secondary;
      background-color: $white;
      opacity: 1;
    }
  }
}

.action-filter-button {
  background-color: $white;
  color: $turivius_dark;
  border: 1px solid $turivius;

  &:hover,
  &:focus {
    color: $white;
    background-color: $turivius;
    opacity: 1;
  }

  &.remove {
    color: $red;
    border: 1px solid $red;

    &:hover,
    &:focus {
      color: $white;
      background-color: $red;
      opacity: 1;
    }
  }

  &.save {
    background-color: $secondary_dark;
    color: $white;
    border: 1px solid $secondary_dark;

    &:hover,
    &:focus {
      color: $secondary_dark;
      background-color: $white;
      opacity: 1;
    }
  }

  &.save.reverse {
    background-color: $white;
    color: $secondary_dark;
    border: 1px solid $secondary_dark;

    &:hover,
    &:focus {
      color: $white;
      background-color: $secondary_dark;
      opacity: 1;
    }
  }
}

// .filter-button {
//   width: 100%;
//   margin: 4px 0;
//   // background-color: $white;
//   color: $turivius_dark;
//   border: 1px solid $turivius;

//   &:hover,
//   &:focus {
//     color: $white;
//     background-color: $turivius;
//     opacity: 1;
//   }

//   &.remove {
//     color: $red;
//     border: 1px solid $red;

//     &:hover,
//     &:focus {
//       color: $white;
//       background-color: $red;
//       opacity: 1;
//     }
//   }

//   &.save {
//     color: $white;
//     background-color: $secondary_dark;
//     border: 1px solid $secondary_dark;

//     &:hover,
//     &:focus {
//       color: $secondary_dark;
//       background-color: $white;
//       opacity: 1;
//     }
//   }

//   &.save.reverse {
//     color: $secondary_dark;
//     background-color: $white;
//     border: 1px solid $secondary_dark;

//     &:hover,
//     &:focus {
//       color: $white;
//       background-color: $secondary_dark;
//       opacity: 1;
//     }
//   }
// }

[id^='modal-quick-information-fix'] {
  max-width: 400px;
  border-radius: 5px;

  h4 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .subtitle {
    margin-bottom: 15px !important;
    text-align: center;

    &.acknowledgement {
      margin-top: 50px !important;
      margin-bottom: 50px !important;
      font-size: 2rem;
      line-height: 2rem !important;
    }

    span {
      text-decoration: underline;
      color: $turivius-light;
    }
  }

  .row {
    text-align: center;
    margin-bottom: 0px;

    button {
      width: 100%;
      border-radius: 30px;
      margin-bottom: 10px;
      background-color: transparent;
      border: 1px solid $turivius;
      color: $turivius;
      line-height: 1rem;
      padding: 10px 5px;
      height: auto;

      &:hover {
        background-color: $turivius;
        color: $white;
        opacity: 1;
      }
    }
  }
}

#modal-confirm-save {
  .modal-confirm-save-body {
    height: 35vh;

    .modal-save-table {
      height: 100%;
      overflow-y: scroll;
    }

    .no-collections {
      background-color: $body_color;

      img {
        width: 40%;
      }
    }
  }
}

.filter-section {
  width: 100%;

  .input-field {
    width: 100%;
  }
}

#modal-help {
  .modal-content {
    padding: 24px 0px;

    .title {
      padding: 0px 24px;
    }

    .modal-body {
      .tabs {
        .indicator {
          background-color: $secondary_dark;
        }

        .tab.col {
          a {
            &:focus {
              background-color: transparentize(
                $color: $secondary_dark,
                $amount: 0.8
              );
            }

            color: $secondary_dark;
          }
        }
      }
    }
  }
}

#modal-more-information {
  margin-top: 5rem;
  margin-left: 25vh;
  width: 80%;
  max-height: 85vh;
  height: 85vh;

  border-radius: 50px;
  background: white; /* Opcional */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); /* Opcional */

  .rs-modal-content{
    border-radius: 8px;
  }

  .disabled {
    &:hover {
      opacity: 0.5;
      cursor: zoom-in;
    }

    div[id^='modebar-'],
    .g-xtitle,
    .g-ytitle,
    .xaxislayer-above {
      display: none;
    }

    div,
    g,
    rect,
    svg,
    path {
      pointer-events: none !important;
    }
  }

  .modal-jurimetrics-footer {
    border-top: 1px solid $gray;

    button {
      max-width: 150px;
      margin-top: 8px;
    }
  }

  .jurimetric-plot-col {
    .svg-container {
      min-height: 460px;
    }

    .close-icon {
      position: absolute;
      z-index: 2;
      cursor: pointer;
      display: none;

      &.visible {
        display: block !important;
      }
    }

    min-height: 460px;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    cursor: default;
  }

  .modal-content {
    padding: 0px 5px;
  }

  .row {
    margin: 0;
  }

  .modal-actions-buttons {
    max-width: 300px;
    margin: 1rem auto;
  }

  .modal-jurimetrics {
    height: 65vh;

    .takeaway {
      color: #333;
    }

    .title {
      font-size: 1.1em;
      font-weight: 700;
    }

    .subtitle {
      color: #666;
      font-size: 0.9em;
    }

    .modal-jurimetrics-notfound-container {
      margin-top: 12.5vh;
      width: 100%;
      height: 40vh;
      text-align: center;
      display: grid;

      .modal-jurimetrics-notfound-image {
        width: 200px;
        margin: auto;
      }
    }

    .modal-searching-jurimetrics-container {
      margin-top: 12.5vh;
      width: 100%;
      height: 40vh;
      text-align: center;
      display: grid;

      img.modal-searching-jurimetrics-image {
        width: 100%;
        max-width: 400px;
        margin: auto;
      }

      .title,
      .subtitle {
        padding: 0;
        position: relative;
        top: -60px;
      }
    }
  }

  .modal-text-ementa {
    .text-ementa,
    .text-decision {
      font-size: 1rem;
      overflow-y: hidden;
    }

    .menu-information-divisor {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      font-size: 0.7rem;
    }

    .ementa-divisor,
    .decision-divisor {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      font-size: 0.7rem;
    }

    .date-text {
      color: #666;
      font-size: 0.9rem;

      .col {
        padding: 0;
      }
    }

    min-height: 53vh;
    max-height: 54vh;
    overflow-y: scroll;

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 3px;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 50px;
      background-color: #555;
    }
  }

  .modal-footer {
    display: flex;
    align-items: center;
    width: fit-content;
    right: 0;

    .action-button {
      width: auto;
      margin: 6px 2px !important;
      padding: 0px 5px;
    }

    .btn {
      padding: 0px 5px;
    }
  }
}

button {
  &.btn-margin {
    margin: 6px 2px !important;
  }

  &.secondary {
    background-color: $gray !important;
    color: $white;
  }

  &.primary {
    background-color: $turivius;
    color: $white;
  }

  &.confirm {
    background-color: $green;
    color: $white;
  }

  &.danger {
    background-color: $red;
    color: $white;
  }

  &:hover {
    opacity: 0.8;
  }
}

nav.rs-breadcrumb {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;

  a {
    color: $turivius-light !important;
  }
}

.circle-clipper .circle {
  border-width: 1.4px;
}

.centered-preloader {
  margin-top: 25%;

  .turivius-spinner {
    position: relative;
  }

  .turivius-spinner-image {
    position: relative;
  }
}

#toast-container {
  top: auto !important;
  left: auto !important;
  bottom: 10%;
  left: 7%;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px $turivius;
  }

  10%,
  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }
}

@keyframes morph {
  0% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }

  50% {
    border-radius: 30% 60% 70% 40% / 50% 60% 30% 60%;
  }

  100% {
    border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  }
}

.payment-due-redirection {
  .rs-row {
    height: calc(100vh + 10px);

    &.big-h {
      height: calc(100vh + 40px);
    }

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .payment-due-redirection-modal {
    margin: auto;
    min-height: 320px;
    max-width: 420px;

    // &.big-h {
    //   height: 400px !important;
    // }

    // button.secondary {
    //   background-color: #666 !important;
    //   margin-top: 3px;
    //   margin-bottom: 0px;

    //   &:hover {
    //     background-color: #777 !important;
    //   }
    // }

    .rs-panel {
      background-color: $white;
      padding: 35px 10px;

      .avatar {
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
        top: -100px;
        width: 130px !important;
        height: 130px !important;
        border-radius: 50%;
        z-index: 9;
        background: $turivius;
        padding: 15px;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

        img {
          width: 130px;
        }
      }

      .shape {
        animation: morph 8s ease-in-out infinite;
        border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
        height: 400px;
        transition: all 1s ease-in-out;
        width: 400px;
        z-index: 5;

        img {
          width: 100%;
          height: 30px;
        }
      }

      .payment-due-redirection-header {
        text-align: center;
        margin: 20px 0px;
        padding: 15px;

        p {
          font-weight: bold;
          font-size: 18px;
        }
      }

      .payment-due-redirection-body {
        text-align: left;
        margin: 20px 0px;
        padding: 15px;

        p {
          font-size: 15px;
        }
      }

      .payment-due-redirection-footer {
        padding: 15px;
      }
    }
  }
}

.modal-vision {
  h4 {
    font-size: 20px;
    color: #053ac8;
    font-weight: 700;
    line-height: 24px;
  }

  p {
    font-size: 14px;
    line-height: 24px;
    color: #101828;
  }
}

.premium-filter-identifier-animated {
  width: fit-content;
  height: fit-content;
  cursor: pointer;
  display: flex;
  padding: 2px 2px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 5px 0px;

  @keyframes bd {
    0% {
      background-position: 0% 50%;
    }

    50% {
      background-position: 100% 50%;
    }

    100% {
      background-position: 0% 50%;
    }
  }
}

.premium-filter-identifier-first {
  width: fit-content;
  height: 5px;
  cursor: pointer;
  display: flex;
  padding: 11px 4px;
  border-radius: 20px;
  background-color: $body_color;
  align-items: center;
  justify-content: center;
}

.premium-filter-identifier {
  width: fit-content;
  height: 5px;
  cursor: pointer;
  display: flex;
  padding: 14px 8px;
  border-radius: 20px;
  background-color: $white;
  position: relative;

  img {
    width: 18px;
    height: 18px;
    margin-top: -10px;
    margin-left: -5px;
    margin-right: 5px;
  }

  p {
    font-size: 0.7rem;
    margin-top: -8px;
    margin-left: -5px;
  }
}

.carregar-mais-button {
  display: flex;
  justify-content: center;
}

.turivius-panel {
  padding: 20px 10px;
  margin-top: 6px;
  background-color: #fff !important;
}

.hoverable:hover {
  transition: all 0.3s ease-in-out;

  box-shadow: 1px 1px 8px rgb(0 0 0 / 20%);
  // box-shadow: 0 8px 17px 0 rgb(0 0 0 / 20%),
  //   0 6px 20px 0 rgb(0 0 0 / 19%);
}

.chip {
  display: inline-block;
  height: 32px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 32px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: #e4e4e4;
  margin-bottom: 5px;
  margin-right: 5px;
}

.chip:focus {
  outline: none;
  background-color: $secondary-light;
  color: #fff;
}

.chip > img {
  float: left;
  margin: 0 8px 0 -12px;
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.chip .close {
  cursor: pointer;
  float: right;
  font-size: 16px;
  line-height: 32px;
  padding-left: 8px;
}

.new-badge {
  background-color: $secondary-dark !important;
  color: $white !important;
  border-radius: 2px !important;
  padding: 2px 2px !important;
  font-size: 9px !important;
  margin-left: 0px;
  margin-right: 2px;
  font-weight: 600;
  line-height: 10px !important;

  .rs-tag-text {
    margin: auto !important;
  }
}

.rs-custom-input-label {
  color: #333;
  font-size: 12px;
  font-weight: 700;
  background-color: transparent;
  position: absolute;
  top: -8px;
  left: 16px;
  z-index: 6;
  padding: 0px 4px;

  @media only screen and (max-width: 425px) {
    font-size: 0.6rem;
  }
}

.rs-entities-input-label {
  color: #333;
  font-size: 12px;
  font-weight: 700;
  background-color: transparent;
  position: absolute;
  top: 3px;
  left: 16px;
  z-index: 6;
  padding: 0px 4px;
}

.rs-custom-simple-label {
  color: #333;
  font-size: 12px;
  font-weight: 700;
  margin-left: 16px;
  z-index: 6;
  padding: 0px 4px;
}

.rs-custom-input-helper-text {
  font-size: 0.75rem;
  text-align: left;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;

  &.hidden {
    display: none;
  }

  &.error {
    color: #f44336;
  }
}

input.rs-input.rs-custom-input-error {
  border: 1px solid #f44336 !important;
}

.rs-custom-input-error {
  border: 1px solid #f44336 !important;
}

.rs-custom-component-error {
  border: 1px solid #f44336 !important;
  color: #f44336 !important;
}

.rs-input-group {
  border: none !important;
}

.rs-custom-input-big {
  line-height: 2.5em !important;
  


  &#home-page-search-input {
    background: #ffffff;
    border: none;
    font-size: 16px;
    padding: 25px 25px 25px 35px;
    height: 56px;
    box-sizing: border-box !important;
    margin-left: 16px;
  }

  &#home-page-search-input::placeholder {
   color: #98A2B3;
  }
}

.modal-block {
  text-align: center;

  .entendi {
    margin-top: 23px;
    background-color: #053ac8;
    color: #fff;
    width: 114px;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
  }

  img {
    margin-bottom: 25px;
  }

  h5 {
    color: #053ac8;
  }

  h6 {
    font-weight: 100;
    color: #053ac8;
    margin-bottom: 19px;
  }
}

.modal-sessions {
  border-radius: 16px;

  .rs-modal-content {
    padding: 0;
    background-color: #f9fafb;
    border-radius: 16px;
  }

  padding: 0;
  text-align: left;

  .modal-header {
    border-radius: 14px 14px 0 0;
    text-align: center;
    color: white;
    padding: 10px 0;

    h6 {
      font-size: 16px;
      font-weight: 400;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        padding-right: 10px;
      }
    }
  }

  .header-red {
    background-color: #f04438;
  }

  .header-yellow {
    background-color: #fdb022;
  }

  .header-green {
    background-color: #12b76a;
  }

  .modal-content {
    padding: 0 70px;

    .modal-title {
      font-size: 32px;
      line-height: 46px;
      font-weight: 400;
      padding-bottom: 20px;
    }

    .blue {
      color: #053ac8;
      cursor: pointer;
    }

    .sessionCards {
      display: flex;
      flex-direction: row;
      gap: 15px;
      justify-content: center;
      align-items: center;
      padding-top: 40px;
    }
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;

    .entendi {
      margin-left: auto;
      display: flex;
      align-items: center;
      background-color: #053ac8;
      color: #fff;
      padding: 8px 16px 8px 16px;
      border-radius: 4px;

      i {
        color: white;
      }
    }
  }

  .code-input {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    .rs-custom-input-code {
      font-size: 24px;
      color: #053ac8;
      font-weight: 700;
    }
  }
}

@media only screen and (max-width: 840px) {
  .modal-sessions {
    .modal-header {
      padding: 10px;
    }

    .modal-content {
      padding: 0 20px;

      .sessionCards {
        flex-direction: column;
      }

      .actions {
        flex-direction: column;
      }
    }
  }
}

.modal-search {
  text-align: center;

  .entendi {
    margin-top: 23px;
    background-color: #053ac8;
    color: #fff;
    width: 114px;
    padding: 8px 16px 8px 16px;
    border-radius: 4px;
  }

  img {
    margin-bottom: 25px;
  }

  h5 {
    color: #053ac8;
    margin-bottom: 18px;
  }

  p {
    color: #000;
    width: 75%;
    margin: auto;
  }
}

.rs-custom-input-addon-big {
  height: 3.5em !important;

  svg {
    font-size: 20px;
  }
}

.rs-custom-picker-toggle-wrapper-big {
  min-height: 3.5em;
}

blockquote {
  padding: 15px;
  margin-bottom: 0px;
  margin-top: 15px;
  margin-left: 0px;
  background-color: #eee;
  width: 100%;
  border-left: 5px solid $turivius-hot;
}

p + p {
  margin-top: 0px !important;
}

.entities.close {
  display: none;
}

.item-collapse-entities {
  display: flex;
  flex-direction: row;

  padding: 0px 12px;

  &_flags {
    display: flex;
    gap: 5px;

    .new,
    .upgrade {
      font-weight: 600 !important;
      font-size: 0.75rem !important;
      line-height: 160%;
      border-radius: 999px;
      padding: 0px 6px;
    }

    .new {
      background-color: #d1e9ff;
      color: #0069f5;
      cursor: default;
    }

    .upgrade {
      background-color: #fef0c7;
      color: #dc6803;
      cursor: pointer;
    }
  }

  input,
  label {
    margin-right: 10px;
  }

  input[type='checkbox'] {
    appearance: none;
    -webkit-appearance: none;
    width: 17px;
    height: 17px;
    border: 1px solid #d9d9d9;
    border-radius: 3px;
    background-color: #fff;
    position: relative;
    padding: 6px;
    cursor: pointer;
    outline: none;
    transition:
      background-color 0.2s,
      border-color 0.2s;
  }

  input[type='checkbox']:hover {
    border-color: #053ac8;
  }

  input[type='checkbox']:checked {
    background-color: #053ac8;
    border-color: #053ac8;
  }

  input[type='checkbox']:checked:hover {
    border-color: #5179e8;
  }

  input[type='checkbox']:checked::before {
    content: '';
    position: absolute;
    top: 1px;
    left: 5px;
    width: 6px;
    height: 9px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }
}

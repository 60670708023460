.sla {
  min-height: 100%;

  &-title {
    padding-top: 24px;
    color: #101828;
    font-size: 14px;
    font-weight: 600;
  }

  &-description {
    margin: 8px 0;
    color: #475467 !important;
    font-size: 14px !important;
    font-weight: 400;
  }

  span {
    font-size: 12px !important;
    color: #98a2b3;
    margin-bottom: 20px !important;
  }
}

.sla-footer {
  margin-top: 24px;
}

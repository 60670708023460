.entity-indicator {
  $borderWidth: 7px;
  $animationTime: 1.5s;
  $border-color-default: transparent;
  $size: 100px;

  .progress {
    float: left;
    background-color: transparent !important;
    width: $size !important;
    height: $size !important;
    line-height: $size;
    background: none;
    margin: 0 auto;
    box-shadow: none;
    position: relative;

    &:after {
      content: '';
      width: 100%;
      height: 100%;
      border-radius: 50%;
      border: $borderWidth solid $border-color-default;
      position: absolute;
      top: 0;
      left: 0;
    }

    > span {
      width: 50%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      top: 0;
      z-index: 1;
    }

    .progress-bar {
      width: 100%;
      height: 100%;
      background: none !important;
      border-width: $borderWidth;
      border-style: solid;
      position: absolute;
      top: 0;
    }

    .progress-value {
      color: #eee;

      width: $size - 14px;
      height: $size - 14px !important;
      display: flex;
      border-radius: 50%;
      font-size: 15px;
      text-align: center;
      line-height: 20px;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      margin: 0;
    }
  }
}

.tv-alert {
  border-radius: 8px;
  padding: 12px;
  background-color: $gray50;
  border: 1px solid $gray200;
  display: flex;
  flex-direction: column;

  &_header {
    font-size: 14px;
    color: $gray600;
    display: flex;
    gap: 12px;
    align-items: center;
  }
  &-info {
    border-left: 3px solid $information400;
  }
  &-warning {
    border-left: 3px solid $warning400;
  }
  &-success {
    border-left: 3px solid $success400;
  }
  &-error {
    border-left: 3px solid $danger400;
  }
}

.jurimetrics {
  &-search {
    &-card-header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $gray200;

      h6 {
        font-family: $poppins !important;
        color: $black;
      }
      span {
        font-size: 0.6rem;
        cursor: pointer;
      }

      &_actions {
        display: flex;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

@import '../../../../../../vars.module.scss';



.result-card {
  .button-relator{
    position: absolute !important;
    margin-top: -48px !important;
    margin-left: 250px !important;
  }

  .row {
    margin-bottom: 5px;
  }

  .action-button .expand {
    margin-top: 5px;
    margin-right: 5px;
    background-color: #164380;
    color: $white;

    .rs-icon {
      background-color: $turivius;
    }
  }

  .card-content {
    padding: 18px 18px 0px 18px;
    background-color: #fff !important;
  }

  .downloadJurimetria {
    font-size: 11px;
    width: 100px;
    padding: 5px;
    padding-left: 8px;
    padding-right: 8px;
    margin-left: -20px;
    margin-top: 2px;
    border-radius: 5px;
  }

  .card-title {
    width: 100%;
    height: 100%;

    .quick-information {
      cursor: pointer;
      font-size: 10px;
      text-transform: uppercase;
      color: #444;

      &.qi-red {
        border: 1px solid $pastelred;

        .quick-information-icon {
          background-color: $pastelred;
        }
      }

      &.qi-green {
        background-color: transparent;
        border: 1px solid $green;

        .quick-information-icon {
          background-color: $green;
        }
      }

      &.qi-neutral {
        background-color: transparent;
        border: 1px solid $turivius-light;

        .quick-information-icon {
          background-color: $turivius-light;
        }
      }

      &.qi-undefined {
        border: 1px solid #aaa;

        .quick-information-icon {
          background-color: #aaa;
        }
      }

      border: 1px solid $secondary-dark;
      display: inline-flex;
      line-height: 12px;
      -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12),
      0 1px 5px 0 rgba(0, 0, 0, 0.2);

      .quick-information-icon {
        background-color: $secondary-dark;
        color: #ffffff;
        width: 16px;
        height: 16px;
        line-height: normal;
        margin: -1px 3px auto -12px;
        border-radius: 50%;
        text-align: center;
        padding: 8px;
      }

      .quick-information-text {
        margin-top: 5px;
        font-size: 11px !important;
      }

      .quick-information-action {
        float: right;
        font-size: 8px !important;
        line-height: 8px;
        color: #777;
      }

      &:hover {
        -webkit-box-shadow: none;
        box-shadow: none;

        .quick-information-action {
          color: $turivius;
        }
      }
    }

    .quick-information.unclickable {
      -webkit-box-shadow: none;
      box-shadow: none;
      cursor: default !important;

      .quick-information-text-container {
        line-height: 32px !important;

        .quick-information-text {
          margin-top: 0px !important;
          font-size: 11px !important;
        }

        .quick-information-action {
          display: none !important;
          font-size: 8px !important;
        }
      }
    }

    .quick-information.fix {
      -webkit-box-shadow: none;
      box-shadow: none;
      cursor: default !important;

      .quick-information-action {
        color: $turivius;
        margin-top: 1px;
      }
    }

    .card-title-text-container {
      min-height: 100px;
      display: flex;
      align-items: center;

      @media only screen and (max-width: 1000px) and (min-width: 600px) {
        margin-left: 50px;
      }

      .text-name {
        font-weight: 700;
        font-size: 1.2rem;
        line-height: 1.4rem;
      }

      .text-additional {
        line-height: 1rem;
        font-size: 1rem;
      }
    }
  }

  .card-body {
    .custom-title {
      color: #555;
      border-bottom: 1px solid #eee;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-size: 10px;
      letter-spacing: 0.1rem;

      .custom-title-action-btn {
        background-color: transparent !important;
        float: right;
        padding: 4px !important;
        margin-top: -12px;
        text-transform: none;

        @media only screen and (max-width: 400px) {
          float: left;
          margin-top: 5px;
          margin-bottom: 4px;
        }
      }
    }

    .text-informations {
      text-align: justify;
    }

    .text-ementa,
    .text-decision {
      font-size: 1rem;
      overflow-y: hidden;
      display: inline;
    }

    .menu-information-divisor {
      border-top: 1px solid $turivius-dark;

      font-size: 0.8rem;
      display: flex;
      align-items: center;
      margin-left: 5px;
      padding: 6px 0;

      p {
        margin-left: 5px;
        cursor: pointer;
        text-decoration: underline;
        color: $turivius-light;
        text-transform: uppercase;

        &:hover {

          font-weight: 700;
        }
      }
    }

    .information-divisor {
      background-color: $turivius-light;
      color: $white;
      padding-top: 2px;
      padding-left: 4px;
      border-bottom: 1px solid $turivius-light;
      border-top: 1px solid $turivius-light;
      font-size: 0.8rem;
      text-transform: uppercase;
      margin: 5px 0 15px 0;
      border-radius: 3px;
    }

    .date-text {
      color: #666;
      font-size: 0.9rem;
    }

    .jurimetrics-preview-preloader {
      height: 250px;
      float: right;
      display: flex;
      align-items: center;
      background-color: $white;

      .turivius-simple-spinner {
        margin: auto !important;
      }
    }

    .js-plotly-plot {
      &:hover {
        cursor: zoom-in;
      }

      float: right;

      div[id^='modebar-'],
      .g-xtitle,
      .g-ytitle,
      .xaxislayer-above {
        display: none;
      }

      div,
      eit g,
      rect,
      svg {
        pointer-events: none !important;
      }

      .infolayer .g-gtitle .gtitle {
        transform: translate(30px, -35px);
        font-size: 11px !important;
      }

      .main-svg .bglayer .bg {
        x: 80 !important;
        y: 20 !important;
      }

      .main-svg {
        width: 100%;
      }

      .main-svg .cartesianlayer .subplot.xy .plot {
        transform: translate(80px, 20px);
      }
    }
  }

  .card-action {
    padding: 5px 0px;

    .save-to-collection-button {
      .modal-save-table {
        max-height: 200px;
        height: 100%;
        overflow-y: scroll;
        font-size: 0.8rem;
      }

      .btn-save-to-collection {
        height: 38px;

        font-size: 0.8rem !important;
        line-height: 0.8rem !important;
        letter-spacing: 0.5px;
        font-weight: 600 !important;

        &:focus,
        &:hover {
          .turivius-white-spinner .spinner-layer {
            border-color: $green;
          }
        }
      }

      .btn-save-to-collection-arrow {
        background-color: $green !important;
        color: $white !important;
        border-left: 1px solid $white !important;

        &:focus,
        &:hover {
          background-color: $green !important;
        }
      }

      #card-search-collection-to-save {
        height: 1.5rem;
      }

      [id^='create-and-add-save-to-collection'] {
        height: 20px;
      }
    }

    .more-info-container {
      padding: 0;

      &.disabled {
        cursor: not-allowed !important;

        .more-info-button {
          border: none !important;

          .rs-icon {
            background-color: $turivius;
          }
        }
      }

      .more-info-button {
        background-color: $turivius;
        color: #fff;
        width: 100%;
        padding: 0 calc(50% - 120px);
      }
    }
  }
}

.thumbs-up-down {
  border: 0.5px solid transparentize($color: $gray, $amount: 0.8);
  border-radius: 25px;
  width: fit-content;
  text-align: center;
  padding: 5px;
  padding-right: 10px;
  z-index: 0;

  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  @media only screen and (min-width: 800px) {
    position: absolute;
    top: -35px;
  }

  .thumbs-up-down-question {
    font-size: 13px;
    line-height: 10px;
    padding: 2px 5px 0px 10px;
    cursor: default;
  }

  .thumb-up,
  .thumb-down {
    cursor: pointer;
    padding: 0px 4px;

    svg {
      color: $gray;
    }

    &:focus {
      background-color: transparent;
    }
  }

  .thumb-up {

    &:hover,
    &.active {
      background-color: transparent;

      svg {
        color: $green;
      }
    }
  }

  .thumb-down {

    &:hover,
    &.active {
      background-color: transparent;

      svg {
        color: $pastelred;
      }
    }
  }

  .counter {
    font-size: 0.8rem;
    font-weight: 700;
    cursor: help;

    &.positive {
      color: $green;
    }

    &.negative {
      color: $pastelred;
    }
  }
}

@import '.././variables/colors';

.recent-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: auto;
  height: 409px;
  overflow: hidden; 
  
  .title-box{
    font-size: 16px;
    font-weight: 600;
    font-family: 'Poppins';
    color: #000000;
  }

  &_content {
    display: flex;
    align-items: normal;
    flex-direction: column;
    margin-top: 12px;

    .badge-recent {
      width: 32px;
      height: 32px;
      border-radius: 50% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 4px;
    }

    &:first-child {
      margin-top: 0;
    }

    p {
      @include typography-body5_preset;
      color: $gray800;
      font-size: 10px;
    }

    h2{
      font-family: 'Poppins';
    }

    h4  {
      @include typography-body5_preset;
      color: $gray800;
      font-size: 12px;
      font-family: Poppins;
    }
  }

  .custom-text-color {
    color: $gray400;
  }

  &_images {
    display: flex;
    flex-direction: row;
  }

  .icon-back-square {
    cursor: pointer !important;
  }

  .custom-truncate {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  hr {
    width: 99%;
    margin: 11px 0px;
    color: #e4e7ec;
  }

  .recent-box_footer {
    justify-content: center;
    padding: 0.5rem;
  }

  .recent-box_button {
    width: 79px;
    height: 24px;
    padding: 5px;
    align-items: center;
  }

  .recent-box_content p.truncate {
    max-width: 16rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .pagination {
    color: #667085;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }

  .custom-button-text {
    font-size: 10px;
  }

  &_footer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 6px;
    padding: 0px !important;
  }
  
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); 
    z-index: 9; 
  }
  
  .show-history-dropdown {
    position: fixed; 
    z-index: 10; 
    background: white; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
  }
  
}
